import React ,{useEffect,useCallback} from 'react';
import { Box, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import { NavLink } from 'react-router-dom';
import {  useDispatch,useSelector } from 'react-redux';
import {getNotificaciones} from '../../../../../../redux/actions/Herramientas'

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    position: 'relative',
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20,
  },
  scrollbarRoot: {
    height: 300,
    padding: 16,
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 375,
    },
  },
}));




const HeaderNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {notificaciones} =  useSelector(({ herramientas }) => herramientas);
  const { authUser } = useSelector(({ auth }) => auth);
 
   const notificacaciones = useCallback(() => {
     setInterval(() => {
      if(authUser !== null){
         dispatch(getNotificaciones(authUser.usuarioID))
        }
     }, 60000);
   },[dispatch,authUser]);

   useEffect(() => {
    if(authUser !== null){
       dispatch(getNotificaciones(authUser.usuarioID))
       notificacaciones()
    }
   }, [dispatch,authUser,notificacaciones]);

  return (
    <Box pr={2}>
       <NavLink  to='/apps/herramienta/consolicitud' >
        <Tooltip title="Notifications">
          <IconButton
            className={clsx(classes.iconRoot, 'Cmt-appIcon', {
              active: notificaciones.cantidad > 0,
            })}>
            <Badge badgeContent={notificaciones.cantidad} classes={{ badge: classes.counterRoot }}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </NavLink>
    </Box>
  );
};

export default HeaderNotifications;
