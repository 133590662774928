import {makeStyles,alpha, lighten} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    
    root: {
        width: '100%',
      },
      paper: {
        width: '100%',
        marginBottom: theme.spacing(4),
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
      },
      container: {
        maxHeight: 550,
        minHeight: 550
      },
      containersolicitudes: {
        maxHeight: 450,
        minHeight: 450
      },
      table: {
        minWidth: 750,
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      titleRoot: {
        marginBottom: 2,
        fontSize: 14,
        letterSpacing: 0.25,
        color: theme.palette.common.dark,
      },
      button: {
        textTransform: "none",
        //color:theme.palette.common.black
      },
      buttonerror: {
        textTransform: "none",
        backgroundColor:theme.palette.error.main,
        color:'white'
      },
      roottoolbar: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
      },
      highlight:
        theme.palette.type === 'light'
          ? {
              color: theme.palette.secondary.main,
              backgroundColor: alpha(theme.palette.secondary.light, 0.15),
            }
          : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
            },
      title: {
        flex: '1 1 100%',
      },
      titlecondicion: {
        flex: '1 1 60%',
      },
      chipsRoot: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        '& > *': {
          margin: theme.spacing(0.5),
        },
      },
      dialogRoot: {
        position: 'relative',
      },
      dialogTitleRoot: {
        '& .MuiTypography-h6': {
          fontSize: 16,
          color: theme.palette.common.black,
        },
        background: theme.palette.primary.main,
      },
      textlabel: {
        '& .MuiFormLabel-root': {
          color: '#232322',
        },
       },
       divider: {
        background: theme.palette.primary.main,
    },
    input:{
      '& .MuiTextField-root': {
        width: '50%',
      }
    },
    buscar:{
      '& .CmtSearch-root': {
        justifyContent:'flex-end',
        paddingRight: theme.spacing(2),
      }
    },
    CmtCard:{
      marginBottom:10
    }
  }));

  export default useStyles;