import {makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
    dialogRoot: {
      position: 'relative',
    },
    dialogTitleRoot: {
      '& .MuiTypography-h6': {
        fontSize: 16,
        color: theme.palette.common.black,
      },
      background: theme.palette.primary.main,
    },
    textlabel: {
      '& .MuiFormLabel-root': {
        color: '#232322',
      },
     },
     divider: {
      background: theme.palette.primary.main,
  },
  input:{
    '& .MuiTextField-root': {
      width: '50%',
    }
  },
  button: {
    textTransform: "none"
  },
  inputdoc:{
      flexDirection:'row'
  }
  }));

  export default useStyles;