import React , {useEffect,useState} from 'react';
import _ from 'lodash';
import useForm from '@jumbo/utils/useForm';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography,TextField } from '@material-ui/core';
import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getConceptosDescuento,addConceptoDescuento,updConceptoDescuento,ElimarConceptoDescuento} from '../../redux/actions/Mantenimiento';
import Swal from 'sweetalert2';
import axios from '@jumbo/constants/axios';
import {SET_DIALOGCONCEPTOSDESCUENTOS} from '@jumbo/constants/ActionTypes';

const headCells = [
  {
    id: 'descripcion',
    numeric: false,
    disablePadding: false,
    label: 'Descripción',
  },
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function PlantillasForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  const {ConceptosDescuentos,dialogConceptosDescuentos} =  useSelector(({ mantenimiento }) => mantenimiento);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState('');
 
   const { form, setForm,resetForm } = useForm({
    "presupuestoItemID":null,
    "descripcion": "",
    "activo": "",
    "createdBy": "",
  });

  
  useEffect(() => {
      dispatch(getConceptosDescuento());
  }, [dispatch ]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return ConceptosDescuentos;
      }
      return TableUtils.filterArrayByString(ConceptosDescuentos, _searchTerm);
    }
    if (ConceptosDescuentos) {
      setData(getFilteredArray(ConceptosDescuentos, searchTerm));
    }
  }, [ConceptosDescuentos, searchTerm]);


  
 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');
};

const handlePageChange = (event, newPage) => {
  setPage(newPage);
};

const handleRowsPerPageChange = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  const handleOpenDialog = (formAppdata) => {
   
    axios.get(`PresupuestoItem/byid/${formAppdata.presupuestoItemID}`)
    .then(data => {
       if (data.status === 200) {
       setForm(data.data)
      } 
    })
    .catch(error => {
      
    });
    dispatch({ type: SET_DIALOGCONCEPTOSDESCUENTOS, payload: true });
    
 };

 
 const handleCloseDialog = () => {
  dispatch({ type: SET_DIALOGCONCEPTOSDESCUENTOS, payload: false });
 };


  return (
    <PageContainer >
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
               Conceptos de Descuentos
            </Typography>
            <React.Fragment>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon={false} />
            </React.Fragment>
            <React.Fragment>
            <Button onClick={() => { dispatch({ type: SET_DIALOGCONCEPTOSDESCUENTOS, payload: true });;resetForm()} }  style={{marginRight:5}} size='small' className={classes.button} variant="contained" color="secondary">Agregar</Button>                                   
            </React.Fragment>
          </Toolbar>
          
          <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.descripcion}</TableCell>
                                 <TableCell  align="right">  
                                 <Button style={{marginRight:5}} size='small' className={classes.button} variant="contained" color="secondary" onClick={() => handleOpenDialog(row) } >Editar</Button>                                   
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
         </Paper>
    </div>
      <>
        <Dialog open={dialogConceptosDescuentos}  className={classes.dialogRoot} fullWidth maxWidth="sm" >
            <DialogTitle className={classes.dialogTitleRoot}>  Concepto de Descuento </DialogTitle>
            <DialogContent dividers>
            <div className={classes.root}>  
                 <TextField 
                     className={classes.textlabel}
                     fullWidth
                     label="Descripción" 
                     variant="outlined"
                     value={form.descripcion}
                     onChange={(value) =>  setForm(_.set({ ...form }, 'descripcion',value.target.value))} 
                     size="small"
                     style={{marginBottom:10}}
                     /> 
            </div>

               <Box display="flex" justifyContent="flex-end" my={4}>
                  <Button variant="contained" onClick={handleCloseDialog}>Cancelar</Button>
                  {form.presupuestoItemID !== null &&
                    <Box ml={2}>
                    <Button variant="contained" className={classes.buttonerror} onClick={() => {
                     form.usuarioID = usuarioID;
                      Swal.fire({
                        title: '¿Estás seguro(a) de Eliminar?',
                        text: '¡No podrás recuperar este cambio!',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: "red",
                        confirmButtonText: 'Si, Eliminar',
                        cancelButtonText: 'No, Mantener',
                        }).then((result) => {
                        if (result.value) {
                          dispatch(ElimarConceptoDescuento(form.presupuestoItemID))
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                          Swal.fire(
                          'Cancelado',
                          'No se realizó este cambio :)',
                          'error'
                          )
                        }
                        })
                        }}>
                        Eliminar
                    </Button>
                      </Box>
                     }
                    <Box ml={2}>

                        <Button variant="contained" color="secondary" onClick={() => {
                            form.createdBy = usuarioID;
                            if(form.presupuestoItemID === null){
                              dispatch(addConceptoDescuento(form))
                            }else{
                              Swal.fire({
                                title: '¿Estás seguro(a) de Actualizar?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: "red",
                                confirmButtonText: 'Si, Actualizar',
                                cancelButtonText: 'No, Mantener',
                                }).then((result) => {
                                if (result.value) {
                                  dispatch(updConceptoDescuento(form))
                                } else if (result.dismiss === Swal.DismissReason.cancel) {
                                  Swal.fire(
                                  'Cancelado',
                                  'No se realizó este cambio :)',
                                  'error'
                                  )
                                }
                                }) 
                            }
                        }}>
                            Guardar
                        </Button>

                    </Box>
                </Box>
            </DialogContent>  
        </Dialog>
      </>
    </PageContainer>
  );
}