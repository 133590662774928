import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { Paper, Table, TableCell, TableContainer, TableRow, Button, Typography, TextField } from '@material-ui/core';
import { makeStyles, alpha, lighten } from '@material-ui/core/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
//import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { SET_OPENPERIODOANODIALOG, SET_CLOSEPERIODOANODIALOG, SET_PERIODOANOSELECT } from '@jumbo/constants/ActionTypes';
import Autocompetesinapp from 'routes/Ejecucion/utils/Autocompetesinapp';
import Pickersinapp from 'routes/Ejecucion/utils/Pickersinapp';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import useForm from '@jumbo/utils/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { ValorSelect } from 'routes/Ejecucion/utils/Helper';
import TableUtils from '@jumbo/utils/TableUtils';
import ReactExport from 'react-export-excel';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Swal from 'sweetalert2';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  getAnosInicio,
  getPeriodosanos,
  addPresupuestoMensual,
  updPresupuestoMensual,
  EliminarPresupuestoMensual,
  getbyidPresupuestoMensual,
  GetCamposFormulario,
  Getrowsheader,
} from '../../redux/actions/Mantenimiento';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useStyles2 from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import { NumberFormatDecimalPositivo } from '../../@jumbo/utils/NumberFormatCustom';

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const { CamposHeader } = useSelector(({ mantenimiento }) => mantenimiento);

  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {CamposHeader.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: 350,
    minHeight: 350,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
  button: {
    textTransform: 'none',
  },
  buttonerror: {
    textTransform: 'none',
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  roottoolbar: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  roottoolbartitle: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    background: theme.palette.primary.main,
    fontWeight: 24,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: alpha(theme.palette.secondary.light, 0.15),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  chipsRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.black,
    },
    background: theme.palette.primary.main,
  },
  textlabel: {
    '& .MuiFormLabel-root': {
      color: '#232322',
      marginTop: 5,
    },
  },
  divider: {
    background: theme.palette.primary.main,
  },
  input: {
    '& .MuiTextField-root': {
      width: '50%',
    },
  },
  buscar: {
    '& .CmtSearch-root': {
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(2),
    },
  },
  CmtCard: {
    marginBottom: 10,
  },
  CmtCardSidebar: {
    marginRight: 10,
  },
  listItem: {
    color: 'black!important',
    fontWeight: '500',
    textDecoration: 'none!important',
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingLeft: 24,
    paddingRight: 12,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: `${'white'}!important`,
      pointerEvents: 'none',
      '& .list-item-icon': {
        color: `${'white'}!important`,
      },
    },
    '& .list-item-icon': {
      marginRight: 16,
    },
  },
}));

const defaultFormState = {
  presupuestoPeriodoMensualID: null,
  periodo: null,
  regular: null,
  pgs: null,
  otras: null,
  usuarioID: null,
};

export default function AgrupacionCartera() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { anos, PeriodoAnoSelect, Periodosano, CamposHeader } = useSelector(({ mantenimiento }) => mantenimiento);

  const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('Periodo');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    dispatch(getAnosInicio());
    dispatch(GetCamposFormulario('PRESUPUESTO_MENSUAL'));
    dispatch(Getrowsheader('PRESUPUESTO_MENSUAL'));
  }, [dispatch]);

  useEffect(() => {
    if (PeriodoAnoSelect.periodoAno) {
      dispatch(getPeriodosanos(PeriodoAnoSelect.periodoAno));
    }
  }, [PeriodoAnoSelect]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return Periodosano;
      }
      return TableUtils.filterArrayByString(Periodosano, _searchTerm);
    }
    if (Periodosano) {
      setData(getFilteredArray(Periodosano, searchTerm));
    }
  }, [Periodosano, searchTerm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <PageContainer>
      <Toolbar className={classes.roottoolbar}>
        <Typography className={classes.title} variant="h2" id="tableTitle" component="div">
          Periodos Presupuesto Mensual
        </Typography>
        <React.Fragment>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => dispatch({ type: SET_OPENPERIODOANODIALOG, payload: defaultFormState })}>
            Agregar
          </Button>
        </React.Fragment>
      </Toolbar>

      <Grid container>
        <Grid item xs={3}>
          <CmtCard className={classes.CmtCardSidebar}>
            <Toolbar className={classes.roottoolbartitle}>
              <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                Años
              </Typography>
            </Toolbar>

            <List>
              {anos.length > 0 &&
                anos.map(grupo => (
                  <ListItem
                    button
                    selected={PeriodoAnoSelect.periodoAno === grupo.periodoAno}
                    onClick={() => {
                      dispatch({ type: SET_PERIODOANOSELECT, payload: grupo });
                    }}
                    key={grupo.periodoAno}
                    activeClassName="active"
                    className={classes.listItem}>
                    <ListItemText primary={grupo.periodoAno} disableTypography />
                  </ListItem>
                ))}
            </List>
          </CmtCard>
        </Grid>

        <Grid item xs={9}>
          <CmtCard>
            <Toolbar className={classes.roottoolbartitle}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                      {PeriodoAnoSelect.periodoAno}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Toolbar>

            <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

                <TableBody>
                  {!!data.length ? (
                    stableSort(data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <TableRow hovers tabIndex={-1} key={row.formAppID}>
                          {CamposHeader.map(headCell => (
                            <TableCell>{row[headCell.id]}</TableCell>
                          ))}

                          {/* <TableCell>{row.regular}</TableCell>
                          <TableCell>{row.pgs}</TableCell>
                          <TableCell>{row.otras}</TableCell> */}
                          <TableCell align="right">
                            <IconButton size="medium" color="secondary">
                              <EditIcon
                                onClick={() => {
                                  dispatch(getbyidPresupuestoMensual(row.presupuestoPeriodoMensualID));
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={7} rowSpan={10}>
                        <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              labelRowsPerPage="Filas por página"
            />
          </CmtCard>
        </Grid>
      </Grid>
      <FormDialog />
    </PageContainer>
  );
}

function FormDialog(props) {
  const dispatch = useDispatch();
  const classes2 = useStyles2();
  const classes = useStyles();
  const formDialog = useSelector(({ mantenimiento }) => mantenimiento.dialogPeriodoano);
  const CamposFormulario = useSelector(({ mantenimiento }) => mantenimiento.CamposFormularios);
  const { form, setForm } = useForm(defaultFormState);
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);

  const initDialog = useCallback(() => {
    if (formDialog.data) {
      setForm({ ...formDialog.data });
    }
  }, [formDialog.data, setForm]);

  useEffect(() => {
    if (formDialog.props.open) {
      initDialog();
    }
  }, [formDialog.props.open, initDialog]);

  function closeComposeDialog() {
    dispatch({ type: SET_CLOSEPERIODOANODIALOG, payload: false });
  }

  const isFormValid = () => {
    return form.periodo && form.regular && form.pgs && form.otras;
  };

  function getTextLabel(value) {
    let textoLabel = '';
    if (CamposFormulario.filter(x => x.fieldName === value).length > 0) {
      textoLabel = CamposFormulario.filter(x => x.fieldName === value)[0].textoLabel;
    }
    return textoLabel;
  }

  return (
    <>
      <Dialog open={formDialog.props.open} className={classes.dialogRoot} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogTitleRoot}> Periodos Presupuesto mensual</DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <div className={classes2.input}>
              <KeyboardDatePicker
                fullWidth
                className={classes.textlabel}
                variant="inline"
                autoOk
                openTo="year"
                views={['year', 'month']}
                format="MM/yyyy"
                label={getTextLabel('periodo') !== '' ? getTextLabel('periodo') : 'Periodo'}
                value={form.periodo ? form.periodo.toString() : null}
                onChange={date => {
                  date !== null
                    ? setForm(_.set({ ...form }, 'periodo', `${date.format('YYYYMM')}`))
                    : setForm(_.set({ ...form }, 'periodo', null));
                }}
                inputVariant="outlined"
                margin="dense"
                style={{ marginBottom: 10 }}
              />
            </div>

            <TextField
              className={classes.textlabel}
              fullWidth
              label={getTextLabel('REGULAR') !== '' ? getTextLabel('REGULAR') : 'Regular'}
              variant="outlined"
              value={form.regular}
              onChange={value => setForm(_.set({ ...form }, 'regular', value.target.value))}
              InputProps={{
                inputComponent: NumberFormatDecimalPositivo,
              }}
              style={{ marginBottom: 10 }}
            />

            <TextField
              className={classes.textlabel}
              fullWidth
              label={getTextLabel('PGS') !== '' ? getTextLabel('PGS') : 'PGS'}
              variant="outlined"
              value={form.pgs}
              onChange={value => setForm(_.set({ ...form }, 'pgs', value.target.value))}
              InputProps={{
                inputComponent: NumberFormatDecimalPositivo,
              }}
              style={{ marginBottom: 10 }}
            />

            <TextField
              className={classes.textlabel}
              fullWidth
              label={getTextLabel('OTRAS') !== '' ? getTextLabel('OTRAS') : 'OTRAS'}
              variant="outlined"
              value={form.otras}
              onChange={value => setForm(_.set({ ...form }, 'otras', value.target.value))}
              InputProps={{
                inputComponent: NumberFormatDecimalPositivo,
              }}
              style={{ marginBottom: 10 }}
            />
          </div>

          <Box display="flex" justifyContent="flex-end" my={4}>
            <Button variant="contained" onClick={() => closeComposeDialog()}>
              Cancelar
            </Button>
            {form.presupuestoPeriodoMensualID !== null && (
              <Box ml={2}>
                <Button
                  variant="contained"
                  className={classes.buttonerror}
                  onClick={() => {
                    form.usuarioID = usuarioID;
                    Swal.fire({
                      title: '¿Estás seguro(a) de Eliminar?',
                      text: '¡No podrás recuperar este cambio!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: 'red',
                      confirmButtonText: 'Si, Eliminar',
                      cancelButtonText: 'No, Mantener',
                    }).then(result => {
                      if (result.value) {
                        let borrar = {
                          usuarioID: usuarioID,
                          presupuestoPeriodoMensualID: form.presupuestoPeriodoMensualID,
                        };
                        dispatch(EliminarPresupuestoMensual(borrar));
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire('Cancelado', 'No se realizó este cambio :)', 'error');
                      }
                    });
                  }}>
                  Eliminar
                </Button>
              </Box>
            )}
            <Box ml={2}>
              <Button
                variant="contained"
                color="secondary"
                disabled={!isFormValid()}
                onClick={() => {
                  form.usuarioID = usuarioID;

                  if (form.presupuestoPeriodoMensualID === null) {
                    dispatch(addPresupuestoMensual(form));
                  } else {
                    dispatch(updPresupuestoMensual(form));
                  }
                }}>
                Guardar
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
