import React , {useEffect,useState} from 'react';
import _ from 'lodash';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography } from '@material-ui/core';
import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getSolicitudes,EliminarSolicitud,descargarSolicitud} from '../../redux/actions/Herramientas';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Pickersinapp from 'routes/Ejecucion/utils/Pickersinapp';
import Grid from '@material-ui/core/Grid';
import useForm from '@jumbo/utils/useForm';
import Swal from 'sweetalert2';

const headCells = [
  {
    id: 'opcionSolicitada',
    numeric: false,
    disablePadding: false,
    label: 'Opción Solicitada',
  },
  {
    id: 'solicitadoPor',
    numeric: false,
    disablePadding: false,
    label: 'Solicitado Por',
  },
  {
    id: 'fechaSolicitud',
    numeric: false,
    disablePadding: false,
    label: 'Fecha Solicitud',
  },
  {
    id: 'estatus',
    numeric: false,
    disablePadding: false,
    label: 'Estatus',
  },

];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function InformesForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  const {solicitudes} =  useSelector(({ herramientas }) => herramientas);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState('');


  const { form, setForm } = useForm({
    "UsuarioID": usuarioID,
    "FormAppID": null,
    "FechaSolicitudInicialDummy": "na",
    "FechaSolicitudFinalDummy": "na"
  });


  useEffect(() => {
      dispatch(getSolicitudes(form));
  }, [dispatch ,form]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return solicitudes;
      }
      return TableUtils.filterArrayByString(solicitudes, _searchTerm);
    }
    if (solicitudes) {
      setData(getFilteredArray(solicitudes, searchTerm));
    }
  }, [solicitudes, searchTerm]);


 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');
};

const handlePageChange = (event, newPage) => {
  setPage(newPage);
};

const handleRowsPerPageChange = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  return (
    <PageContainer heading="Consulta de Solicitudes">

<CmtCard className={classes.CmtCard}>
        <CmtCardContent>
        <Grid container spacing={2} >
   
            <Grid item xs={2}>
                <Pickersinapp
                         fullWidth
                          label="Desde"
                          value={form.FechaSolicitudInicialDummy === "na" ? null : form.FechaSolicitudInicialDummy  }
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'FechaSolicitudInicialDummy', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'FechaSolicitudInicialDummy', "na"))}}
                          style={{paddingRight: 10}}
                       />
              </Grid>
              
              <Grid item xs={2}>
                <Pickersinapp
                         fullWidth
                          label="Hasta"
                          value={form.FechaSolicitudFinalDummy === "na" ? null : form.FechaSolicitudFinalDummy }
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'FechaSolicitudFinalDummy', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'FechaSolicitudFinalDummy', "na"))}}
                          style={{paddingRight: 10}}
                       />
              </Grid>

                     <Grid  item xs={8}>
                        <Button 
                          onClick={() => dispatch(getSolicitudes(form)) } 
                          style={{margin:5}} 
                          size="small" 
                          className={classes.button} 
                          variant="contained"
                          > 
                            Buscar
                        </Button>
                        
         
                     
                     </Grid>

           </Grid>          

        </CmtCardContent>
    </CmtCard>

      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
               Listado de Solicitudes
            </Typography>
            <React.Fragment>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
            </React.Fragment>
          </Toolbar>
          
          <TableContainer className={classes.containersolicitudes}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.opcionSolicitada}</TableCell>
                                 <TableCell>{row.solicitadoPor}</TableCell>
                                 <TableCell>{row.fechaSolicitud}</TableCell>
                                 <TableCell>{row.estatus}</TableCell>
                                 <TableCell  align="right">  
                                    <Button 
                                      style={{marginRight:10}} 
                                      size='small' 
                                      className={classes.button} 
                                      variant="contained" 
                                      color='secondary' 
                                      onClick={() => {
                                        if(row.archivosNombreDescargar){
                                          dispatch(descargarSolicitud(row.solicitudEjecucionID,usuarioID))
                                          window.open(row.archivosNombreDescargar,null)
                                        }}
                                        
                                        }> Descargar </Button>
                                    <Button size='small' className={classes.buttonerror} variant="contained" color='secondary'
                                      onClick={() => {
                                            Swal.fire({
                                              title: '¿Estás seguro(a) de Eliminar?',
                                              text: '¡No podrás recuperar este cambio!',
                                              icon: 'warning',
                                              showCancelButton: true,
                                              confirmButtonColor: "red",
                                              confirmButtonText: 'Si, Eliminar',
                                              cancelButtonText: 'No, Mantener',
                                              }).then((result) => {
                                              if (result.value) {
                                                dispatch(EliminarSolicitud(row.solicitudEjecucionID,usuarioID))
                                                setTimeout(() => {
                                                  dispatch(getSolicitudes(form));
                                                }, 1000);
                                              } else if (result.dismiss === Swal.DismissReason.cancel) {
                                                Swal.fire(
                                                'Cancelado',
                                                'No se realizó este cambio :)',
                                                'error'
                                                )
                                              }
                                              })
                                      }}
                                    > Eliminar </Button>
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
         </Paper>
    </div>
      </PageContainer>
  );
}