import {  
  GET_INFORMES, 
  GET_PLANTILLAS,
  SET_HOTEL,
  SET_HOTELGROUP,
  SET_TOUROPERATOR,
  SET_TOUROPERATORGROUP,
  SET_MARKET,
  SET_PAYMENTCONDITION,
  SET_ORDENARPOR,
  SET_VERSIONCCL,
  SET_ROOMTYPE,
  SET_INVTYPE,
  SET_ESTATUSFACTURAS,
  SET_INCLUIRFACTURAS,
  SET_TIPODOCUMENTO,
  SET_ACCOUNTTYPE,
  SET_BALANCE,
  SET_CARTERA,
  SET_FRECUENCY,
  SET_INVOICECONDITION,
  SET_TIPOREPORTE,
  SET_DOCCLASS,
  SET_TRANSACTIONCODE,
  SET_DOCUMENTSING,
  SET_DEPARTAMENT,
  SET_RESPONSIBLE,
  SET_MESES,
  SET_CLASS,
  SET_OPENDIALOG,
  SET_TIPODOCUMENTOFACTURA,
  SET_PAYMODE,
  SET_CREDITDAYS,
  SET_CONTACTTYPE,
  SET_STATE,
  SET_RATETYPE,
  SET_RATERECEIVED,
  SET_AGRUPACIONCARTERA
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  informes: [],
  plantillas: [],
  hotel:[],
  hotelgroup:[],
  touroperator:[],
  touroperatorgroup:[],
  market:[],
  paymentcondition:[],
  ordenarpor:[],
  versionccl:[],
  roomtype:[],
  invtype:[],
  estatusfacturas:[],
  incluirfacturas:[],
  tipodocumento:[],
  accounttype:[],
  balance:[],
  cartera:[],
  frecuency:[],
  invoicecondition:[],
  tiporeporte:[],
  docclass:[],
  transactioncode:[],
  documentsing:[],
  departament:[],
  responsible:[],
  meses:[],
  classdoc:[],
  openDialog:false,
  tipodocumentofactura:[],
  paymode:[],
  creditdays:[],
  contacttype:[],
  statefrm:[],
  ratetype:[],
  ratereceived:[],
  agrupacionCartera:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AGRUPACIONCARTERA: {
      return {
        ...state,
        agrupacionCartera: action.payload,
      };
    }
    case SET_RATETYPE: {
      return {
        ...state,
        ratetype: action.payload,
      };
    }
    case SET_RATERECEIVED: {
      return {
        ...state,
        ratereceived: action.payload,
      };
    }
    case SET_PAYMODE: {
      return {
        ...state,
        paymode: action.payload,
      };
    }
     case SET_CREDITDAYS: {
      return {
        ...state,
        creditdays: action.payload,
      };
    }
    case SET_CONTACTTYPE: {
      return {
        ...state,
        contacttype: action.payload,
      };
    }
    case SET_STATE: {
      return {
        ...state,
        statefrm: action.payload,
      };
    }
    case SET_TIPODOCUMENTOFACTURA: {
      return {
        ...state,
        tipodocumentofactura: action.payload,
      };
    }
    case SET_OPENDIALOG: {
      return {
        ...state,
        openDialog: action.payload,
      };
    }
    case GET_INFORMES: {
      return {
        ...state,
        informes: action.payload,
      };
    }
    case GET_PLANTILLAS: {
      return {
        ...state,
        plantillas: action.payload,
      };
    }
    case SET_HOTEL: {
      return {
        ...state,
        hotel: action.payload,
      };
    }
    case SET_HOTELGROUP: {
      return {
        ...state,
        hotelgroup: action.payload,
      };
    }
    case SET_TOUROPERATOR: {
      return {
        ...state,
        touroperator: action.payload,
      };
    }
    case SET_TOUROPERATORGROUP: {
      return {
        ...state,
        touroperatorgroup: action.payload,
      };
    }
    case SET_MARKET: {
      return {
        ...state,
        market: action.payload,
      };
    }
    case SET_PAYMENTCONDITION: {
      return {
        ...state,
        paymentcondition: action.payload,
      };
    }
    case SET_ORDENARPOR: {
      return {
        ...state,
        ordenarpor: action.payload,
      };
    }
    case SET_VERSIONCCL: {
      return {
        ...state,
        versionccl: action.payload,
      };
    }
    case SET_ROOMTYPE: {
      return {
        ...state,
        roomtype: action.payload,
      };
    }
    case SET_INVTYPE: {
      return {
        ...state,
        invtype: action.payload,
      };
    }
    case SET_ESTATUSFACTURAS: {
      return {
        ...state,
        estatusfacturas: action.payload,
      };
    }
    case SET_INCLUIRFACTURAS: {
      return {
        ...state,
        incluirfacturas: action.payload,
      };
    }
    case SET_TIPODOCUMENTO: {
      return {
        ...state,
        tipodocumento: action.payload,
      };
    }
    case SET_ACCOUNTTYPE: {
      return {
        ...state,
        accounttype: action.payload,
      };
    }
    case SET_BALANCE: {
      return {
        ...state,
        balance: action.payload,
      };
    }
    case SET_CARTERA: {
      return {
        ...state,
        cartera: action.payload,
      };
    }
    case SET_FRECUENCY: {
      return {
        ...state,
        frecuency: action.payload,
      };
    }
    case SET_INVOICECONDITION: {
      return {
        ...state,
        invoicecondition: action.payload,
      };
    }
    case SET_TIPOREPORTE: {
      return {
        ...state,
        tiporeporte: action.payload,
      };
    }
    case SET_DOCCLASS: {
      return {
        ...state,
        docclass: action.payload,
      };
    }
    case SET_TRANSACTIONCODE: {
      return {
        ...state,
        transactioncode: action.payload,
      };
    }
    case SET_DOCUMENTSING: {
      return {
        ...state,
        documentsing: action.payload,
      };
    }
    case SET_DEPARTAMENT: {
      return {
        ...state,
        departament: action.payload,
      };
    }
    case SET_RESPONSIBLE: {
      return {
        ...state,
        responsible: action.payload,
      };
    }
    case SET_MESES: {
      return {
        ...state,
        meses: action.payload,
      };
    }
    case SET_CLASS: {
      return {
        ...state,
        classdoc: action.payload,
      };
    }
    

    
    default:
      return state;
  }
};
