import _ from 'lodash';
import React, { useState }  from 'react';
import { Button } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import useForm from '@jumbo/utils/useForm';
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import { resetpassword} from '../../redux/actions/Auth';
import {makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        flexDirection:'row'
      },
      button: {
        textTransform: "none",
        marginTop:10
      },
      title: {
        flex: '1 1 100%',
      },
      textlabel: {
        '& .MuiFormLabel-root': {
          color: '#232322',
        },
       },
       input:{
        '& .MuiTextField-root': {
          width: '50%',
        },
        flex:1,
        flexDirection:'column' ,
        margin:4
      }

  }));

export default function InformesForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  const { form, setForm } = useForm({
    "UsuarioID": usuarioID,
    "PassWordAnterior": "",
    "PassWord": "",
    "PassWordConfirmacion": "",
  });
const[PassWordAnterior,setPassWordAnterior] = useState('');
const[PassWord,setPassWord] = useState('');
const[PassWordConfirmacion,setPassWordConfirmacion] = useState('');
 
  return (
    <PageContainer heading="Cambiar Clave">
        <CmtCard>
            <CmtCardContent>
            <div className={classes.root}>  
            <div className={classes.input} >
             <Box display="flex" justifyContent="flex-start" flexDirection="column" my={4}>
                <TextField 
                   className={classes.textlabel}
                    label="Clave Anterior" 
                    variant="outlined"
                    value={form.PassWordAnterior}
                    onChange={(value) => {setForm(_.set({ ...form }, 'PassWordAnterior', value.target.value));setPassWordAnterior('')} } 
                    helperText={PassWordAnterior}
                />     

                 <TextField 
                    className={classes.textlabel}
                    label="Clave Nueva" 
                    variant="outlined"
                    value={form.PassWord}
                    onChange={(value) => {setForm(_.set({ ...form }, 'PassWord', value.target.value));setPassWord('') }} 
                    style={{marginTop:10,marginBottom:10}}
                    helperText={PassWord}
                />     
                   <TextField 
                    className={classes.textlabel}
                    label="Clave Nueva Confirmación" 
                    variant="outlined"
                    value={form.PassWordConfirmacion}
                    onChange={(value) => {setForm(_.set({ ...form }, 'PassWordConfirmacion', value.target.value));setPassWordConfirmacion('')} } 
                    helperText={PassWordConfirmacion}
                />  

                   <Box>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() =>{
                       
                       if (!form.PassWordAnterior) {setPassWordAnterior("Claver Anterior Requerido");} 
                       if (!form.PassWord) {setPassWord("Clave Nueva Requerido");}  
                       if (!form.PassWordConfirmacion) {setPassWordConfirmacion("Confirmación Clave Nueva Requerido");}
                       if(form.PassWordAnterior && form.PassWord && form.PassWordConfirmacion){
                        dispatch(resetpassword(form))
                        }
                       }}  >
                       Cambiar Clave
                     </Button>
                </Box>
             </Box>
            </div>    
            </div>   
            </CmtCardContent>

          
             
        </CmtCard>
    </PageContainer>
  );
}