import React , {useEffect,useState} from 'react';
import _ from 'lodash';
import useForm from '@jumbo/utils/useForm';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography,TextField } from '@material-ui/core';
import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getCarteras,mantCarteras,EliminarCartera} from '../../redux/actions/Mantenimiento';
import {getDepartment,getResponsible,getTourOperator} from '../../redux/actions/Ejecucion';
import Autocompetesinapp from '../../routes/Ejecucion/utils/Autocompetesinapp';
import Swal from 'sweetalert2';
import {ValorSelect} from '../Ejecucion/utils/Helper';
import axios from '@jumbo/constants/axios';
import {SET_DIALOGCARTERAS} from '@jumbo/constants/ActionTypes';

const headCells = [
  {
    id: 'departamento',
    numeric: false,
    disablePadding: false,
    label: 'Departamento',
  },
  {
    id: 'colaborador',
    numeric: false,
    disablePadding: false,
    label: 'Colaborador',
  },
  {
    id: 'descripcion',
    numeric: false,
    disablePadding: false,
    label: 'Descripción',
  },
  {
    id: 'cantidadTTOO',
    numeric: false,
    disablePadding: false,
    label: 'CantidadTTOO',
  }
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function PlantillasForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  const {carteras,dialogcartera} =  useSelector(({ mantenimiento }) => mantenimiento);
  const {departament,responsible,touroperator} =  useSelector(({ ejecucion }) => ejecucion);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState('');
 
   const { form, setForm,resetForm } = useForm({
    "carteraID":null,
    "descripcion": "",
    "depID": "",
    "empID": "",
    "tourOpIDs": "",
    "usuarioID":null
  });

  
  useEffect(() => {
      dispatch(getCarteras());
      dispatch(getDepartment());
      dispatch(getTourOperator('na'))
  }, [dispatch ]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return carteras;
      }
      return TableUtils.filterArrayByString(carteras, _searchTerm);
    }
    if (carteras) {
      setData(getFilteredArray(carteras, searchTerm));
    }
  }, [carteras, searchTerm]);

  const handleCloseDialog = () => {
    dispatch({ type: SET_DIALOGCARTERAS, payload: false });
  };

  const handleOpenDialog = (formAppdata) => {
   
    axios.get(`mantenimiento/carteralst/${formAppdata.carteraID}`)
    .then(data => {
       if (data.status === 200) {
       setForm(data.data[0])
      } 
    })
    .catch(error => {
      
    });
 
    dispatch(getResponsible(formAppdata.depID))
   
    dispatch({ type: SET_DIALOGCARTERAS, payload: true });
    
 };


 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');
};

const handlePageChange = (event, newPage) => {
  setPage(newPage);
};

const handleRowsPerPageChange = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  return (
    <PageContainer heading="Carteras">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
               Listado de Carteras
            </Typography>
            <React.Fragment>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon={false} />
            </React.Fragment>
            <React.Fragment>
            <Button onClick={() => { dispatch({ type: SET_DIALOGCARTERAS, payload: true });;resetForm()} }  style={{marginRight:5}} size='small' className={classes.button} variant="contained" color="secondary">Agregar</Button>                                   
            </React.Fragment>
          </Toolbar>
          
          <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.departamento}</TableCell>
                                 <TableCell>{row.colaborador}</TableCell>
                                 <TableCell>{row.descripcion}</TableCell>
                                 <TableCell>{row.cantidadTTOO}</TableCell>
                                 <TableCell  align="right">  
                                 <Button style={{marginRight:5}} size='small' className={classes.button} variant="contained" color="secondary" onClick={() => handleOpenDialog(row) } >Editar</Button>                                   
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
         </Paper>
    </div>
      <>
        <Dialog open={dialogcartera}  className={classes.dialogRoot} fullWidth maxWidth="sm" >
            <DialogTitle className={classes.dialogTitleRoot}> Cartera </DialogTitle>
            <DialogContent dividers>
            <div className={classes.root}>  
           
                 <TextField 
                     className={classes.textlabel}
                     fullWidth
                     label="Descripción" 
                     variant="outlined"
                     value={form.descripcion}
                     onChange={(value) =>  setForm(_.set({ ...form }, 'descripcion',value.target.value))} 
                     size="small"
                     style={{marginBottom:10}}
                     /> 
              
                <Autocompetesinapp 
                    options={departament} 
                    name="Departament"
                    value={form.depID === "" ? null : departament.find(x => x.value === form.depID.toString())}
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'depID', ValorSelect(newValue))) 
                       dispatch(getResponsible(newValue.value))
                    }}
                />
             
                <div style={{marginTop:7,marginBottom:7}}>
                 <Autocompetesinapp 
                    options={responsible} 
                    name="Responsible"
                    value={form.empID === "" ? null : responsible.find(x => x.value === form.empID.toString())}
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'empID', ValorSelect(newValue))) 
                    }}
                />
                 </div>
                  
                 <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    value={touroperator.filter(x => form.tourOpIDs.split(",").includes(x.value))}
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'tourOpIDs', ValorSelect(newValue))) 
                    }}
                />


            </div>
                <Box display="flex" justifyContent="flex-end" my={4}>
                  <Button variant="contained" onClick={handleCloseDialog}>Cancelar</Button>
                  {form.carteraID !== null &&
                    <Box ml={2}>
                    <Button variant="contained" className={classes.buttonerror} onClick={() => {
                     form.usuarioID = usuarioID;

                     Swal.fire({
                      title: '¿Estás seguro(a) de Eliminar?',
                      text: '¡No podrás recuperar este cambio!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: "red",
                      confirmButtonText: 'Si, Eliminar',
                      cancelButtonText: 'No, Mantener',
                      }).then((result) => {
                      if (result.value) {
                        dispatch(EliminarCartera(form.carteraID))
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                        'Cancelado',
                        'No se realizó este cambio :)',
                        'error'
                        )
                      }
                      })
                      }}>
                        Eliminar
                    </Button>
                      </Box>
                     }
                    <Box ml={2}>
                    <Button variant="contained" color="secondary" onClick={() => {
                        form.usuarioID = usuarioID;
                        dispatch(mantCarteras(form))
                    }}>
                        Guardar
                    </Button>
                    </Box>
                </Box>
            </DialogContent>  
        </Dialog>
      </>
    </PageContainer>
  );
}