import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useForm from '@jumbo/utils/useForm';
import { IniciarAccountStatement,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import Grid from '@material-ui/core/Grid';

const AccountStatement = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
     const { hotel,hotelgroup,touroperator,touroperatorgroup,market,paymentcondition,balance,invtype,accounttype} = useSelector(({ ejecucion }) => ejecucion);
    const { form, setForm } = useForm({
      "hotel": null,
      "hotelgroup": null,
      "touroperator": null,
      "touroperatorgroup": null,
      "market": null,
      "paymentcondition": null,
      "invoicetype": null,
      "accounttype": null,
      "asofdate":null,
      "excluircci":null,
      "nomostrardetalle":null,
      "incluiragingbalance":null,
      "mostrarnotas":null,
      "checkar":null,
      "checkfl":null,
      "checkgrossbilling":null,
      "check_by_booking":null,
      "checkincluirearlycheckout":true,
      "checkincluirnc":true,
      "checkincluirfechaenvio":null,
      "balance": null,
    });

     useEffect(() => {
        dispatch(IniciarAccountStatement(formApp.formAppID));
     }, [dispatch,formApp ]);



    const ejecucion = () => {
       let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
        }else{variables += "null"}

        if(form.hotelgroup){variables += `|${form.hotelgroup}`
        }else{variables += "|null"}

        if(form.touroperator){variables += `|${form.touroperator}`
        }else{variables += "|null"}

        if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
        }else{variables += "|null"}
        
        if(form.market){variables += `|${form.market}`
        }else{variables += "|null"}

        if(form.paymentcondition){variables += `|${form.paymentcondition}`
        }else{variables += "|null"}

        if(form.invoicetype){variables += `|${form.invoicetype}`
        }else{variables += "|null"}

        if(form.accounttype){variables += `|${form.accounttype}`
        }else{variables += "|null"}

        if(form.asofdate){variables += `|${form.asofdate}`
        }else{variables += "|null"}

        if(form.excluircci){variables += `|${form.excluircci}`
        }else{variables += "|null"}

        if(form.nomostrardetalle){variables += `|${form.nomostrardetalle}`
        }else{variables += "|null"}

        if(form.incluiragingbalance){variables += `|${form.incluiragingbalance}`
        }else{variables += "|null"}

        if(form.mostrarnotas){variables += `|${form.mostrarnotas}`
         }else{variables += "|null"}

        if(form.checkar){variables += `|${form.checkar}`
        }else{variables += "|null"}

        if(form.checkfl){variables += `|${form.checkfl}`
        }else{variables += "|null"}

        if(form.checkgrossbilling){variables += `|${form.checkgrossbilling}`
        }else{variables += "|null"}

        if(form.check_by_booking){variables += `|${form.check_by_booking}`
       }else{variables += "|null"}

       if(form.checkincluirearlycheckout){variables += `|${form.checkincluirearlycheckout}`
      }else{variables += "|null"}

      if(form.checkincluirnc){variables += `|${form.checkincluirnc}`
       }else{variables += "|null"}

      if(form.checkincluirfechaenvio){variables += `|${form.checkincluirfechaenvio}`
      }else{variables += "|null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":form.balance,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }

         dispatch(SolicitarEjecucion(Solicitar))
      };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography variant='h4'>Criterios de busqueda</Typography>
              <Divider classes={{root: classes.divider}}/>
              
              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    options={hotelgroup} 
                    name="Hotel Group"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                    }}
                />

                
            <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    options={touroperatorgroup} 
                    name="Tour Operator Group"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={market} 
                    name="Market"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    options={paymentcondition} 
                    name="Payment Condition"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'paymentcondition', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={invtype} 
                    name="Invoice Type"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'invoicetype', ValorSelect(newValue))) 
                    }}
                />

                  <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={accounttype} 
                    name="Account Type"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'accounttype', ValorSelect(newValue))) 
                    }}
                />
               <div>
                  <Pickersinapp
                    label="As Of Date"
                    value={form.asofdate}
                    onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'asofdate', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'asofdate', null))}}
                    style={{paddingRight: 10}}
                  />
               </div>
               <Grid container > 
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={ <Checkbox
                          checked={form.excluircci}
                          onChange={x => {setForm(_.set({ ...form }, 'excluircci', x.target.checked))}}
                          color="primary"
                        />}
                        label="Excluir Cedar Creek International"
                      />
                  </Grid>
                  <Grid item xs={3}>
                  <FormControlLabel
                      control={ <Checkbox
                        checked={form.nomostrardetalle}
                        onChange={x => {setForm(_.set({ ...form }, 'nomostrardetalle', x.target.checked))}}
                        color="primary"
                      />}
                      label="No Mostrar Detalle"
                    />
                    </Grid> 
                    <Grid item xs={3}>
                  <FormControlLabel
                      control={ <Checkbox
                        checked={form.incluiragingbalance}
                        onChange={x => {setForm(_.set({ ...form }, 'incluiragingbalance', x.target.checked))}}
                        color="primary"
                      />}
                      label="Aging Balance"
                    />
                    </Grid> 
                </Grid>

                <Grid container item xs={12} >
                <Grid item xs={6}>
                <FormControlLabel
                    control={ <Checkbox
                      checked={form.mostrarnotas}
                      onChange={x => {setForm(_.set({ ...form }, 'mostrarnotas', x.target.checked))}}
                      color="primary"
                    />}
                    label="Mostrar Notas"
                  />
                  </Grid>
                <Grid item xs={3}>
              <FormControlLabel
                    control={ <Checkbox
                      checked={form.checkar}
                      onChange={x => {setForm(_.set({ ...form }, 'checkar', x.target.checked))}}
                      color="primary"
                    />}
                    label="AR"
                  />
                  </Grid>
                <Grid item xs={3}>
                <FormControlLabel
                    control={ <Checkbox
                      checked={form.checkfl}
                      onChange={x => {setForm(_.set({ ...form }, 'checkfl', x.target.checked))}}
                      color="primary"
                    />}
                    label="FL"
                  />
                  </Grid>
                  </Grid>

              <Grid container item xs={12} >
              <Grid item xs={6}>
                <FormControlLabel
                    control={ <Checkbox
                      checked={form.checkgrossbilling}
                      onChange={x => {setForm(_.set({ ...form }, 'checkgrossbilling', x.target.checked))}}
                      color="primary"
                    />}
                    label="Gross Billing"
                  />
                  </Grid>
                <Grid item xs={3}>
                <FormControlLabel
                    control={ <Checkbox
                      checked={form.check_by_booking}
                      onChange={x => {setForm(_.set({ ...form }, 'check_by_booking', x.target.checked))}}
                      color="primary"
                    />}
                    label="Report by Booking"
                  />
                  </Grid>
                <Grid item xs={3}>
              <FormControlLabel
                    control={ <Checkbox
                      checked={form.checkincluirearlycheckout}
                      onChange={x => {setForm(_.set({ ...form }, 'checkincluirearlycheckout', x.target.checked))}}
                      color="primary"
                    />}
                    label="Incluir Early Check Out "
                  />
                  </Grid>
                </Grid>
               
                <Grid container item xs={12} > 
                <Grid item xs={6}>
                <FormControlLabel
                    control={ <Checkbox
                      checked={form.checkincluirnc}
                      onChange={x => {setForm(_.set({ ...form }, 'checkincluirnc', x.target.checked))}}
                      color="primary"
                    />}
                    label="Incluir NC "
                  />
                  </Grid>
                <Grid item xs={3}>
                <FormControlLabel
                    control={ <Checkbox
                      checked={form.checkincluirfechaenvio}
                      onChange={x => {setForm(_.set({ ...form }, 'checkincluirfechaenvio', x.target.checked))}}
                      color="primary"
                    />}
                    label="Incluir Fecha de Envio"
                  />
                  </Grid>
                  </Grid>
        </Grid>

              <Typography variant='h4'>Balance</Typography>
              <Divider classes={{root: classes.divider}}/>

              <Autocompetesinapp 
                    options={balance} 
                    name="Balance"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'balance', ValorSelect(newValue))) 
                    }}
                />

              </div>
        
            <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
            </DialogContent>  
        </Dialog>
    )
};

export default AccountStatement;

AccountStatement.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
