import React , {useEffect,useState,useCallback} from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography } from '@material-ui/core';
import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getUsuarios,getEditarUsuario,GrabarUsuario,EditarUsuario,getRolesByUsuario,agregarRolesUsuario,resetpswuser,InactivarUsuario,BorrarUsuario,ActivarUsuario} from '../../redux/actions/Auth';
import { SET_ADDUSUARIO,SET_CLOSEDILOGUSUARIO } from '@jumbo/constants/ActionTypes';
import _ from 'lodash';
import useForm from '@jumbo/utils/useForm';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import AppBar from '@material-ui/core/AppBar';
import Swal from 'sweetalert2';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const headCells = [
  {
    id: 'loginName',
    numeric: false,
    disablePadding: false,
    label: 'Login Name',
  },
  {
    id: 'nombre',
    numeric: false,
    disablePadding: false,
    label: 'Nombre',
  },
  {
    id: 'apellido',
    numeric: false,
    disablePadding: false,
    label: 'Apellido',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'estatus',
    numeric: false,
    disablePadding: false,
    label: 'Estatus',
  },
  {
    id: 'codigo',
    numeric: false,
    disablePadding: false,
    label: 'Codigo',
  }
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useItemStyles = makeStyles(theme => ({
  root: {
    "& > .MuiTreeItem-content > .MuiTreeItem-label": {
      display: "flex",
      alignItems: "center",
      padding: "4px 0",
      background: "transparent !important",
      pointerEvents: "none"
    },
    "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
      content: "''",
      display: "inline-block",
      width: 12,
      height: 12,
      marginRight: 8,
      border: "1px solid #ccc",
      background: "white",
    },
  },
  label: {
    padding: 0
  },
  selected: {
    "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
      background: theme.palette.primary.main,
      border: "1px solid transparent"
    }
  }
}));

const useViewStyles = makeStyles({
  root: {}
});


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box my={3}>
          <Typography >{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Usuariodatadefaul = { 
  "usuarioID": null,
  "loginName": "",
  "nombre": "",
  "apellido": "",
  "email": "",
  "telefono": "",
  "direccion": "",
  "foto": "",
  "createdBy": null};
  
export default function InformesForm() {
  const classes = useStyles();
  const classesView = useViewStyles();
  const dispatch = useDispatch();
  const {usuarios,formDialogUsuario,rolesbyusuario,authUser} =  useSelector(({ auth }) => auth);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const { form, setForm } = useForm(Usuariodatadefaul);
  const [value, setValue] = React.useState(0);
  const [nombreError, setnombreError] = useState('');
  const [apellidoError, setapellidoError] = useState('');
  const [loginnameError, setloginnameError] = useState('');
  const classesItem = useItemStyles();
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const [Activo, setActivo] = React.useState(null);
	const [Inactivo, setInactivo] = React.useState(null);
  const [ValueUserSelect, setValueUserSelect] = useState(null);

  const handleClickInactivo = (event) => {
		setInactivo(event.currentTarget);
    };
  
	const handleClickActivo = (event) => {
		setActivo(event.currentTarget);
	};

	const handleCloseInactivo = (item) => {
		setInactivo(null);
  	};

    const handleCloseActivo = (item) => {
      setActivo(null);
	};

  const editUsuario = (event) => {
    dispatch(getEditarUsuario(ValueUserSelect.usuarioID))
    setActivo(null);
    setInactivo(null);
  };

  const BorrarUsr = (event) => {

    Swal.fire({
      title: '¿Estás seguro(a) de Borrar Usuario?',
      text: '¡No podrás recuperar este cambio!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: 'Si, Borrar',
      cancelButtonText: 'No, Mantener',
      }).then((result) => {
      if (result.value) {
        dispatch(BorrarUsuario(ValueUserSelect.usuarioID,authUser.usuarioID))
        setActivo(null);
        setInactivo(null);

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
        'Cancelado',
        'No se realizó este cambio :)',
        'error'
        )
      }
      })

  
  };

  const Cambiarclave = () =>{
    Swal.fire({
      title: '¿Estás seguro(a) de Cambiar Clave?',
      text: '¡No podrás recuperar este cambio!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: 'Si, Cambiar',
      cancelButtonText: 'No, Mantener',
      }).then((result) => {
      if (result.value) {
        dispatch(resetpswuser(ValueUserSelect.usuarioID))
        setActivo(null);
        setInactivo(null);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
        'Cancelado',
        'No se realizó este cambio :)',
        'error'
        )
      }
      })
  }

  const InactivarUsr =() =>{
    Swal.fire({
      title: '¿Estás seguro(a) de inactivar este usuario?',
      text: '¡No podrás recuperar este cambio!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: 'Si, inactivar',
      cancelButtonText: 'No, Mantener',
      }).then((result) => {
      if (result.value) {
        dispatch(InactivarUsuario(ValueUserSelect.usuarioID,authUser.usuarioID))
        setActivo(null);
        setInactivo(null);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
        'Cancelado',
        'No se realizó este cambio :)',
        'error'
        )
      }
      })
  }

  const ActivarUsr =() =>{
    Swal.fire({
      title: '¿Estás seguro(a) de Activar este usuario?',
      text: '¡No podrás recuperar este cambio!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: 'Si, Activar',
      cancelButtonText: 'No, Mantener',
      }).then((result) => {
      if (result.value) {
        dispatch(ActivarUsuario(ValueUserSelect.usuarioID,authUser.usuarioID))
        setActivo(null);
        setInactivo(null);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
        'Cancelado',
        'No se realizó este cambio :)',
        'error'
        )
      }
      })
  }

  useEffect(() => {
      dispatch(getUsuarios());
  }, [dispatch ]);




  const initDialog = useCallback(() => {
		if (formDialogUsuario.type === 'edit' && formDialogUsuario.data) {
		setForm({ ...formDialogUsuario.data });
    dispatch(getRolesByUsuario(formDialogUsuario.data.usuarioID))
		}
	
		if (formDialogUsuario.type === 'new') {
			setForm({...Usuariodatadefaul});
		}
    }, [formDialogUsuario.data, formDialogUsuario.type, setForm,dispatch]);

   useEffect(() => {
    initDialog()
 }, [formDialogUsuario.props.open, initDialog,dispatch]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return usuarios;
      }
      return TableUtils.filterArrayByString(usuarios, _searchTerm);
    }
    if (usuarios) {
      setData(getFilteredArray(usuarios, searchTerm));
    }
  }, [usuarios, searchTerm]);

  useEffect(() => {
    let arr = []
    rolesbyusuario.filter(x => x.aplicado === true).forEach(numero =>{
      arr.push(numero.rolID)
   });
    setSelected(arr)
	}, [rolesbyusuario]);
  

 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');
};

const handlePageChange = (event, newPage) => {
  setPage(newPage);
};

const handleRowsPerPageChange = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

const handleChange = (event, newValue) => {
  setValue(newValue);
};

const handleToggle = (event, nodeIds) => {
  if (event.target.nodeName !== "svg") {
    return;
  }
  setExpanded(nodeIds);
};

const handleSelect = (event, nodeIds) => {
  if (event.target.nodeName === "svg") {
    return;
  }
  const first = nodeIds[0];
  if (selected.includes(first)) {
    setSelected(selected.filter(id => id !== first));
  } else {
    setSelected([first, ...selected]);
  }
};

const renderTree = (nodes) => (
  <TreeItem classes={classesItem}  key={nodes.rolID} nodeId={nodes.rolID} label={nodes.descripcion}>
    {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
  </TreeItem>
);


  return (
    <PageContainer heading="Usuarios">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
               <Button color="secondary" variant='outlined' onClick={() =>  dispatch({ type: SET_ADDUSUARIO })}>
                Agregar Usuario
              </Button>
            </Typography>
            <React.Fragment>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
            </React.Fragment>
          </Toolbar>
          
          <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.loginName}</TableCell>
                                 <TableCell>{row.nombre}</TableCell>
                                 <TableCell>{row.apellido}</TableCell>
                                 <TableCell>{row.email}</TableCell>
                                 <TableCell>{row.estatus}</TableCell>
                                 <TableCell>{row.codigo}</TableCell>
                                 <TableCell  align="right">  

                                 <IconButton color="primary" >
                                    <MoreVertIcon onClick={ev => {{row.estatus === "Activo" ? handleClickActivo(ev) : handleClickInactivo(ev) }
                                        setValueUserSelect(row)
                                      }} 
                                    />
                                  </IconButton>
                                    <Menu
                                      anchorEl={Activo}
                                      keepMounted
                                      open={Boolean(Activo)}
                                      onClose={() => handleCloseActivo(null)}
                                    >
                                    <MenuItem onClick={ event => editUsuario(ValueUserSelect)} >
                                        Editar 
                                      </MenuItem>
                                      <MenuItem onClick={event => BorrarUsr(ValueUserSelect)} >
                                        Borrar
                                      </MenuItem>
                                      <MenuItem onClick={event => Cambiarclave(ValueUserSelect)} >
                                        Cambiar Clave
                                      </MenuItem>
                                      <MenuItem onClick={event => InactivarUsr(ValueUserSelect)} >
                                        Inactivar
                                      </MenuItem>
                                    </Menu>

                                    <Menu
                                      anchorEl={Inactivo}
                                      keepMounted
                                      open={Boolean(Inactivo)}
                                      onClose={() => handleCloseInactivo(null)}
                                    >
                                      <MenuItem onClick={ event => editUsuario(ValueUserSelect) } >
                                        Editar 
                                      </MenuItem>
                                      <MenuItem onClick={event => BorrarUsr(ValueUserSelect)} >
                                        Borrar
                                      </MenuItem>
                                      <MenuItem onClick={event => Cambiarclave(ValueUserSelect)} >
                                        Cambiar Clave
                                      </MenuItem>
                                      <MenuItem onClick={event => ActivarUsr(ValueUserSelect)} >
                                        Activar
                                      </MenuItem>
                                    </Menu>
                                    
										            
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
         </Paper>
    </div>
      <>{formDialogUsuario.props.open &&
        <Dialog open={formDialogUsuario.props.open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
            <DialogTitle className={classes.dialogTitleRoot}> {formDialogUsuario.type === "new" ? 'Agregar Usuario' : "Editar Usuario"}</DialogTitle>
            <DialogContent dividers>
            <div className={classes.root}>  

            <Box className={classes.root} mb={{ xs: 4, sm: 6 }}>
            <AppBar position="static" color='inherit'  >
              <Tabs value={value} onChange={handleChange} >
                <Tab className={ classes.customLabelColor}   label={`Usuario`} {...a11yProps(0)} />
                {form.usuarioID !== null &&  <Tab className={ classes.customLabelColor}   label={`Roles`} {...a11yProps(1)} /> }
              </Tabs>
            </AppBar>
          <TabPanel value={value} index={0}>
            
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 3, md: 3 }} >
                <GridContainer>
                  <Grid item xs={12} sm={6}>
                    <AppTextInput
                      fullWidth
                      variant="outlined"
                      label="Nombre"
                      value={form.nombre}
                       onChange={e => {
                        setForm(_.set({ ...form }, 'nombre', e.target.value)) 
                        setnombreError('');
                      }}
                      helperText={nombreError}
                      style={{marginTop:10}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AppTextInput
                      fullWidth
                      variant="outlined"
                      label="Apellido"
                      value={form.apellido}
                      onChange={e =>{setForm(_.set({ ...form }, 'apellido', e.target.value)); setapellidoError('') }}
                      helperText={apellidoError}
                      style={{marginTop:10}}
                    />
                  </Grid>
                </GridContainer>
              </Box>
             
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 3, md: 3 }}>
              <GridContainer>
                  <Grid item xs={12} sm={6}>
                    <AppTextInput
                      fullWidth
                      variant="outlined"
                      label="Login Name"
                      value={form.loginName}
                      onChange={e => {
                        setForm(_.set({ ...form }, 'loginName', e.target.value)) 
                        setloginnameError('');
                      }}
                      helperText={loginnameError}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AppTextInput
                      fullWidth
                      variant="outlined"
                      label="Email"
                      value={form.email}
                      onChange={e =>setForm(_.set({ ...form }, 'email', e.target.value)) }
                    />
                  </Grid>
                </GridContainer>
                </Box>

                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 4, md: 4 }}>
                  <GridContainer>
                      <Grid item xs={12} sm={6}>
                        <AppTextInput
                          fullWidth
                          variant="outlined"
                          label="Dirección"
                          value={form.direccion}
                          onChange={e => {
                            setForm(_.set({ ...form }, 'direccion', e.target.value))
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <AppTextInput
                          fullWidth
                          variant="outlined"
                          label="Telefono"
                          value={form.telefono}
                          onChange={e =>setForm(_.set({ ...form }, 'telefono', e.target.value)) }
                        />
                      </Grid>
                    </GridContainer>
                </Box>    
                <Box display="flex" justifyContent="flex-end" my={4}>
                    <Box ml={2}>
                      <Button className={classes.button} variant="contained" onClick={() => {
                        dispatch({ type: SET_CLOSEDILOGUSUARIO })
                        setnombreError('')
                        setapellidoError('')
                        setloginnameError('')
                        }}>Cancelar</Button>
                    </Box>
                    <Box ml={2}>
                    <Button className={classes.button} variant="contained" color="secondary" onClick={() => {

                            if (!form.nombre) {setnombreError("Nombre Requerido");} 
                             if (!form.apellido) {setapellidoError("Apellido Requerido");}  
                             if (!form.loginName) {setloginnameError("Login Name requerido");}
                             if(form.nombre && form.apellido && form.loginName)
                             {
                              if(formDialogUsuario.type === "new" ){
                                dispatch(GrabarUsuario(form,authUser.usuarioID))
                              }else{
                                dispatch(EditarUsuario(form,authUser.usuarioID))
                              }
                            }
                    }}>
                        Guardar
                    </Button>
                    </Box>
                </Box>       
          </TabPanel>
          <TabPanel value={value} index={1}>
            {rolesbyusuario.length >= 1 &&
                <TreeView
                  classes={classesView}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expanded}
                  selected={selected}
                  onNodeToggle={handleToggle}
                  onNodeSelect={handleSelect}
                  multiSelect
                >
                  {rolesbyusuario.map(value =>(renderTree(value)))}
                </TreeView>
              }

                <Box display="flex" justifyContent="flex-end" my={4}>
                    <Button className={classes.button} variant="contained" onClick={() => dispatch({ type: SET_CLOSEDILOGUSUARIO })}>Cancelar</Button>
                    <Box ml={2}>
                    <Button className={classes.button} variant="contained" color="secondary" onClick={() => {
                      let value={
                        usuarioID:form.usuarioID,
                        rolesID:selected.toString()
                      }
                      dispatch(agregarRolesUsuario(value))
                      }}>
                        Guardar
                    </Button>
                    </Box>
                </Box> 

          </TabPanel>
       
        </Box>
            </div>
           
            </DialogContent>  
        </Dialog>
        }
      </>
    </PageContainer>
  );
}


