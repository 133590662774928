import {  SET_SOLICITUDES, SET_NOTIFICACIONES } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  solicitudes:[],
  notificaciones: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case SET_SOLICITUDES: {
      return {
        ...state,
        solicitudes: action.payload,
      };
    }
    case SET_NOTIFICACIONES: {
      return {
        ...state,
        notificaciones: action.payload,
      };
    }
    default:
      return state;
  }
};
