import{  
  UPDATE_AUTH_USER, 
  UPDATE_LOAD_USER,
  RESET_PASSWORD,
  SET_USUARIOS,
  SET_ADDUSUARIO,
  SET_EDITUSUARIO,
  SET_CLOSEDILOGUSUARIO,
  SET_ROLESBYUSUARIO,
  SET_ROLES, 
  SET_ADDROLES,
  SET_EDITROLES,
  SET_CLOSEDILOGROLES,
  SET_FORMROL,
  SET_MENUROL
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  authUser: null,
  loadUser: false,
  reset_password:null,
  usuarios:[],
   formDialogUsuario: {
    type: 'new',
    props: {
      open: false
    },
    data: { 
      "usuarioID": null,
      "loginName": "",
      "nombre": "",
      "apellido": "",
      "email": "",
      "telefono": "",
      "direccion": "",
      "foto": "",
      "createdBy": null}
  },
  rolesbyusuario:[],
  roles:[],
  formDialogRoles: {
    type: 'new',
    props: {
      open: false
    },
    data: { 
      "rolID": null,
      "nombre": "",
      "descripcion": "",
      "activo": true,
      "createdBy": null}
  },
  formrol:[],
  menurol:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MENUROL: {
      return {
        ...state,
        menurol: action.payload,
      };
    }
    case SET_FORMROL: {
      return {
        ...state,
        formrol: action.payload,
      };
    }
    case SET_ADDROLES: {
      return {
        ...state,
        formDialogRoles: {
          type: 'new',
          props: {
            open: true
          },
          data: null
        }
        
      };
    }
    case SET_EDITROLES: {
      return {
        ...state,
        formDialogRoles: {
          type: 'edit',
          props: {
            open: true
          },
          data: action.payload
        }
        
      };
    }
    case SET_CLOSEDILOGROLES: {
      return {
        ...state,
        formDialogRoles: {
          type: 'edit',
          props: {
            open: false
          },
          data: null
        }
        
      };
    }
    case SET_ROLESBYUSUARIO: {
      return {
        ...state,
        rolesbyusuario: action.payload,
      };
    }
    case SET_ROLES: {
      return {
        ...state,
        roles: action.payload,
      };
    }
    case SET_ADDUSUARIO: {
      return {
        ...state,
        formDialogUsuario: {
          type: 'new',
          props: {
            open: true
          },
          data: null
        }
        
      };
    }
    case SET_EDITUSUARIO: {
      return {
        ...state,
        formDialogUsuario: {
          type: 'edit',
          props: {
            open: true
          },
          data: action.payload
        }
        
      };
    }
    case SET_CLOSEDILOGUSUARIO: {
      return {
        ...state,
        formDialogUsuario: {
          type: 'edit',
          props: {
            open: false
          },
          data: null
        }
        
      };
    }
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        reset_password: action.payload,
      };
    }
    case SET_USUARIOS: {
      return {
        ...state,
        usuarios: action.payload,
      };
    }
    default:
      return state;
  }
};
