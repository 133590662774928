import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS,GET_MENU,SET_MENUSELECT } from '../../@jumbo/constants/ActionTypes';

export const fetchSuccess = message => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
  };
};


export const getmenu = status => {
  return dispatch => {
    dispatch({
      type: GET_MENU,
      payload: status,
    });
  };
};

export const setmenuselect = menuselect => {
  return dispatch => {
    dispatch({
      type: SET_MENUSELECT,
      payload: menuselect,
    });
  };
};
