import React , {useEffect,useState} from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography } from '@material-ui/core';
import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getTTToDiasHabiles,getTiposFacturacion,GrabarDiasHabiles,EliminarDiasHabiles} from '../../redux/actions/Mantenimiento';
import TextField from '@material-ui/core/TextField';
import Autocompetesinapp from '../Ejecucion/utils/Autocompetesinapp';
import { ValorSelect } from 'routes/Ejecucion/utils/Helper';
import Swal from 'sweetalert2';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const headCells = [
  {
    id: 'tourOp',
    numeric: false,
    disablePadding: false,
    label: 'Tour Operador',
  },
  {
    id: 'payCond',
    numeric: false,
    disablePadding: false,
    label: 'Condición de Pago',
  },
  {
    id: 'creditDays',
    numeric: false,
    disablePadding: false,
    label: 'Días PP/CR',
  },
  {
    id: 'diasHabil',
    numeric: false,
    disablePadding: false,
    label: 'Días Hábiles',
  },
  {
    id: 'tipoFacturacion',
    numeric: false,
    disablePadding: false,
    label: 'Tipo Facturación',
  }
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function PlantillasForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {ttoodiashabiles,tiposdefacturacion} =  useSelector(({ mantenimiento }) => mantenimiento);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [diashabiles,setdiashabiles] = useState('')
  const [tipoFacturacionTTOOID,settipoFacturacionTTOOID] = useState('-10000');
  const [formApp, setformApp] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
 
  useEffect(() => {
      dispatch(getTTToDiasHabiles());
  }, [dispatch ]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return ttoodiashabiles;
      }
      return TableUtils.filterArrayByString(ttoodiashabiles, _searchTerm);
    }
    if (ttoodiashabiles) {
      setData(getFilteredArray(ttoodiashabiles, searchTerm));
    }
  }, [ttoodiashabiles, searchTerm]);

  const handleCloseDialog = () => {
      setformApp('');
      setdiashabiles('');
      settipoFacturacionTTOOID('-10000');
      setOpenDialog(false);
  };

  const handleOpenDialog = (formAppdata) => {
    setformApp(formAppdata)
    setdiashabiles(formAppdata.diasHabil)
    settipoFacturacionTTOOID(formAppdata.tipoFacturacionTTOOID)
    setOpenDialog(true);
    dispatch(getTiposFacturacion())
    
 };


 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');
};

const handlePageChange = (event, newPage) => {
  setPage(newPage);
};

const handleRowsPerPageChange = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  return (
    <PageContainer heading="Condición de Pago">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
           
            <Typography className={classes.titlecondicion} variant="h4" id="tableTitle" component="div">
               Listado de Tours Operadores
            </Typography>
         
            <React.Fragment>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
            </React.Fragment>
          
            <React.Fragment>
              <ExcelFile element={<Button  variant="contained" color='secondary'>Export Excel</Button>}>
               <ExcelSheet data={ttoodiashabiles} name="Condiciones de pago">
                <ExcelColumn label="tourOp" value="tourOp"/>
                <ExcelColumn label="Pay Condicion" value="payCond"/>
                <ExcelColumn label="Credit Days" value="creditDays"/>
                <ExcelColumn label="Dias Habil" value="diasHabil"/>
                <ExcelColumn label="Tipo Facturación" value="tipoFacturacion"/>
               </ExcelSheet>
              </ExcelFile>
            </React.Fragment>
         
          </Toolbar>
          
          <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.tourOp}</TableCell>
                                 <TableCell>{row.payCond}</TableCell>
                                 <TableCell>{row.creditDays}</TableCell>
                                 <TableCell>{row.diasHabil}</TableCell>
                                 <TableCell>{row.tipoFacturacion}</TableCell>
                                 <TableCell  align="right">  
                                    <Button  size="small" style={{marginRight:5}} className={classes.button} variant="contained" color="secondary" onClick={() => handleOpenDialog(row)} >Editar</Button>
                                    <Button  size="small" className={classes.buttonerror} variant="contained" onClick={() =>{
                                         Swal.fire({
                                          title: '¿Estás seguro(a) de Eliminar?',
                                          text: '¡No podrás recuperar este cambio!',
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonColor: "red",
                                          confirmButtonText: 'Si, Eliminar',
                                          cancelButtonText: 'No, Mantener',
                                          }).then((result) => {
                                          if (result.value) {
                                            dispatch(EliminarDiasHabiles(row.tourOpDiasHabilID))
                                          } else if (result.dismiss === Swal.DismissReason.cancel) {
                                            Swal.fire(
                                            'Cancelado',
                                            'No se realizó este cambio :)',
                                            'error'
                                            )
                                          }
                                          })
                                       }} >Eliminar</Button>
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
         </Paper>
    </div>
      <>
        <Dialog open={openDialog}  className={classes.dialogRoot} fullWidth maxWidth="sm" >
            <DialogTitle className={classes.dialogTitleRoot}> Editar Plantilla </DialogTitle>
            <DialogContent dividers>
            <div className={classes.root}>  
              
               <TextField 
                     className={classes.textlabel}
                     fullWidth
                     label="Días Hábiles" 
                     variant="outlined"
                     value={diashabiles}
                     onChange={(value) => setdiashabiles(value.target.value) } 
                     size="small"
                     style={{marginBottom:10}}
                     /> 
                {tiposdefacturacion.length > 0 &&
                  <Autocompetesinapp 
                    options={tiposdefacturacion} 
                    value={tiposdefacturacion.find(x => x.value === tipoFacturacionTTOOID.toString())}
                    name="Tipo Facturación"
                    onChange={(event, newValue) => {
                      settipoFacturacionTTOOID(ValorSelect(newValue))
                    }}
                    fullWidth
                  />
                }

            </div>
                <Box display="flex" justifyContent="flex-end" my={4}>
                    <Button variant="contained" onClick={handleCloseDialog}>Cancelar</Button>
                    <Box ml={2}>
                    <Button variant="contained" color="secondary" onClick={() => {
                      dispatch(GrabarDiasHabiles(formApp.tourOpID,tipoFacturacionTTOOID,diashabiles))
                      handleCloseDialog()
                    }}>
                        Guardar
                    </Button>
                    </Box>
                </Box>
            </DialogContent>  
        </Dialog>
      </>
    </PageContainer>
  );
}