import React , {useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Informes from './Informes';
import Plantillas from './Plantillas';
import CmtCard from '../../@coremat/CmtCard';
import CmtCardHeader from '../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import { useDispatch, useSelector } from 'react-redux';
import { getInformes,getPlantillas,getMeses} from '../../redux/actions/Ejecucion';
import {setmenuselect} from '../../redux/actions/Common';
import { useLocation } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box my={3}>
          <Typography >{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  customLabelColor: {
    color: "#000000",
    fontWeight:'bold',
    textTransform: "none",
    fontSize:16
  }
}));

export default function Grupos() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  const {informes,plantillas} =  useSelector(({ ejecucion }) => ejecucion);
  const {menuselect,menu} =  useSelector(({ common }) => common);
 
  useEffect(() => {
    if(menuselect.menuid){
        dispatch(getInformes(menuselect.menuid,usuarioID));
        dispatch(getPlantillas(menuselect.menuid,usuarioID));
        setValue(0)
      if(menuselect.menuid === 7000 ){
            dispatch(getMeses())
        }
    }
    else{
     dispatch(setmenuselect(menu[0].children.find(x => x.link === location.pathname )))
    }
    
  }, [dispatch,menuselect,usuarioID,menu,location.pathname ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CmtCard>
      <CmtCardHeader title={menuselect.name} />
      <CmtCardContent>
        <Box className={classes.root} mb={{ xs: 4, sm: 6 }}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} >
              <Tab className={ classes.customLabelColor}   label={`Informes (${informes.length})`} {...a11yProps(0)} />
              <Tab className={ classes.customLabelColor}   label={`Plantillas (${plantillas.length})`} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
             <Informes/>           
          </TabPanel>
          <TabPanel value={value} index={1}>
              <Plantillas/>
          </TabPanel>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
}
