import axios from '@jumbo/constants/axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {SET_SOLICITUDES,SET_NOTIFICACIONES} from '../../@jumbo/constants/ActionTypes';


export const getSolicitudes = (SolicitudesGetParam) => {
    return dispatch => {
        console.log(SolicitudesGetParam)
       dispatch(fetchStart());
       axios
         .post(`/SolicitudEjecucion/sollist`,SolicitudesGetParam)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_SOLICITUDES, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getNotificaciones = (usuarioid) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`SolicitudEjecucion/solpencnt/${usuarioid}`,)
         .then(data => {
            if (data.status === 200) {
              if(data.data.cantidad === 0){
                dispatch(fetchSuccess());
              }else{
                dispatch(fetchSuccess(data.data.mensaje));
              }
             dispatch({ type: SET_NOTIFICACIONES, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const EliminarSolicitud = (solicitudEjecucionID,usuarioid) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .post(`SolicitudEjecucion/soldel/${solicitudEjecucionID}/${usuarioid}`,)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess("Solicitud Eliminada"));
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const descargarSolicitud = (solicitudEjecucionID,usuarioid) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .post(`SolicitudEjecucion/solestchg/${solicitudEjecucionID}`,)
         .then(data => {
            if (data.status === 200) {
              console.log(data.data)
             dispatch(fetchSuccess(""));
             dispatch(getNotificaciones(usuarioid))
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };