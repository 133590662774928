import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export function NumberFormatEnteroPositivo(props) {
	const { inputRef, onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		//thousandSeparator
		isNumericString
		allowNegative ={false}	
		decimalScale="0"
		decimalSeparator={false}
	  />
	);
  }
  
  NumberFormatEnteroPositivo.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
  };