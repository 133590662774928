import React , {useEffect,useState} from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography } from '@material-ui/core';
import {makeStyles,alpha, lighten} from '@material-ui/core/styles';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getListaPaisesFestivos,getListanosPaises,getListDiasFestivosPaises,EliminarDiaFestivo,agregarDiaFestivo} from '../../redux/actions/Mantenimiento';
import GridContainer from '@jumbo/components/GridContainer';
import { Grid } from '@material-ui/core';
import Pickersinapp from 'routes/Ejecucion/utils/Pickersinapp';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Swal from 'sweetalert2';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { ValorSelect } from 'routes/Ejecucion/utils/Helper';
import Autocompetesinapp from 'routes/Ejecucion/utils/Autocompetesinapp';

const useStyles = makeStyles(theme => ({
    
    root: {
        width: '100%',
      },
      paper: {
        width: '100%',
        marginBottom: theme.spacing(4),
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
      },
      container: {
        maxHeight: 550,
        minHeight: 550
      },
      table: {
        minWidth: 200,
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      titleRoot: {
        marginBottom: 2,
        fontSize: 14,
        letterSpacing: 0.25,
        color: theme.palette.common.dark,
      },
      button: {
        textTransform: "none",
        color:theme.palette.common.black
      },
      buttonerror: {
        textTransform: "none",
        backgroundColor:theme.palette.error.main,
        color:'white'
      },
      roottoolbar: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
      },
      highlight:
        theme.palette.type === 'light'
          ? {
              color: theme.palette.secondary.main,
              backgroundColor: alpha(theme.palette.secondary.light, 0.15),
            }
          : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
            },
      title: {
        flex: '1 1 100%',
      },
      chipsRoot: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        '& > *': {
          margin: theme.spacing(0.5),
        },
      },
      dialogRoot: {
        position: 'relative',
      },
      dialogTitleRoot: {
        '& .MuiTypography-h6': {
          fontSize: 16,
          color: theme.palette.common.black,
        },
        background: theme.palette.primary.main,
      },
      textlabel: {
        '& .MuiFormLabel-root': {
          color: '#232322',
        },
       },
       divider: {
        background: theme.palette.primary.main,
    },
    input:{
      '& .MuiTextField-root': {
        width: '50%',
      }
    },
    buscar:{
      '& .CmtSearch-root': {
        justifyContent:'flex-end',
        paddingRight: theme.spacing(2),
      }
    },
    CmtCard:{
      marginBottom:10
    }
  }));


const headCellsPaies = [
    {
      id: 'countryName',
      numeric: false,
      disablePadding: false,
      label: 'Paises',
    }
  ];

  const headCellsanos = [
    {
      id: 'fechaAno',
      numeric: false,
      disablePadding: false,
      label: 'Años',
    }
  ];

  const headCellsDiasFestivos = [
    {
      id: 'countryName',
      numeric: false,
      disablePadding: false,
      label: 'Pais',
    },
    {
        id: 'fecha',
        numeric: false,
        disablePadding: false,
        label: 'Fecha',
      },
      {
        id: 'recurrente',
        numeric: false,
        disablePadding: false,
        label: 'Recurrente',
      }
  ];

function TableHeadpaises({ classes, order, orderBy, onRequestSort }) {
    const onSortOrderChange = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCellsPaies.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={onSortOrderChange(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }


function TableHeadanos({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCellsanos.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TableHeadDiasFestivos({ classes, order, orderBy, onRequestSort }) {
    const onSortOrderChange = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCellsDiasFestivos.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={onSortOrderChange(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell align="right">Acción</TableCell>
        </TableRow>
      </TableHead>
    );
  }

export default function InformesForm() {
   const dispatch = useDispatch();
   const classes = useStyles();
   const {paises} =  useSelector(({ mantenimiento }) => mantenimiento);
   const {Listadoanospais,paisfestivoselect} =  useSelector(({ mantenimiento }) => mantenimiento);
   const [pais,setpais] = useState('0');
   const [paisano,setpaisano] = useState("0");

  useEffect(() => {
      dispatch(getListaPaisesFestivos());
  }, [dispatch ]);

  useEffect(() => {
    if(paisfestivoselect.value !== null){
      dispatch(getListDiasFestivosPaises('-10000',paisfestivoselect.value, paisano))
    }
}, [Listadoanospais]);

  return (
    <PageContainer heading="Días Festivos por País">
       <CmtCard className={classes.CmtCard}>
        <CmtCardContent>
        <Grid container spacing={2} >
            <Grid item xs={6}>
                <Autocompetesinapp 
                      options={paises} 
                      value={paises.find(x => x.value === pais.toString())}
                      name="Pais "
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        if(newValue){
                          setpaisano("0")
                          dispatch(getListanosPaises(newValue))
                        //  setpais(ValorSelect(newValue))
                        }else{
                          setpaisano("0")
                         // setpais(ValorSelect('0'))
                        }
                      }}
                  />
                  </Grid>
                  
                  <Grid item xs={6}>
                  {Listadoanospais.length > 0 &&
                    <Autocompetesinapp 
                        options={Listadoanospais} 
                        value={Listadoanospais.find(x => x.value === paisano)}
                        name="Año "
                        onChange={(event, newValue) => {
                          console.log(newValue)
                          if(newValue){
                            dispatch(getListDiasFestivosPaises('-10000',paisfestivoselect.value, newValue.value))
                            setpaisano(newValue.value)
                          }else{
                            dispatch(getListDiasFestivosPaises('-10000',paisfestivoselect.value, 0))
                            setpaisano(0)
                          }
                        }}
                    />
                    }
                </Grid>
                </Grid>
           </CmtCardContent>
        </CmtCard>
       <ListaDiasFestivos />
    </PageContainer>
  );
}

function ListaPaises() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {paises} =  useSelector(({ mantenimiento }) => mantenimiento);
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = React.useState('descripcion');
    const [order, setOrder] = React.useState('asc');
    const page = 0;
    const rowsPerPage= 300;
    const [searchTerm, setSearchTerm] = useState('');
    
  
    useEffect(() => {
      function getFilteredArray(entities, _searchTerm) {
        if (_searchTerm.length === 0) {
          return paises;
        }
        return TableUtils.filterArrayByString(paises, _searchTerm);
      }
      if (paises) {
        setData(getFilteredArray(paises, searchTerm));
      }
    }, [paises, searchTerm]);
  
   const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
    };
     
    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.roottoolbar}>
              <React.Fragment>
                <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon={false}  />
              </React.Fragment>
            </Toolbar>
            
            <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                  <TableHeadpaises
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                
                  <TableBody>
                     {!!data.length ? (
                          stableSort(data, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                             <TableRow
                                   hover
                                   tabIndex={-1}
                                   key={row.countryID}
                                   onClick={() => dispatch(getListanosPaises(row))}
                                 >
                                   <TableCell>{row.countryName}</TableCell>
                              </TableRow>
                          ))
                      ) : (
                          <TableRow style={{ height: 53 * 6 }}>
                            <TableCell colSpan={7} rowSpan={10}>
                               <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                            </TableCell>
                          </TableRow>
                      )}
                  </TableBody>
                 </Table>
              </TableContainer>    
           </Paper>
      </div>
    );
  }


  
function Listaanospaises() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {Listadoanospais,paisfestivoselect} =  useSelector(({ mantenimiento }) => mantenimiento);
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = React.useState('descripcion');
    const [order, setOrder] = React.useState('asc');
    const page = 0;
    const rowsPerPage= 300;
    const [searchTerm, setSearchTerm] = useState('');
  
    useEffect(() => {
      function getFilteredArray(entities, _searchTerm) {
        if (_searchTerm.length === 0) {
          return Listadoanospais;
        }
        return TableUtils.filterArrayByString(Listadoanospais, _searchTerm);
      }
      if (Listadoanospais) {
        setData(getFilteredArray(Listadoanospais, searchTerm));
      }
    }, [Listadoanospais, searchTerm]);
  
   const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
    };
     
    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.roottoolbar}>
                <Typography>
                    {paisfestivoselect.countryName}
                </Typography>
              <React.Fragment>
                <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon={false}  />
              </React.Fragment>
            </Toolbar>
            
            <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                  <TableHeadanos
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                
                  <TableBody>
                     {!!data.length ? (
                          stableSort(data, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                             <TableRow
                                   hover
                                   tabIndex={-1}
                                   key={row.countryID}
                                   onClick={() => dispatch(getListDiasFestivosPaises('-10000',paisfestivoselect.countryID,row.fechaAno))}
                                 >
                                   <TableCell>{row.fechaAno}</TableCell>
                              </TableRow>
                          ))
                      ) : (
                          <TableRow style={{ height: 53 * 6 }}>
                            <TableCell colSpan={7} rowSpan={10}>
                               <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                            </TableCell>
                          </TableRow>
                      )}
                  </TableBody>
                 </Table>
              </TableContainer>    
           </Paper>
      </div>
    );
  }

  function ListaDiasFestivos() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {Listadodiasfestivos,paisfestivoselect} =  useSelector(({ mantenimiento }) => mantenimiento);
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = React.useState('descripcion');
    const [order, setOrder] = React.useState('asc');
    const page = 0;
    const rowsPerPage= 300;
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [Fecha, setFecha] = useState(null);
    const [Recurrente, setRecurrente] = useState(false);
  
    useEffect(() => {
      function getFilteredArray(entities, _searchTerm) {
        if (_searchTerm.length === 0) {
          return Listadodiasfestivos;
        }
        return TableUtils.filterArrayByString(Listadodiasfestivos, _searchTerm);
      }
      if (Listadodiasfestivos) {
        setData(getFilteredArray(Listadodiasfestivos, searchTerm));
      }
    }, [Listadodiasfestivos, searchTerm]);
  
   const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrderBy(property);
      setOrder(isAsc ? 'desc' : 'asc');
    };
     
 
    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.roottoolbar}>
              <Button  variant="contained" color='secondary' onClick={() => setOpenDialog(true)}>
                Agregar 
              </Button>
              <React.Fragment>
                <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon={false} />
              </React.Fragment>
            </Toolbar>
            
            <TableContainer className={classes.container}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                  <TableHeadDiasFestivos
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                
                  <TableBody>
                     {!!data.length ? (
                          stableSort(data, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                             <TableRow
                                   hover
                                   tabIndex={-1}
                                   key={row.countryHoliDayID}
                                 >
                                    <TableCell>{row.countryName}</TableCell>
                                   <TableCell>{row.fecha}</TableCell>
                                   <TableCell>{row.recurrente === true ? 'SI' : 'NO'}</TableCell>
                                   <TableCell  align="right">  
                                    <Button onClick={() =>{
                                        Swal.fire({
                                          title: '¿Estás seguro(a) de Eliminar?',
                                          text: '¡No podrás recuperar este cambio!',
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonColor: "red",
                                          confirmButtonText: 'Si, Eliminar',
                                          cancelButtonText: 'No, Mantener',
                                          }).then((result) => {
                                          if (result.value) {
                                            dispatch(EliminarDiaFestivo(row.countryHoliDayID,paisfestivoselect))
                                          } else if (result.dismiss === Swal.DismissReason.cancel) {
                                            Swal.fire(
                                            'Cancelado',
                                            'No se realizó este cambio :)',
                                            'error'
                                            )
                                          }
                                          })
                                      }} size='small' className={classes.buttonerror} variant="contained" >Eliminar</Button>
                                 </TableCell>
                              </TableRow>
                          ))
                      ) : (
                          <TableRow style={{ height: 53 * 6 }}>
                            <TableCell colSpan={7} rowSpan={10}>
                               <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                            </TableCell>
                          </TableRow>
                      )}
                  </TableBody>
                 </Table>
              </TableContainer>    
           </Paper>

           <>
        <Dialog open={openDialog}  className={classes.dialogRoot} fullWidth maxWidth="sm" >
            <DialogTitle className={classes.dialogTitleRoot}> {paisfestivoselect.label} </DialogTitle>
            <DialogContent dividers>
            <div className={classes.root}>  
            <Pickersinapp
                label="Fecha"
                value={Fecha}
                onChange={(date) =>{date !== null ? setFecha(date.format('YYYYMMDD')) : setFecha(null) }}
            />
             <FormControlLabel
                    control={ <Checkbox
                      checked={Recurrente}
                      onChange={x => { setRecurrente(x.target.checked)}}
                      color="primary"
                    />}
                    label="Recurrente"
                  />
            </div>
                <Box display="flex" justifyContent="flex-end" my={4}>
                    <Button variant="contained" onClick={() => setOpenDialog(false) }>Cancelar</Button>
                    <Box ml={2}>
                    <Button variant="contained" color="secondary" onClick={() => {
                       dispatch(agregarDiaFestivo(paisfestivoselect,Fecha,Recurrente))
                       setFecha(null);
                       setRecurrente(false)
                       setOpenDialog(false)
                    }}>
                        Guardar
                    </Button>
                    </Box>
                </Box>
            </DialogContent>  
        </Dialog>
      </>
      </div>
    );
  }