import React , {useEffect,useState} from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button } from '@material-ui/core';
import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getTourOperator,getPresupuestoCobrosExcluirTTOO,agregarPresupuestoCobrosExcluirTTOO,eliminarPresupuestoCobrosExcluirTTOO} from '../../redux/actions/Mantenimiento';
import Autocompetesinapp from '../Ejecucion/utils/Autocompetesinapp';
import Grid from '@material-ui/core/Grid';
import Swal from 'sweetalert2';

const headCells = [
  {
    id: 'descripcion',
    numeric: false,
    disablePadding: false,
    label: 'Tour Operator',
  }
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function InformesForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {presupuestocobroexcluir,touroperator} =  useSelector(({ mantenimiento }) => mantenimiento);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [TourOperator,setTourOperator] = useState('-10000')
 
  useEffect(() => {
      dispatch(getPresupuestoCobrosExcluirTTOO());
      dispatch(getTourOperator('na'));
  }, [dispatch ]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return presupuestocobroexcluir;
      }
      return TableUtils.filterArrayByString(presupuestocobroexcluir, _searchTerm);
    }
    if (presupuestocobroexcluir) {
      setData(getFilteredArray(presupuestocobroexcluir, searchTerm));
    }
  }, [presupuestocobroexcluir, searchTerm]);


 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');
};

const handlePageChange = (event, newPage) => {
  setPage(newPage);
};

const handleRowsPerPageChange = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  return (
    <PageContainer heading="Presupuesto de Cobros Excluir TTOO">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
           <Grid container spacing={1} alignItems="center">
           <Grid item xs={6}>
            {touroperator.length > 0 &&
              <Autocompetesinapp 
                      options={touroperator} 
                      name="Tour Operator"
                      value={touroperator.find(x => x.value === TourOperator.toString())}
                      onChange={(event, newValue) => {
                        setTourOperator(newValue.value)
                      }}
                  />
                }
              </Grid> 
              <Grid item xs={3} style={{}}>
                <Button 
                    size='small' 
                    className={classes.button} 
                    variant="contained" 
                    color="secondary" 
                    onClick={x =>{
                      if(TourOperator){
                        dispatch(agregarPresupuestoCobrosExcluirTTOO(TourOperator)); 
                        setTourOperator('-10000')}
                      }
                      }
                        >
                          Agregar
                </Button>
              </Grid>
              <Grid  item xs={3}>
                  <div className={classes.buscar}>
                  <React.Fragment  >
                    <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
                  </React.Fragment>
                  </div>
              </Grid>
            </Grid>
          </Toolbar>
          
          <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.tourOp}</TableCell>
                                 <TableCell  align="right">  
                                    <Button  
                                      size='small' 
                                      className={classes.buttonerror} 
                                      variant="contained"  
                                      onClick={() =>
                                        {
                                          Swal.fire({
                                            title: '¿Estás seguro(a) de Eliminar?',
                                            text: '¡No podrás recuperar este cambio!',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: "red",
                                            confirmButtonText: 'Si, Eliminar',
                                            cancelButtonText: 'No, Mantener',
                                            }).then((result) => {
                                            if (result.value) {
                                               dispatch(eliminarPresupuestoCobrosExcluirTTOO(row.tourOpID))
                                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                                              Swal.fire(
                                              'Cancelado',
                                              'No se realizó este cambio :)',
                                              'error'
                                              )
                                            }
                                            })
                                          }
                                        } 
                                      >
                                        Eliminar
                                    </Button>
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
         </Paper>
    </div>
    </PageContainer>
  );
}