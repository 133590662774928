import axios from '@jumbo/constants/axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {  
  GET_INFORMES, 
  GET_PLANTILLAS,
  SET_HOTEL,
  SET_HOTELGROUP,
  SET_TOUROPERATOR,
  SET_TOUROPERATORGROUP,
  SET_MARKET,
  SET_PAYMENTCONDITION,
  SET_ORDENARPOR,
  SET_VERSIONCCL,
  SET_ROOMTYPE,
  SET_INVTYPE,
  SET_ESTATUSFACTURAS,
  SET_INCLUIRFACTURAS,
  SET_TIPODOCUMENTO,
  SET_TIPODOCUMENTOFACTURA,
  SET_ACCOUNTTYPE,
  SET_BALANCE,
  SET_CARTERA,
  SET_FRECUENCY,
  SET_INVOICECONDITION,
  SET_TIPOREPORTE,
  SET_DOCCLASS,
  SET_TRANSACTIONCODE,
  SET_DOCUMENTSING,
  SET_DEPARTAMENT,
  SET_RESPONSIBLE,
  SET_MESES,
  SET_CLASS,
  SET_OPENDIALOG,
  SET_PAYMODE,
  SET_CREDITDAYS,
  SET_CONTACTTYPE,
  SET_STATE,
  SET_RATETYPE,
  SET_RATERECEIVED,
  SET_AGRUPACIONCARTERA
} from '../../@jumbo/constants/ActionTypes';
import Swal from 'sweetalert2';


export const getInformes = (menuid,usuarioID, callbackFun) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`/Usuario/infplanmenusrget/${menuid}/INFORME/${usuarioID}`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: GET_INFORMES, payload: data.data });
             if (callbackFun) callbackFun(data.data);
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getPlantillas = (menuid,usuarioID, callbackFun) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`/Usuario/infplanmenusrget/${menuid}/plantilla/${usuarioID}`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: GET_PLANTILLAS, payload: data.data });
             if (callbackFun) callbackFun(data.data);
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const SolicitarEjecucion = (Solicitar) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .post(`SolicitudEjecucion/solins`,Solicitar)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess("Solicitud Enviada"));
             dispatch({ type: SET_OPENDIALOG, payload: false });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
          Swal.fire({
            icon: 'error',
            title:   `${error.response.data}`,
            confirmButtonColor: "red",
          })  
           dispatch(fetchError());
         });
    };
  };

  export const getHotel = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filhotel`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_HOTEL, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getHotelPresupuestoCobro = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filhotelprecobro`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_HOTEL, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };


  export const getHotelGroup = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filhotelgrp`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_HOTELGROUP, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getTourOperator = (codigoBusqueda) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filttoo/${codigoBusqueda}`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_TOUROPERATOR, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getTourOperatorgroup = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filttoogrp`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_TOUROPERATORGROUP, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getMarket= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtmark`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_MARKET, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getPaymentCondition= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtpaycon`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_PAYMENTCONDITION, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getOrdenarpor = (formAppID) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filorderby/${formAppID}`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_ORDENARPOR, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getVersion = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtvers`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_VERSIONCCL, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getRoomType = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtroomtyp`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_ROOMTYPE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getInvType = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtinvtyp`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_INVTYPE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getEstatusFactura= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtinvsta`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_ESTATUSFACTURAS, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getIncluirFacturas = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`Generica/genselect/PREGUNTA_SI_NO/Indique si incluye facturas canceladas`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_INCLUIRFACTURAS, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getTipoDocumentoFactura = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
       .get(`Generica/genselect/TIPO_DOCTO_FACTURA/Tipo de Factura Invoice o Proforma`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_TIPODOCUMENTOFACTURA, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getTipoDocumento = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtdoctype`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_TIPODOCUMENTO, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getAccountType = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtacctyp`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_ACCOUNTTYPE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getBalance = (formAppID) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filorderby/${formAppID}`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_BALANCE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  }

  export const getCartera = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtcartera`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_CARTERA, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  }

  export const getFrecuencia = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtfrprecob`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_FRECUENCY, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  }

  export const getInvoiceCondition= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtinvconprecob`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_INVOICECONDITION, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  }

  export const getTipoReporte= (formAppID) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filorderby/${formAppID}`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_TIPOREPORTE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  }

  export const getDocClass = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtdocclass`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_DOCCLASS, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getTransactioncode = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filttranscode`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_TRANSACTIONCODE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getDocumentSing = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtdocsign`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_DOCUMENTSING, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getDepartment = () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtdepto`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_DEPARTAMENT, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getResponsible = (depID) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtemp/${depID}/true`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_RESPONSIBLE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getclass = (trDocClass) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtclass/${trDocClass}`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_CLASS, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getMeses = (depID) => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filmeses/ESPANOL/false`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_MESES, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };


  export const getPaymode= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filpaymode`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_PAYMODE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getCreditday= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtouropcreday`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_CREDITDAYS, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getContactType= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filcontype`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_CONTACTTYPE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getStateFrm= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filtouropstate`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_STATE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getRateType= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filratetypecont`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_RATETYPE, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getRateReceived= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`filtro/filratereceivedcon`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_RATERECEIVED, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };

  export const getAgrupacionCartera= () => {
    return dispatch => {
       dispatch(fetchStart());
       axios
         .get(`Mantenimiento/grpcarterasel`)
         .then(data => {
            if (data.status === 200) {
             dispatch(fetchSuccess());
             dispatch({ type: SET_AGRUPACIONCARTERA, payload: data.data });
           } else {
             dispatch(fetchError('Esta acción no pudo realizarse.'));
           }
         })
         .catch(error => {
            dispatch(fetchError(error.response.data));
         });
    };
  };


  // Iniciar formularios
  export const IniciarReviewdInvoice = (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'));
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
       dispatch(getOrdenarpor(formAppID));
       dispatch(getVersion());
    };
  };

  export const IniciarReservations = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'));
       dispatch(getTourOperatorgroup());
       dispatch(getRoomType());
       
    };
  };

  export const IniciarFacturasAmericaAirline = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getPaymentCondition());
       dispatch(getMarket());
    };
  };

  export const IniciarFacturasExpedida= () => {
    return dispatch => {
       dispatch(getHotel());
    };
  };

  export const IniciarInvoiceJournal= (formAppID) => {
    return dispatch => {
      dispatch(getHotel());
      dispatch(getHotelGroup());
      dispatch(getTourOperator('na'));
      dispatch(getTourOperatorgroup());
      dispatch(getPaymentCondition());
      dispatch(getMarket());
      dispatch(getInvType());
      dispatch(getRoomType());
      dispatch(getEstatusFactura());
      dispatch(getIncluirFacturas());
      dispatch(getTipoDocumentoFactura());
      dispatch(getOrdenarpor(formAppID));
    };
  };

  export const IniciarComparativofacturacclvshotel= () => {
    return dispatch => {
      dispatch(getHotel());
      dispatch(getHotelGroup());
      dispatch(getTourOperator('na'));
      dispatch(getTourOperatorgroup());
      dispatch(getPaymentCondition());
      dispatch(getMarket());
      dispatch(getInvType());
      dispatch(getRoomType());
      dispatch(getEstatusFactura());
      dispatch(getIncluirFacturas());
    };
  };

  export const IniciarAccountStatement= (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'));
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
       dispatch(getInvType());
       dispatch(getAccountType());
       dispatch(getBalance(formAppID));
    };
  };

  export const IniciarPresupuestoCobrosNeto = (formAppID) => {
    return dispatch => {
       dispatch(getHotelPresupuestoCobro());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'));
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
       dispatch(getCartera());
       dispatch(getFrecuencia());
       dispatch(getInvoiceCondition());
       dispatch(getTipoReporte(formAppID));
    };
  };

  export const IniciarPresupuestoCobrosBruto = () => {
    return dispatch => {
       dispatch(getHotelPresupuestoCobro());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'));
       dispatch(getTourOperatorgroup());
       dispatch(getPaymentCondition());
       dispatch(getMarket());
       dispatch(getCartera());
       dispatch(getFrecuencia());
       dispatch(getAgrupacionCartera());
    };
  };

  export const IniciarPlantillaEstadodeCuentaCostco = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('COSTCO'));
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
    };
  };

  export const IniciarPlantillaestadodeCuentaGOGO = (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getPaymentCondition());
       dispatch(getMarket());
       dispatch(getOrdenarpor(formAppID))

    };
  };
  
  export const IniciarEstadoCuentaPGS = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getPaymentCondition());
       dispatch(getMarket());
    };
  };
  
  export const IniciarEstadoCuentaKHR = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getPaymentCondition());
       dispatch(getMarket());
    };
  };
  
  export const IniciarDestiworld = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getPaymentCondition());
       dispatch(getMarket());
    };
  };

  export const IniciarReconciliacion = (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getTipoReporte(formAppID));
    };
  };

  export const IniciarConfirmacionSaldo = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getPaymentCondition());
       dispatch(getMarket());
    };
  };

  export const IniciarAgedBalance = (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getPaymentCondition());
       dispatch(getMarket());
       dispatch(getAccountType())
       dispatch(getInvType())
       dispatch(getOrdenarpor(formAppID))
       dispatch(getFrecuencia())
    };
  };

  export const IniciarDailyJournal = (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
       dispatch(getDocClass());
       dispatch(getTransactioncode());
       dispatch(getAccountType())
       dispatch(getTipoDocumento());
       dispatch(getDocumentSing());
       dispatch(getInvType());
       dispatch(getDepartment());
       dispatch(getOrdenarpor(formAppID))
       dispatch(getVersion());
       dispatch(getIncluirFacturas())
       
    };
  };

  export const IniciarIncomeReconciliation = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getPaymentCondition());
       dispatch(getMarket());
    };
  };

  export const IniciarCountingReport = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getTipoDocumento());
       dispatch(getMeses())
    };
  };

  export const   IniciarCountingReportsDocuments = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getTipoDocumento());
       dispatch(getMeses())
    };
  };

  export const   IniciarFacturasFechaAplicacion = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
     };
  };

  export const IniciarDocumentBalance = (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
       dispatch(getDocClass());
       dispatch(getTransactioncode());
       dispatch(getAccountType())
       dispatch(getDocumentSing());
       dispatch(getDepartment());
       dispatch(getOrdenarpor(formAppID))
       dispatch(getVersion());
    };
  };
  
  export const IniciarMonthlyTransactions = (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
       dispatch(getTipoDocumento());
       dispatch(getTransactioncode());
       dispatch(getOrdenarpor(formAppID))
       dispatch(getVersion());
    };
  };

  export const IniciarAdjusmentReport = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
    };
  };

  export const IniciarCollectionsbyHotelbyMonth = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
    };
  };
  

  export const IniciarGrossBilling= () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
    };
  };
  
  export const IniciarNetBilling= () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
    };
  };
  
  export const IniciarAdjustments= () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
    };
  };
  
  

  export const IniciarBalanceVencido= (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getPaymentCondition());
       dispatch(getAccountType())
       dispatch(getInvType())
       dispatch(getOrdenarpor(formAppID))
    };
  };


  export const IniciarAccountReceivableBreakdown= (formAppID) => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getPaymentCondition());
       dispatch(getVersion())
       dispatch(getOrdenarpor(formAppID))
    };
  };
  
  
  export const IniciarRotacionCartera= () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket())
       dispatch(getPaymentCondition());
             
    };
  };
  
  export const IniciarStatusFacturacion= () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
    };
  };
  
  export const IniciarGarantias= () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getTourOperatorgroup());
       dispatch(getPaymentCondition());
       dispatch(getMarket());
       dispatch(getInvType());
       dispatch(getEstatusFactura());
       dispatch(getIncluirFacturas());
       
    };
  };


  export const IniciarTourOperator= (formAppID) => {
    return dispatch => {
      dispatch(getTourOperatorgroup());
      dispatch(getMarket());
      dispatch(getPaymentCondition());
      dispatch(getPaymode());
      dispatch(getCreditday());
      dispatch(getContactType());
      dispatch(getStateFrm());
       
    };
  };


  export const IniciarInventarioTarifas = () => {
    return dispatch => {
       dispatch(getHotel());
       dispatch(getHotelGroup());
       dispatch(getTourOperator('na'))
       dispatch(getTourOperatorgroup());
       dispatch(getMarket());
       dispatch(getRateType());
       dispatch(getRateReceived());
    };
  };