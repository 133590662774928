 import _ from 'lodash';
import React,{useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
 import TextField from '@material-ui/core/TextField';
 import useForm from '@jumbo/utils/useForm';
 import { IniciarInventarioTarifas,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
 import {ValorSelect} from './utils/Helper';
 import Autocompetesinapp from './utils/Autocompetesinapp';
 import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import {NumberFormatEnteroPositivo} from './utils/NumberFormatCustom';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const InventarioTarifas = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
    const { hotel,hotelgroup,touroperator,touroperatorgroup,market,ratetype,ratereceived} = useSelector(({ ejecucion }) => ejecucion);
    const { form, setForm } = useForm({
      "hotel": null,
      "hotelgroup": null,
      "touroperator": null,
      "touroperatorgroup": null,
      "title": null,
      "market": null,
      "ratetype": null,
      "ratecode": null,
      "ratedatedesde": null,
      "ratedatehasta": null,
      "bookingdatedesde": null,
      "bookingdatehasta": null,
      "ratereceived":'',
      "includecomments": null,
      "includeinactivecontacts": null,
    });

     useEffect(() => {
       dispatch(IniciarInventarioTarifas());
     }, [dispatch ]);



     const ejecucion = () => {
         let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
        }else{variables += "null"}

        if(form.hotelgroup){variables += `|${form.hotelgroup}`
        }else{variables += "|null"}

        if(form.touroperator){variables += `|${form.touroperator}`
        }else{variables += "|null"}

        if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
        }else{variables += "|null"}

        if(form.title){variables += `|${form.title}`
        }else{variables += "|null"}

        if(form.market){variables += `|${form.market}`
        }else{variables += "|null"}

        if(form.ratetype){variables += `|${form.ratetype}`
        }else{variables += "|null"}

        if(form.ratecode){variables += `|${form.ratecode}`
        }else{variables += "|null"}

        if(form.ratedatedesde){variables += `|${form.ratedatedesde},`
        }else{variables += "|null,"}

        if(form.ratedatehasta){variables += `${form.ratedatehasta}`
        }else{variables += "null"}

        if(form.bookingdatedesde){variables += `|${form.bookingdatedesde},`
        }else{variables += "|null,"}

        if(form.bookingdatehasta){variables += `${form.bookingdatehasta}`
        }else{variables += "null"}
        
        if(form.ratereceived){variables += `|${form.ratereceived}`
        }else{variables += "|null"}

        if(form.includecomments){variables += `|${form.includecomments}`
        }else{variables += "|null"}

        if(form.includeinactivecontacts){variables += `|${form.includeinactivecontacts}`
        }else{variables += "|null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":form.ordenarpor,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":form.version, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }
       
        dispatch(SolicitarEjecucion(Solicitar))
       };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography color='inherit'>Criterios de busqueda</Typography>
              <Divider classes={{root: classes.divider}}/>

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                        setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                  options={hotelgroup} 
                  name="Hotel Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

               <Autocompetesinapp 
                  options={touroperatorgroup} 
                  name="Tour Operator Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                  }}
                />

                <TextField 
                  className={classes.textlabel}
                  size='small'
                  label="Title" 
                  variant="outlined"
                  value={form.title}
                  onChange={(value) => setForm(_.set({ ...form }, 'title', value.target.value)) } />

                <Autocompetesinapp 
                  multiple 
                  disableCloseOnSelect 
                  options={market} 
                  name="Market"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                  }}
                />

                <Autocompetesinapp 
                  multiple 
                  disableCloseOnSelect 
                  options={ratetype} 
                  name="Rate Type"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'ratetype', ValorSelect(newValue))) 
                   }}
                />
 
                <TextField 
                    className={classes.textlabel}
                     size='small'
                     label="Rate Code" 
                     variant="outlined"
                     value={form.ratecode}
                     onChange={(value) => setForm(_.set({ ...form }, 'ratecode', value.target.value)) } 
                     style={{paddingRight:10}}
                />
     
                    <Box>
                        <Pickersinapp
                            label="Start"
                            value={form.ratedatedesde}
                            onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'ratedatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'ratedatedesde', null))}}
                            style={{paddingRight: 10}}
                        />
                        
                        <Pickersinapp
                            label="End"
                            value={form.ratedatehasta}
                            onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'ratedatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'ratedatehasta', null))}}
                            style={{paddingRight: 10}}
                        />
                    </Box>

                    <Box >
                        <Pickersinapp
                          label="Booking Window Start"
                          value={form.bookingdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'bookingdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'bookingdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                            
                       <Pickersinapp
                          label="Booking Window Finish"
                          value={form.bookingdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'bookingdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'bookingdatehasta', null))}}
                          style={{paddingRight: 10}}
                        />
                   </Box>

                   <Autocompetesinapp 
                      multiple 
                      disableCloseOnSelect 
                      options={ratereceived} 
                      name="Rate Received"
                      onChange={(event, newValue) => {
                        setForm(_.set({ ...form }, 'ratereceived', ValorSelect(newValue))) 
                      }}
                    />

                  
                <FormControlLabel
                    control={ <Checkbox
                      checked={form.includecomments}
                      onChange={x => {setForm(_.set({ ...form }, 'includecomments', x.target.checked))}}
                      color="primary"
                    />}
                    label="Include Comments"
                  />

                <FormControlLabel
                    control={ <Checkbox
                      checked={form.includeinactivecontacts}
                      onChange={x => {setForm(_.set({ ...form }, 'includeinactivecontacts', x.target.checked))}}
                      color="primary"
                    />}
                    label="Include Inactive Contacts"
                  />

        
              </div>
        
              <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()} >
                       Ejecutar
                    </Button>
                </Box>
             </Box>
             </DialogContent>  
        </Dialog>
    )
};

export default InventarioTarifas;

InventarioTarifas.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
