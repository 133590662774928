import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS,GET_MENU,SET_MENUSELECT } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  initialURL: '/',
  menuselect:{"name":"","menuid":""},
  error: '',
  message: '',
  loading: false,
  menu:[{"name":"Menu","type":"section","children":[{"name":"Grupos","link":"/apps/forms/grupos"}]}]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', loading: false, message: action.payload };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, message: '', error: action.payload };
    }
    case GET_MENU: {
      return { ...state, menu: action.payload };
    }
    case SET_MENUSELECT: {
      return { ...state, menuselect: action.payload };
    }
    default:
      return state;
  }
};
