//import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
//import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// import TextField from '@material-ui/core/TextField';
// import useForm from '@jumbo/utils/useForm';
// import { IniciarReviewdInvoice,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
// import {ValorSelect} from './utils/Helper';
// import Autocompetesinapp from './utils/Autocompetesinapp';
// import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';


const ContratoVentasGrupos = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
  //  const dispatch = useDispatch();
  //  const { usuarioID } = useSelector(({ auth }) => auth.authUser);
    // const { hotel,hotelgroup,touroperator,touroperatorgroup,market,paymentcondition,ordenarpor,versionccl} = useSelector(({ ejecucion }) => ejecucion);
    // const { form, setForm } = useForm({
    //   "hotel": null,
    //   "hotelgroup": null,
    //   "touroperator": null,
    //   "touroperatorgroup": null,
    //   "market": null,
    //   "bookingdatedesde": null,
    //   "bookingdatehasta": null,
    //   "invoicedatedesde": null,
    //   "invoicedatehasta": null,
    //   "reviewdatedesde": null,
    //   "reviewdatehasta": null,
    //   "bookingnumber":'',
    //   "paymentcondition": null,
    //   "ordenarpor": null,
    //   "version": null,
    // });

    // useEffect(() => {
    //   dispatch(IniciarReviewdInvoice(formApp.formAppID));
    // }, [dispatch,formApp ]);



    // const ejecucion = () => {
    //     let variables = "";
        
    //     if(form.hotel){variables += `${form.hotel}`
    //     }else{variables += "null"}

    //     if(form.hotelgroup){variables += `|${form.hotelgroup}`
    //     }else{variables += "|null"}

    //     if(form.touroperator){variables += `|${form.touroperator}`
    //     }else{variables += "|null"}

    //     if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
    //     }else{variables += "|null"}

    //     if(form.market){variables += `|${form.market}`
    //     }else{variables += "|null"}

    //     if(form.bookingdatedesde){variables += `|${form.bookingdatedesde},`
    //     }else{variables += "|null,"}

    //     if(form.bookingdatehasta){variables += `${form.bookingdatehasta}`
    //     }else{variables += "null"}

    //     if(form.invoicedatedesde){variables += `|${form.invoicedatedesde},`
    //     }else{variables += "|null,"}

    //     if(form.invoicedatehasta){variables += `${form.invoicedatehasta}`
    //     }else{variables += "null"}
        
    //     if(form.reviewdatedesde){variables += `|${form.reviewdatedesde},`
    //     }else{variables += "|null,"}

    //     if(form.reviewdatehasta){variables += `${form.reviewdatehasta}`
    //     }else{variables += "null"}

    //     if(form.bookingnumber){variables += `|${form.bookingnumber}`
    //     }else{variables += "|null"}

    //     if(form.paymentcondition){variables += `|${form.paymentcondition}`
    //     }else{variables += "|null"}

    //     let Solicitar = {
    //       "FormAppID":formApp.formAppID,
    //       "UsuarioID":usuarioID,
    //       "OrdenarPorID":form.ordenarpor,
    //       "Computador":"192.163", 
    //       "UserNameComputador":"192.163", 
    //       "ValorVersion":form.version, 
    //       "CriteriosEjecucion":variables, 
    //       "CriteriosEjecucionExclusion":"" 
    //     }

    //     dispatch(SolicitarEjecucion(Solicitar))
    //     onCloseDialog()
    //   };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography color='inherit'>Criterios de busqueda</Typography>
              <Divider classes={{root: classes.divider}}/>

                {/* <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                      console.log(ValorSelect(newValue))
                      //  setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                  options={hotelgroup} 
                  name="Hotel Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

               <Autocompetesinapp 
                  options={touroperatorgroup} 
                  name="Tour Operator Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={market} 
                    name="Tour Market"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                    }}
                />
 
              <Autocompetesinapp 
                  options={paymentcondition} 
                  name="Payment Condition"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'paymentcondition', ValorSelect(newValue))) 
                  }}
                />



              </div>
                 </Box>
                    <Box my={-5}>
                        <Pickersinapp
                          label="Booking Date Desde"
                          value={form.bookingdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'bookingdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'bookingdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                            
                       <Pickersinapp
                          label="Booking Date Hasta"
                          value={form.bookingdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'bookingdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'bookingdatehasta', null))}}
                          style={{paddingRight: 10}}
                        />
                   </Box>

                   <Box my={-5}>
                        <Pickersinapp
                            label="Invoice Date Desde"
                            value={form.invoicedatedesde}
                            onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invoicedatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invoicedatedesde', null))}}
                            style={{paddingRight: 10}}
                        />
                        
                        <Pickersinapp
                            label="Invoice Date Hasta"
                            value={form.invoicedatehasta}
                            onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invoicedatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invoicedatehasta', null))}}
                            style={{paddingRight: 10}}
                        />
                    </Box>

                    <Box my={-2}>
                        <Pickersinapp
                                label="Review Date Desde"
                                value={form.reviewdatedesde}
                                onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'reviewdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'reviewdatedesde', null))}}
                                style={{paddingRight: 10}}
                            />
                            
                            <Pickersinapp
                                label="Review Date Hasta"
                                value={form.reviewdatehasta}
                                onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'reviewdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'reviewdatehasta', null))}}
                            />
                        </Box>
               
                        <TextField 
                         className={classes.textlabel}
                          size='small'
                          label="Booking Number" 
                          variant="outlined"
                          value={form.bookingnumber}
                          onChange={(value) => setForm(_.set({ ...form }, 'bookingnumber', value.target.value)) } />
 

                <Autocompetesinapp 
                  options={ordenarpor} 
                  name="Ordenar Por"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'ordenarpor', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                  options={versionccl} 
                  name="Versión"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'version', ValorSelect(newValue))) 
                  }}
                /> */}
              </div>
        
              <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" >
                       Ejecutar
                    </Button>
                </Box>
             </Box>
             </DialogContent>  
        </Dialog>
    )
};

export default ContratoVentasGrupos;

ContratoVentasGrupos.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
