import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useForm from '@jumbo/utils/useForm';
import { IniciarCollectionsbyHotelbyMonth,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';


const CollectionsbyHotelbyMonth = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
    const { hotel, hotelgroup } = useSelector(({ ejecucion }) => ejecucion);
    
  const { form, setForm } = useForm({
      "hotel": null,
      "hotelgroup": null,
      "datedesde":null,
      "datehasta": null,
    });

     useEffect(() => {
       dispatch(IniciarCollectionsbyHotelbyMonth());
     }, [dispatch ]);


     const ejecucion = () => {
        let variables = "";
        
         if(form.hotel){variables += `${form.hotel}`
         }else{variables += "null"}

         if(form.hotelgroup){variables += `|${form.hotelgroup}`
         }else{variables += "|null"}
              
        if(form.datedesde){variables += `|${form.datedesde},`
        }else{variables += "|null,"}

        if(form.datehasta){variables += `${form.datehasta}`
        }else{variables += "null"}
      
        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":null,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }

        dispatch(SolicitarEjecucion(Solicitar))
        
       };

    return (
    <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
        <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
        <DialogContent dividers>
        <div className={classes.root}>  
            <Typography variant='h4'>Criterios de busqueda</Typography>
            <Divider classes={{root: classes.divider}}/>

            <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    options={hotelgroup} 
                    name="Hotel Group"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                    }}
                />
  
          
                <div className={classes.input} >
                        <Pickersinapp
                          label="Date From"
                          value={form.datedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'datedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'datedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Date To"
                          value={form.datehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'datehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'datehasta', null))}}
                        />
                  </div>

               

        </div>
        <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
        </DialogContent>  
    </Dialog>
    )
};

export default CollectionsbyHotelbyMonth;

CollectionsbyHotelbyMonth.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
