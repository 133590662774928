import React , {useEffect,useState} from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography } from '@material-ui/core';
import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getListadoMenu,UpdateMenu} from '../../redux/actions/Mantenimiento';
import TextField from '@material-ui/core/TextField';


const headCells = [
  {
    id: 'descripcion',
    numeric: false,
    disablePadding: false,
    label: 'Descripción',
  }
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function InformesForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {menus} =  useSelector(({ mantenimiento }) => mantenimiento);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [nombre,setnombre] = useState('')
  const [formApp, setformApp] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
 
  useEffect(() => {
      dispatch(getListadoMenu());
  }, [dispatch ]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return menus;
      }
      return TableUtils.filterArrayByString(menus, _searchTerm);
    }
    if (menus) {
      setData(getFilteredArray(menus, searchTerm));
    }
  }, [menus, searchTerm]);

  const handleCloseDialog = () => {
      setformApp('');
      setnombre('');
      setOpenDialog(false);
  };

  const handleOpenDialog = (formAppdata) => {
    setformApp(formAppdata)
    setnombre(formAppdata.navTitle)
    setOpenDialog(true);
    
 };


 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');
};

const handlePageChange = (event, newPage) => {
  setPage(newPage);
};

const handleRowsPerPageChange = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  return (
    <PageContainer heading="Actualización de Menús">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
               Listado de Menús
            </Typography>
            <React.Fragment>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
            </React.Fragment>
          </Toolbar>
          
          <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.navTitle}</TableCell>
                                 <TableCell  align="right">  
                                    <Button size='small' className={classes.button} variant="contained" color='secondary'  onClick={() => handleOpenDialog(row)} >Editar</Button>
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
         </Paper>
    </div>
      <>
        <Dialog open={openDialog}  className={classes.dialogRoot} fullWidth maxWidth="md" >
            <DialogTitle className={classes.dialogTitleRoot}> Editar Plantilla </DialogTitle>
            <DialogContent dividers>
            <div className={classes.root}>  
               <TextField 
                     className={classes.textlabel}
                     fullWidth
                     label="Nombre" 
                     variant="outlined"
                     value={nombre}
                     onChange={(value) => setnombre(value.target.value) } 
                     />     
            </div>
                <Box display="flex" justifyContent="flex-end" my={4}>
                    <Button className={classes.button} variant="contained" onClick={handleCloseDialog}>Cancelar</Button>
                    <Box ml={2}>
                    <Button className={classes.button} variant="contained" color="secondary" onClick={() => {
                      dispatch(UpdateMenu(formApp.menuID,nombre))
                      handleCloseDialog()
                    }}>
                        Guardar
                    </Button>
                    </Box>
                </Box>
            </DialogContent>  
        </Dialog>
      </>
    </PageContainer>
  );
}