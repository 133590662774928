import _ from '@lodash';
import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    textlabel: {
      '& .MuiFormLabel-root': {
        color: '#232322',
      },
     },
   }));

function Pickersinapp(props) {
    const classes = useStyles();

    const importedProps = _.pick(props, [
		'autoComplete',
		'autoFocus',
		'children',
		'className',
		'defaultValue',
		'disabled',
		'FormHelperTextProps',
		'fullWidth',
		'id',
		'InputLabelProps',
		'inputProps',
		'InputProps',
		'inputRef',
		'label',
		'multiline',
		'name',
		'onBlur',
		'onChange',
		'onFocus',
		'placeholder',
		'required',
		'rows',
		'rowsMax',
		'select',
		'SelectProps',
		'type',
		'variant',
		'color',
        'multiple',
        'disableCloseOnSelect',
        'style'
       
	]);

     return(
        <KeyboardDatePicker
        {...importedProps}
          size='small'
          autoOk
         // disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          label={props.label}
          KeyboardButtonProps={{
            'aria-label': 'change date',
         }}
         inputVariant="outlined"
         className={classes.textlabel}
         value={props.value}
		// margin="dense"
		openTo="date"
		views={["year", "month", "date"]}
    />
 
  );
}


export default React.memo(Pickersinapp);