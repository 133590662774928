import _ from '@lodash';
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import makeStyles from '@material-ui/core/styles/makeStyles';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    textlabel: {
      '& .MuiFormLabel-root': {
        color: '#232322',
      },
     },
   }));

function Autocompetesinapp(props) {
    const classes = useStyles();

    const importedProps = _.pick(props, [
		'autoComplete',
		'autoFocus',
		'children',
		'className',
		'defaultValue',
		'disabled',
		'FormHelperTextProps',
		'fullWidth',
		'id',
		'InputLabelProps',
		'inputProps',
		'InputProps',
		'inputRef',
		'label',
		'multiline',
		'name',
		'onBlur',
		'onChange',
		'onFocus',
		'placeholder',
		'required',
		'rows',
		'rowsMax',
		'select',
		'SelectProps',
		'type',
		'variant',
		'color',
        'multiple',
        'disableCloseOnSelect'
	]);

     return(
        <Autocomplete
        {...importedProps}
        size='small'
        value={props.value}
        options={props.options}
        getOptionLabel={option => option.label.toString()}
        renderOption={(option, { selected }) => (
            <React.Fragment>
                {props.multiple  ? (
                    <>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected}   />
                    {option.label}
                    </>
                ) : (
                    <>
                    {option.label}
                    </>
                )}
            </React.Fragment>
       )}
        renderInput={params => <TextField {...params} variant="outlined" label={props.name} className={classes.textlabel}/>}
        />
 
  );
}


export default React.memo(Autocompetesinapp);