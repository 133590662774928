export const ValorSelect =(arreglo) => {
    if(Array.isArray(arreglo)){
        if(arreglo.length > 0){
            let values=[]
            arreglo.forEach(value =>{
                values.push(value.value)
            })
            return values.join();
        }else{
            return null;
        }
    }else{  
        if(arreglo){
            return  arreglo.value;
        }else{
            return null;
        }
    }
}
