import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useForm from '@jumbo/utils/useForm';
import { IniciarBalanceVencido,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';

const BalanceVencido = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
     const { hotel,hotelgroup,touroperator,touroperatorgroup,market,paymentcondition,invtype,accounttype,ordenarpor} = useSelector(({ ejecucion }) => ejecucion);
     const { form, setForm } = useForm({
        "hotel": null,
        "hotelgroup": null,
        "touroperator": null,
        "touroperatorgroup": null,
        "market": null,
        "paymentcondition": null,
        "accounttype":null,
        "invtype": null,
        "agedbalanceasof":null,
        "asofdate":null,
        "ordenarpor": null
     });

    useEffect(() => {
      dispatch(IniciarBalanceVencido(formApp.formAppID));
    }, [dispatch,formApp ]);


    const ejecucion = () => {
        let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
        }else{variables += "null"}

        if(form.hotelgroup){variables += `|${form.hotelgroup}`
        }else{variables += "|null"}

        if(form.touroperator){variables += `|${form.touroperator}`
        }else{variables += "|null"}

        if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
        }else{variables += "|null"}

        if(form.market){variables += `|${form.market}`
        }else{variables += "|null"}

        if(form.paymentcondition){variables += `|${form.paymentcondition}`
        }else{variables += "|null"}

        if(form.accounttype){variables += `|${form.accounttype}`
        }else{variables += "|null"}
       
        if(form.invtype){variables += `|${form.invtype}`
        }else{variables += "|null"}

        if(form.agedbalanceasof){variables += `|${form.agedbalanceasof}`
         }else{variables += "|null"}

        if(form.asofdate){variables += `|${form.asofdate}`
        }else{variables += "|null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":form.ordenarpor,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }
        dispatch(SolicitarEjecucion(Solicitar))
        
      };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography variant='h4'>Criterios de busqueda</Typography>
              <Divider classes={{root: classes.divider}}/>

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                  options={hotelgroup} 
                  name="Hotel Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                  }}
                />

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

               <Autocompetesinapp 
                  options={touroperatorgroup} 
                  name="Tour Operator Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                  }}
                />

                <Autocompetesinapp 
                   multiple 
                  disableCloseOnSelect 
                  options={market} 
                  name="Market"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    options={paymentcondition} 
                    name="Payment Condition"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'paymentcondition', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    options={accounttype} 
                    name="Account Type"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'accounttype', ValorSelect(newValue))) 
                    }}
                />
               
               <Autocompetesinapp 
                   multiple 
                  disableCloseOnSelect 
                  options={invtype} 
                  name="Invoice Type"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'invtype', ValorSelect(newValue))) 
                  }}
                />

                <Autocompetesinapp 
                  options={ordenarpor} 
                  name="Tipo de Reporte"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'ordenarpor', ValorSelect(newValue))) 
                  }}
                />

                        <Pickersinapp
                          label="Aged Balance As Of (*)"
                          value={form.agedbalanceasof}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'agedbalanceasof', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'agedbalanceasof', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="As Of Date (*)"
                          value={form.asofdate}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'asofdate', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'asofdate', null))}}
                        />
                
          
                  </div>
                  <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
             </DialogContent>  
        </Dialog>
    )
};

export default BalanceVencido;

BalanceVencido.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
