import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export function NumberFormatEnteroPositivo(props) {
	const { inputRef, onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		thousandSeparator
		isNumericString
		allowNegative ={false}	
		decimalScale="0"
		decimalSeparator={false}
	  />
	);
  }
  
  NumberFormatEnteroPositivo.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
  };

  export function NumberFormatEnteroNegativo(props) {
	const { inputRef, onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		thousandSeparator
		isNumericString
		decimalScale="0"
		decimalSeparator={false}
	  />
	);
  }
  
  NumberFormatEnteroNegativo.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
  };

  
  export function NumberFormatDecimalPositivo(props) {
	const { inputRef, onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		thousandSeparator
		isNumericString
		allowNegative ={false}	
		decimalScale="2"
	  />
	);
  }
  
  NumberFormatDecimalPositivo.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
  };

    
  export function NumberFormatDecimalNegativo(props) {
	const { inputRef, onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		thousandSeparator
		isNumericString
		decimalScale="2"
	  />
	);
  }
  
  NumberFormatDecimalNegativo.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
  };


  export function NumberFormatEntero(props) {
	const { inputRef, onChange, ...other } = props;
  
	return (
	  <NumberFormat
		{...other}
		getInputRef={inputRef}
		onValueChange={(values) => {
		  onChange({
			target: {
			  name: props.name,
			  value: values.value,
			},
		  });
		}}
		isNumericString
		allowNegative ={false}	
		//decimalScale="0"
		//decimalSeparator={false}
	  />
	);
  }
  
  NumberFormatEntero.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
  };

