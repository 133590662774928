import axios from 'axios';

export default axios.create({
  baseURL: `https://sinappapi.sonetisoluciones.com/api/v1/`,
  //fuera  https://sinappapi.sonetisoluciones.com/api/v1/,
  //produccion http://10.0.0.143:84/api/v1/,
  //desarrollo http://localhost:5173/api/v1/,
  headers: {
    'Content-Type': 'application/json',
  },
});
