import axios from '@jumbo/constants/axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  SET_PLANTILLAS,
  SET_INFORMES,
  SET_HOTELES,
  SET_TOURSOPERATOR,
  SET_LISTITEMSPRESUPUESTOCOBROS,
  SET_ITEMPRESUPUESTOCOBROBYID,
  SET_PRESUPUESTOCOBROEXCLUIR,
  SET_TTOODIASHABILES,
  SET_TIPOSDEFACTURACION,
  SET_HOTELPAIS,
  SET_PAISES,
  SET_LISTADOANOSPAIS,
  SET_LISTADODIASFESTIVOS,
  SET_LISTITEMPRESUPUESTOS,
  SET_PAISFESTIVOSELECT,
  SET_MENU,
  SET_CARTERAS,
  SET_DIALOGCARTERAS,
  SET_CONCEPTOSDESCUENTOS,
  SET_OPENPRESUPUESTOCOBRODIALOG,
  SET_CLOSEPRESUPUESTOCOBRODIALOG,
  SET_DIALOGCONCEPTOSDESCUENTOS,
  SET_COBROSNOREGULARES,
  SET_OPENCOBROSNOREGULARESDIALOG,
  SET_CLOSECOBROSNOREGULARESDIALOG,
  SET_CARTERAGRUPO,
  SET_OPENCARTERAGRUPODIALOG,
  SET_CLOSECARTERAGRUPODIALOG,
  SET_CARTERAGRUPODETALLE,
  SET_CARTERAGRUPOSELECT,
  SET_OPENPERIODOANODIALOG,
  SET_CLOSEPERIODOANODIALOG,
  SET_PERIODOANOSELECT,
  SET_PERIODOANOS,
  SET_ANOS,
  SET_OPENPERIODOSSEMANALDIALOG,
  SET_CLOSEPERIODOSSEMANALDIALOG,
  SET_PERIODOSSEMANAL,
  SET_CAMPOSHEADER,
  SET_CAMPOSFORMULARIO,
} from '../../@jumbo/constants/ActionTypes';
import moment from 'moment';

// Mantenimiento Plantillas e Informes
export const getInformes = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`/usuario/frmapplst/INFORME`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_INFORMES, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const getPlantillas = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`/usuario/frmapplst/PLANTILLA `)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_PLANTILLAS, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const updateInforme = (formAppID, titulo, orden, activo) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`usuario/frmappupd/${formAppID}/${titulo}/${orden}/${activo}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(getInformes());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const updatePlantilla = (formAppID, titulo, orden, activo) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`usuario/frmappupd/${formAppID}/${titulo}/${orden}/${activo}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(getPlantillas());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

// Mantenimiento Items Presupuesto de Cobros

export const getHoteles = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`filtro/filhotel`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_HOTELES, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const getHotelesPresupuesto = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`filtro/filhotel`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          let valores = data.data;
          valores.push({ value: '-10000', label: 'Mostar Todos' });
          dispatch({ type: SET_HOTELES, payload: valores });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const getHotelesPresupuestoConceptoFiltrado = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`filtro/filprecobroconceptohotel`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          let valores = data.data;
          valores.push({ value: '-10000', label: 'Mostar Todos' });
          dispatch({ type: SET_HOTELES, payload: valores });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const getTourOperatorPresupuesto = codigoBusqueda => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`filtro/filttoocartera/${codigoBusqueda}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          let valores = data.data;
          valores.push({ value: '-10000', label: 'Mostar Todos' });
          dispatch({ type: SET_TOURSOPERATOR, payload: valores });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const getTourOperator = codigoBusqueda => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`filtro/filttoo/${codigoBusqueda}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_TOURSOPERATOR, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const getLisItemsPresupuestoCobros = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`filtro/filitemprescob/true`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          let valores = data.data;
          valores.push({ value: '-10000', label: 'Mostar Todos' });
          dispatch({ type: SET_LISTITEMPRESUPUESTOS, payload: valores });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const getItemsPresupuestoCobros = presupuestoPartidaID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/preparbyid/${presupuestoPartidaID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_OPENPRESUPUESTOCOBRODIALOG, payload: data.data });
          //dispatch({ type: SET_ITEMPRESUPUESTOCOBROBYID, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const iniciarItemsPresupuestoCobros = () => {
  return dispatch => {
    dispatch(fetchStart());
    dispatch({
      type: SET_ITEMPRESUPUESTOCOBROBYID,
      payload: {
        presupuestoPartidaID: null,
        presupuestoItemID: '-10000',
        hotelID: '-10000',
        tourOpID: '-10000',
        importe: '',
        fechaDummy: moment().format('YYYYMMDD'),
        comentario: '',
        usuarioID: null,
        tourOpGroupId: '-10000',
      },
    });
    dispatch(fetchSuccess());
  };
};

export const BusquedaItemsPresupuestoCobros = (
  presupuestoPartidaID,
  presupuestoItemID,
  hotelID,
  tourOpID,
  tourOpGroupId,
) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(
        `mantenimiento/preparlst/${presupuestoPartidaID === null ? '-10000' : presupuestoPartidaID}/${
          presupuestoItemID === null ? '-10000' : presupuestoItemID
        }/${hotelID === null ? '-10000' : hotelID}/${tourOpGroupId === null ? '-10000' : tourOpGroupId}/${
          tourOpID === null ? '-10000' : tourOpID
        }/na`,
      )
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_LISTITEMSPRESUPUESTOCOBROS, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const grabarItemsPresupuestoCobros = item => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/preparmant`, item)
      .then(data => {
        if (data.status === 200) {
          dispatch(BusquedaItemsPresupuestoCobros('-10000', '-10000', '-10000', '-10000', '-10000'));
          dispatch({
            type: SET_ITEMPRESUPUESTOCOBROBYID,
            payload: {
              presupuestoPartidaID: null,
              presupuestoItemID: item.presupuestoItemID,
              hotelID: item.hotelID,
              tourOpID: item.tourOpID,
              importe: '',
              fechaDummy: item.fechaDummy, //moment().format('YYYYMMDD'),
              comentario: '',
              usuarioID: null,
              tourOpGroupId: item.tourOpGroupId,
            },
          });

          dispatch(fetchSuccess('Deducción registrada.'));
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const updateItemsPresupuestoCobros = item => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/preparmant`, item)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Actualizada con Exito'));
          dispatch(BusquedaItemsPresupuestoCobros('-10000', '-10000', '-10000', '-10000', '-10000'));
          dispatch({ type: SET_CLOSEPRESUPUESTOCOBRODIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const elimienatItemsPresupuestoCobros = presupuestoPartidaID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/prepardel/${presupuestoPartidaID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Item Eliminado'));
          setTimeout(() => {
            dispatch(BusquedaItemsPresupuestoCobros('-10000', '-10000', '-10000', '-10000', '-10000'));
          }, 1000);
          dispatch({ type: SET_CLOSEPRESUPUESTOCOBRODIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

// Mantenimiento Presupuesto de Cobros Excluir TTOO

export const getPresupuestoCobrosExcluirTTOO = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/ttooexclulst`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_PRESUPUESTOCOBROEXCLUIR, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const agregarPresupuestoCobrosExcluirTTOO = tourOPID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/ttooexcluadd/${tourOPID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getPresupuestoCobrosExcluirTTOO());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const eliminarPresupuestoCobrosExcluirTTOO = tourOPID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/ttooexcludel/${tourOPID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getPresupuestoCobrosExcluirTTOO());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

// Mantenimiento – TTTO Dias Hábiles

export const getTTToDiasHabiles = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/ttoodiahablst/-10000/NA`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_TTOODIASHABILES, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const getTiposFacturacion = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/tipfacttoolst`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_TIPOSDEFACTURACION, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const GrabarDiasHabiles = (tourOpID, tipoFacturacionTTOOID, diasHabil) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/ttoodiahabilmant/${tourOpID}/${tipoFacturacionTTOOID}/${diasHabil}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getTTToDiasHabiles());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const AgregarDiasHabiles = Condicion => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/ttoodiahabiladd`, Condicion)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getTTToDiasHabiles());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const EliminarDiasHabiles = tourOpDiasHabilID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/ttoodiahabildel/${tourOpDiasHabilID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getTTToDiasHabiles());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchError(error.response.data));
      });
  };
};

/// Mantenimiento – listado de hoteles y asignación de país

export const getListadoHoteles = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/hotcountrylst`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_HOTELPAIS, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getListaPaises = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/countrylst`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_PAISES, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const AsignarPaisHotel = (countryID, hotelID) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/hotcountrymant/${countryID}/${hotelID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getListadoHoteles());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const EliminarPaisHotel = (countryID, hotelID) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/hotcountrydel/${countryID}/${hotelID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getListadoHoteles());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

// Mantenimiento-Días Festivos por País

export const getListaPaisesFestivos = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/countrylst`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());

          var arreglo = data.data.map(item => {
            return { value: item.countryID, label: item.countryName.toString() };
          });

          dispatch({ type: SET_PAISES, payload: arreglo });

          if (data.data.length > 0) {
            dispatch({ type: SET_PAISFESTIVOSELECT, payload: arreglo[0] });

            dispatch(getListanosPaises(arreglo[0]));
          }
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getListanosPaises = row => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/countryholyearlst/${row.value}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());

          dispatch({ type: SET_PAISFESTIVOSELECT, payload: row });
          dispatch({ type: SET_LISTADOANOSPAIS, payload: data.data });
          // dispatch({ type: SET_LISTADODIASFESTIVOS, payload: [] });

          if (data.data.length === 0) {
            dispatch({ type: SET_LISTADOANOSPAIS, payload: [] });
            //dispatch({ type: SET_LISTADODIASFESTIVOS, payload: [] });
          } else {
            // dispatch(getListDiasFestivosPaises('-10000',row.value, data.data[0].value))
          }
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getListDiasFestivosPaises = (countryHoliDayID, countryID, year) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/countryhollst/${countryHoliDayID}/${countryID}/${year}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_LISTADODIASFESTIVOS, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const EliminarDiaFestivo = (countryHoliDayID, paisfestivoselect) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/countryholdel/${countryHoliDayID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getListanosPaises(paisfestivoselect));
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const agregarDiaFestivo = (paisfestivoselect, fechaDummy, recurrente) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/countryholmant/0/${paisfestivoselect.value}/${fechaDummy}/${recurrente}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getListanosPaises(paisfestivoselect));
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getListadoMenu = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`usuario/menupdget/`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_MENU, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const UpdateMenu = (menuID, nombre) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`usuario/menuUpd/${menuID}/${nombre}/true`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(getListadoMenu());
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

//ConcecptosDescuentos
export const getConceptosDescuento = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`PresupuestoItem/list`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CONCEPTOSDESCUENTOS, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const addConceptoDescuento = concepto => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoItem/add`, concepto)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          setTimeout(() => {
            dispatch(getConceptosDescuento());
          }, 1000);
          dispatch({ type: SET_DIALOGCONCEPTOSDESCUENTOS, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const updConceptoDescuento = concepto => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoItem/upd`, concepto)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          setTimeout(() => {
            dispatch(getConceptosDescuento());
          }, 1000);
          dispatch({ type: SET_DIALOGCONCEPTOSDESCUENTOS, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const ElimarConceptoDescuento = presupuestoItemID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoItem/del/${presupuestoItemID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Concepto Descuento Eliminado'));
          setTimeout(() => {
            dispatch(getConceptosDescuento());
          }, 1000);
          dispatch({ type: SET_DIALOGCONCEPTOSDESCUENTOS, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

// Carteras

export const getCarteras = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/carteralst/-1`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CARTERAS, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getCarterasPresupuesto = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`filtro/filtcartera`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CARTERAS, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const mantCarteras = cartera => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/carteramant`, cartera)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          setTimeout(() => {
            dispatch(getCarteras());
          }, 1000);
          dispatch({ type: SET_DIALOGCARTERAS, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const EliminarCartera = carteraid => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/carteradel/${carteraid}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Cartera Eliminada'));
          setTimeout(() => {
            dispatch(getCarteras());
          }, 1000);
          dispatch({ type: SET_DIALOGCARTERAS, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

// Registrar Cobros no Regulares

export const getCobrosnoregulares = frmcobros => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/cobrolst/`, frmcobros)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_COBROSNOREGULARES, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const addCobrosnoregulares = Cobrosnoregulares => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/cobroadd`, Cobrosnoregulares)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          setTimeout(() => {
            dispatch(
              getCobrosnoregulares({ tourOpGroupId: '-10000', hotelID: '-10000', fechaInicial: 'na', fechaFinal: 'na' }),
            );
          }, 1000);
          dispatch({ type: SET_CLOSECOBROSNOREGULARESDIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const updCobrosnoregulares = Cobrosnoregulares => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/cobroupd`, Cobrosnoregulares)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          setTimeout(() => {
            dispatch(
              getCobrosnoregulares({ tourOpGroupId: '-10000', hotelID: '-10000', fechaInicial: 'na', fechaFinal: 'na' }),
            );
          }, 1000);
          dispatch({ type: SET_CLOSECOBROSNOREGULARESDIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const getCobronoregularesbyid = cobroNoRegularID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/cobroget/${cobroNoRegularID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_OPENCOBROSNOREGULARESDIALOG, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const EliminarCobronoregular = cobroNoRegularID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/cobrodel/${cobroNoRegularID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Cobro no regular eliminado'));
          setTimeout(() => {
            dispatch(
              getCobrosnoregulares({ tourOpGroupId: '-10000', hotelID: '-10000', fechaInicial: 'na', fechaFinal: 'na' }),
            );
          }, 1000);
          dispatch({ type: SET_CLOSECOBROSNOREGULARESDIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

//Cartera grupo
export const getCarterascobrosInicio = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/grpcarteralst`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CARTERAGRUPO, payload: data.data });

          if (data.data.length > 0) {
            dispatch({ type: SET_CARTERAGRUPOSELECT, payload: data.data[0] });
            dispatch(getCarterascobrosDetalle(data.data[0].carteraGrupoID));
          }
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getCarterascobros = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/grpcarteralst`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CARTERAGRUPO, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getCarterascobrosbyid = carteraGrupoID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/grpcarteraget/${carteraGrupoID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_OPENCARTERAGRUPODIALOG, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const addCarteraCobros = carteracobros => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/grpcarteraadd`, carteracobros)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          setTimeout(() => {
            dispatch(getCarterascobros());
          }, 1000);
          dispatch({ type: SET_CLOSECARTERAGRUPODIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const updCarteraCobros = carteracobros => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/grpcarteraupd`, carteracobros)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          setTimeout(() => {
            dispatch(getCarterascobros());
          }, 1000);
          dispatch({ type: SET_CLOSECARTERAGRUPODIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const Eliminarcarteracobros = carteraGrupoID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/grpcarteradel/${carteraGrupoID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Grupo eliminado'));
          setTimeout(() => {
            dispatch(getCarterascobrosInicio());
          }, 1000);
          dispatch({ type: SET_CLOSECARTERAGRUPODIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getCarterascobrosDetalle = carteraGrupoID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`mantenimiento/grpcarteradetallelst/${carteraGrupoID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CARTERAGRUPODETALLE, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const addCarteraCobrosDetalle = carteracobros => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/grpcarteradetalleadd`, carteracobros)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          setTimeout(() => {
            dispatch(getCarterascobrosDetalle(carteracobros.carteraGrupoID));
          }, 1000);
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const Eliminarcarteracobrosdetalle = value => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`mantenimiento/grpcarteradetalledel/${value.carteraGrupoDetalleID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Detalle eliminado'));
          setTimeout(() => {
            dispatch(getCarterascobrosDetalle(value.carteraGrupoID));
          }, 1000);
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

//Periodo presupuesto mensual

export const getAnosInicio = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`PresupuestoPeriodoMensual/yearlist`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_ANOS, payload: data.data });

          if (data.data.length > 0) {
            dispatch({ type: SET_PERIODOANOSELECT, payload: data.data[0] });
            dispatch(getPeriodosanos(data.data[0].periodoAno));
          }
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getAnos = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`PresupuestoPeriodoMensual/yearlist`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_ANOS, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getPeriodosanos = periodoAno => {
  return (dispatch, getState) => {
    dispatch(fetchStart());
    axios
      .get(`PresupuestoPeriodoMensual/list/${periodoAno}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_PERIODOANOS, payload: data.data });

          if (data.data.length == 0) {
            dispatch(getAnosInicio());
          }
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getbyidPresupuestoMensual = presupuestoPeriodoMensualID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`PresupuestoPeriodoMensual/byid/${presupuestoPeriodoMensualID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_OPENPERIODOANODIALOG, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const addPresupuestoMensual = Presupuesto => {
  return (dispatch, getState) => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoPeriodoMensual/add`, Presupuesto)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess(data.data));
          setTimeout(() => {
            if (getState().mantenimiento.anos.length === 0) {
              dispatch(getAnosInicio());
            } else {
              dispatch(getAnos());
              dispatch(getPeriodosanos(getState().mantenimiento.PeriodoAnoSelect.periodoAno));
            }
          }, 1000);
          dispatch({ type: SET_CLOSEPERIODOANODIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const updPresupuestoMensual = carteracobros => {
  return (dispatch, getState) => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoPeriodoMensual/upd`, carteracobros)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          setTimeout(() => {
            dispatch(getPeriodosanos(getState().mantenimiento.PeriodoAnoSelect.periodoAno));
          }, 1000);
          dispatch({ type: SET_CLOSEPERIODOANODIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const EliminarPresupuestoMensual = borrardata => {
  return (dispatch, getState) => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoPeriodoMensual/del/${borrardata.presupuestoPeriodoMensualID}/${borrardata.usuarioID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Periodo eliminado'));
          setTimeout(() => {
            dispatch(getPeriodosanos(getState().mantenimiento.PeriodoAnoSelect.periodoAno));
          }, 1000);
          dispatch({ type: SET_CLOSEPERIODOANODIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

//Propuesta Semanal

export const getPeriodosSemanal = frmfiltros => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoPeriodoSemanal/list`, frmfiltros)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_PERIODOSSEMANAL, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const getbyidPeriodoSemanal = presupuestoPeriodoSemanalID => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`PresupuestoPeriodoSemanal/byid/${presupuestoPeriodoSemanalID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_OPENPERIODOSSEMANALDIALOG, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const addPeriodoSemanal = frm => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoPeriodoSemanal/add`, frm)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess(data.data));
          setTimeout(() => {
            dispatch(getPeriodosSemanal({ FechaInicial: null, FechaFinal: null }));
          }, 1000);
          dispatch({ type: SET_CLOSEPERIODOSSEMANALDIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const updPeriodoSemanal = frm => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoPeriodoSemanal/upd`, frm)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess(data.data));
          setTimeout(() => {
            dispatch(getPeriodosSemanal({ FechaInicial: null, FechaFinal: null }));
          }, 1000);
          dispatch({ type: SET_CLOSEPERIODOSSEMANALDIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

export const EliminarPeriodoSemanal = value => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`PresupuestoPeriodoSemanal/del/${value.presupuestoPeriodoSemanalID}/${value.usuarioID}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess(data.data));
          setTimeout(() => {
            dispatch(getPeriodosSemanal({ FechaInicial: null, FechaFinal: null }));
          }, 1000);
          dispatch({ type: SET_CLOSEPERIODOSSEMANALDIALOG, payload: false });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response.data));
      });
  };
};

//Generica
export const GetCamposFormulario = componente => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`generica/formfldlst/${componente}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CAMPOSFORMULARIO, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};

export const Getrowsheader = componente => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`generica/frmgridheader/${componente}`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_CAMPOSHEADER, payload: data.data });
        } else {
          dispatch(fetchError('Esta acción no pudo realizarse.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('Esta acción no pudo realizarse.'));
      });
  };
};
