import { fetchError, fetchStart, fetchSuccess,getmenu } from '../../../redux/actions';
import { setAuthUser, updateLoadUser,SetupdatePassworUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from '@jumbo/constants/axios';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },


  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('Usuario/auth', {
            LoginName: email,
            Password: password,
          })
          .then(({ data }) => {
           
            if (data) {
              
               if(data.cambiarClave){
                 dispatch(SetupdatePassworUser(data));
               }
              else{
                  localStorage.setItem('authUser', JSON.stringify(data));
                  localStorage.setItem('token', data.token);
                  axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
              
                    axios.get(`Usuario/usrmenuget/${data.usuarioID}`).then(({ data }) => {
                      dispatch(getmenu(data));
                      localStorage.setItem('authUserMenu', JSON.stringify(data));
                      dispatch(JWTAuth.getAuthUser(true, data.token));
                  })
    
                 
               }
               dispatch(fetchSuccess());
              } else {
               dispatch(fetchError(data.error));
              }
            })
          .catch(function(error) {
            dispatch(fetchError(error.response.data));
          });
      } catch (error) {
        dispatch(fetchError(error.response.data));
      }
    };
  },

  onchangepassowrd: ({ usuarioID ,passwordanterior, passwordnew,passwordConfirmacion}) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('Usuario/usrchangepwd', {
            UsuarioID: usuarioID,
            PassWordAnterior: passwordanterior,
            PassWord: passwordnew,
            PassWordConfirmacion: passwordConfirmacion,
          })
          .then(({ data }) => {
             dispatch(SetupdatePassworUser(null));
             dispatch(fetchSuccess("Clave cambiada con exito"));
            })
          .catch(function(error) {
            dispatch(fetchError(error.response.data));
          });
      } catch (error) {
        dispatch(fetchError(error.response.data));
      }
    };
  },
 
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(fetchSuccess());
      localStorage.setItem('token',null);
      localStorage.setItem('authUser',null);
      localStorage.setItem('authUserMenu',null);
      dispatch(setAuthUser(null));
      dispatch(getmenu(null));
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
       
        dispatch(fetchStart());
        dispatch(updateLoadUser(loaded));
        dispatch(fetchSuccess());

        if( localStorage.getItem('authUserMenu') === null){
          dispatch(getmenu([]));
         }else{
          dispatch(getmenu( JSON.parse(localStorage.getItem('authUserMenu'))));
         }
        
        dispatch(setAuthUser(JSON.parse(localStorage.getItem('authUser'))));
      };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
           dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
