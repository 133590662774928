import React, { useState,useEffect,useCallback } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography } from '@material-ui/core';
import useStyles from '../../@jumbo/components/ListadoEjecucion/ListadoEjecucionStyle';
import NoRecordFound from '../../@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '../../@jumbo/utils/tableHelper';
import { useSelector,useDispatch } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '../../@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import ContratoVentasGrupos from '../Ejecucion/ContratoVentasGrupos';
import FacturasAmericaAirlines from '../Ejecucion/FacturasAmericaAirlines';
import FacturasExpedida from '../Ejecucion/FacturasExpedida';
import PlantillaEstadoCuentaCostco from '../Ejecucion/PlantillaEstadoCuentaCostco';
import PlantillaEstadoCuentaGOGO from '../Ejecucion/PlantillaEstadoCuentaGOGO';
import  EstadoCuentaPGS from '../Ejecucion/EstadoCuentaPGS';
import  EstadoCuentaKHR from '../Ejecucion/EstadoCuentaKHR';
import  EstadoCuentaDestiworld from '../Ejecucion/EstadoCuentaDestiworld';
import  EstadoCuentaDestimundo from '../Ejecucion/EstadoCuentaDestimundo';
import  ReconciliationSabana from '../Ejecucion/ReconciliationSabana';
import  ReconciliationAlberto from '../Ejecucion/ReconciliationAlberto';
import  ConfirmacionSaldosFacturacion from '../Ejecucion/ConfirmacionSaldosFacturacion';
import {SET_OPENDIALOG}  from'@jumbo/constants/ActionTypes';

const headCells = [
  {
    id: 'descripcion',
    numeric: false,
    disablePadding: false,
    label: 'Descripción',
  }
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

const ListPlantillas = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const ListaPlantillas =  useSelector(({ ejecucion }) => ejecucion.plantillas);
    const {openDialog} = useSelector(({ ejecucion }) => ejecucion);
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = React.useState('descripcion');
    const [order, setOrder] = React.useState('asc');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [formApp, setformApp] = useState('');
 
    const handleCloseDialog = useCallback(() => {
      setformApp('')
      dispatch({ type: SET_OPENDIALOG, payload: false });
    },[dispatch,setformApp]);

    const handleOpenDialog = (formAppdata) => {
      setformApp(formAppdata)
      dispatch({ type: SET_OPENDIALOG, payload: true });
   };

   useEffect(() => {
    if(!openDialog){
        handleCloseDialog()
      }
    }, [openDialog,handleCloseDialog]);


    useEffect(() => {
      function getFilteredArray(entities, _searchTerm) {
        if (_searchTerm.length === 0) {
          return ListaPlantillas;
        }
        return TableUtils.filterArrayByString(ListaPlantillas, _searchTerm);
      }
      if (ListaPlantillas) {
        setData(getFilteredArray(ListaPlantillas, searchTerm));
      }
    }, [ListaPlantillas, searchTerm]);
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
      };

      const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
               Listado de plantillas
            </Typography>
            <React.Fragment>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
            </React.Fragment>
          </Toolbar>
          
          <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.titulo}</TableCell>
                                 <TableCell  align="right">  
                                    <Button  
                                      size='small'  
                                      className={classes.button} 
                                      variant="contained" 
                                      color="secondary" 
                                      onClick={() => handleOpenDialog(row)}>
                                        Ejecutar
                                    </Button>
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
              
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
         </Paper>
      <>
        {formApp.formAppID === 3 &&  <ContratoVentasGrupos open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 12 && <FacturasAmericaAirlines open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 11 && <FacturasExpedida open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 20 && <PlantillaEstadoCuentaCostco open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 21 && <PlantillaEstadoCuentaGOGO open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 30 && <EstadoCuentaPGS open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 31 && <EstadoCuentaKHR open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 32 && <EstadoCuentaDestiworld open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 33 && <EstadoCuentaDestimundo open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 35 && <ReconciliationSabana open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 36 && <ReconciliationAlberto open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 38 && <ConfirmacionSaldosFacturacion open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
      </>
              
    </div>
  );
};

export default ListPlantillas;
