import {  
  UPDATE_AUTH_USER, 
  UPDATE_LOAD_USER,
  SET_EDITUSUARIO,
  SET_CLOSEDILOGUSUARIO ,
  SET_USUARIOS,
  SET_ROLESBYUSUARIO,
  SET_ROLES,
  SET_EDITROLES,
  SET_CLOSEDILOGROLES ,
  SET_FORMROL,
  SET_MENUROL,
  RESET_PASSWORD
} from '../../@jumbo/constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '@jumbo/constants/axios';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const SetupdatePassworUser = loading => {
  return dispatch => {
    dispatch({
      type: RESET_PASSWORD,
      payload: loading,
    });
  };
};


export const resetpassword = (UsuarioCambiarPwdParam) => {
  return dispatch => {
     dispatch(fetchStart());
     axios
       .post(`/Usuario/usrchangepwd`,UsuarioCambiarPwdParam)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess(data.data));
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
         dispatch(fetchError(error.response.data));
       });
  };
};

export const getUsuarios = () => {
  return dispatch => {
     dispatch(fetchStart());
     axios.get(`/Usuario/usrlist`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({ type: SET_USUARIOS, payload: data.data });
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};

export const getEditarUsuario = (usrid) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.get(`/Usuario/usredit/${usrid}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({ type: SET_EDITUSUARIO, payload: data.data });
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};

export const BorrarUsuario = (usrid,UsuarioID) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/usreliminar/${usrid}/${UsuarioID}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess("Usuario Borrado"));
            setTimeout(() => {
              dispatch(getUsuarios());
            dispatch({ type: SET_CLOSEDILOGUSUARIO});  
            }, 1000);
            
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};

export const GrabarUsuario = (usrparams,usuarioID) => {
  usrparams.createdBy = usuarioID;
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/usrAdd/`,usrparams)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess(data.data));
            dispatch(getUsuarios());
            dispatch({ type: SET_CLOSEDILOGUSUARIO});
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const InactivarUsuario = (usuarioID,usuarioidb) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/usrinactivar/${usuarioID}/${usuarioidb}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess("Usuario Inactivo"));
            dispatch({ type: SET_CLOSEDILOGUSUARIO});
            setTimeout(() => {
              dispatch(getUsuarios());      
              }, 1000);
          } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};

export const ActivarUsuario = (usuarioID,usuarioidb) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/usrreactivar/${usuarioID}/${usuarioidb}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess("Usuario Activado"));
            dispatch({ type: SET_CLOSEDILOGUSUARIO});
            setTimeout(() => {
              dispatch(getUsuarios());      
              }, 1000);
          } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const EditarUsuario = (usrparams,usuarioID) => {
  usrparams.createdBy = usuarioID;
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/usrUpd/`,usrparams)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess("Usuario actualizado"));
            dispatch(getUsuarios());
            dispatch({ type: SET_CLOSEDILOGUSUARIO});
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};

export const resetpswuser = (usuarioID) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.get(`/Usuario/solchgpwd/${usuarioID}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess("Contraseñas Restablecida"));
            setTimeout(() => {
              dispatch(getUsuarios());  
            }, 1000);
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};

export const getRolesByUsuario = (usuarioID) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.get(`/Usuario/usrrolget/${usuarioID}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess(""));
            dispatch({ type: SET_ROLESBYUSUARIO, payload: data.data });
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const agregarRolesUsuario = (UsuarioRolAddUpdParam) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/usrrolmant`,UsuarioRolAddUpdParam)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess("Roles Actualizados"));
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const getRoles = () => {
  return dispatch => {
     dispatch(fetchStart());
     axios.get(`/Usuario/rollist`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess(""));
            dispatch({ type: SET_ROLES, payload: data.data });
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const getEditarRoles = (rolID) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.get(`/Usuario/rolbyid/${rolID}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({ type: SET_EDITROLES, payload: data.data });
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const GrabarRoles= (usrparams,usuarioID) => {
  usrparams.createdBy = usuarioID;
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/rolAdd/`,usrparams)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess(data.data));
            dispatch(getRoles());
            dispatch({ type: SET_CLOSEDILOGROLES});
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};

export const EditarRoles = (usrparams,usuarioID) => {
  usrparams.createdBy = usuarioID;
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/rolUpd/`,usrparams)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess("Rol actualizado"));
            dispatch(getRoles());
            dispatch({ type: SET_CLOSEDILOGROLES});
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};

export const EliminarRoles = (RolID,usuarioID) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/roldel/${RolID}/${usuarioID}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess("Rol Eliminado"));
            setTimeout(() => {
              dispatch(getRoles());  
            }, 1000);
            dispatch({ type: SET_CLOSEDILOGROLES});
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const getFormRol = (rolID) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.get(`/Usuario/frmapprolget/-10000/${rolID}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess(""));
            dispatch({ type: SET_FORMROL, payload: data.data });
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const agregarFormRol = (rolID,formAppIDs) => {
  console.log(rolID,formAppIDs)
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/frmappaut/${rolID}/${formAppIDs}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess("Roles Actualizados"));
            setTimeout(() => {
              dispatch(getFormRol(rolID))
            }, 1000);
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const getMenuRol = (rolID) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.get(`/Usuario/rolmenu/${rolID}`)
       .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess(""));
            dispatch({ type: SET_MENUROL, payload: data.data });
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};


export const agregarMenuRol = (rolID,menuIDs) => {
  return dispatch => {
     dispatch(fetchStart());
     axios.post(`/Usuario/rolmenuAddUpd/${rolID}/${menuIDs}`)
       .then(data => {
        dispatch(fetchSuccess("Roles Actualizados"));  
        if (data.status === 200) {
          setTimeout(() => {
            dispatch(getMenuRol(rolID))
          }, 1000);
           
         } else {
           dispatch(fetchError('Esta acción no pudo realizarse.'));
         }
       })
       .catch(error => {
        dispatch(fetchError(error.response.data));
       });
  };
};