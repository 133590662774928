import React, { useState} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 25,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  button: {
    textTransform: "none"
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const { reset_password } = useSelector(({ auth }) => auth);
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordnew, setPasswordnew] = useState('');
  const [passwordConfirmacion, setPasswordConfirmacion] = useState('');
 
  const [emailError, setEmailError] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [passwordConfirmacionerror, setPasswordConfirmacionerror] = useState('');

  const checkValidations = () => {
     if (!email) {
      setEmailError("Usuario Requerido");
    } 
    else if (!password) {
      setpasswordError("Contraseña Requerida");
    } 
    else{
      dispatch(AuhMethods[method].onLogin({ email, password }));
    }
  };

  const checkValidationschangepassowrd = () => {
    if (!email) {
     setEmailError("Usuario Requerido");
   } 
   else if (!passwordnew) {
     setpasswordError("Contraseña Requerida");
   } 
   else if (!passwordConfirmacion) {
    setPasswordConfirmacionerror("Confirmación Contraseña Requerida");
  } 
   else{
     let usuarioID = reset_password.usuarioID
     let passwordanterior = password;
     setPassword('')
     dispatch(AuhMethods[method].onchangepassowrd({ usuarioID ,passwordanterior, passwordnew,passwordConfirmacion }));
   }
 };

  

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
       <Box  mb={4} >
          <CmtImage src={'/images/logoauth.png'} />
        </Box>
        {reset_password !== null &&  
        <Box display="flex" alignItems="center" justifyContent="center" mb={3} >
          <Typography>Cambio de Contraseña</Typography>
        </Box>
        }
        <form>
          <Box mb={4}>
            <AppTextInput
              label="Usuario"
              fullWidth
              onChange={event =>{
                 setEmail(event.target.value)
                 setEmailError('');                
                }}
              defaultValue={email}
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={emailError}
              disabled ={reset_password === null ? false : true}
            />
          </Box>
          {reset_password === null &&
          <Box mb={6}>
            <AppTextInput
              type=  "password"
              label= "Contraseña"
              fullWidth
              onChange={event => {
                setPassword(event.target.value)
                setpasswordError('')
              }}
              defaultValue={password}
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={passwordError}
            />
          </Box>
          }
          {reset_password !== null &&
          <>
              <Box mb={3}>
              <AppTextInput
                type="password"
                label= "Contraseña Nueva"
                fullWidth
                onChange={event => {
                  setPasswordnew(event.target.value)
                  setpasswordError('')
                }}
                defaultValue={passwordnew}
                variant="outlined"
                className={classes.textFieldRoot}
                helperText={passwordError}
              />
            </Box>
            <Box mb={6} >
              <AppTextInput
                type="password"
                label="Confirmar Contraseña"
                fullWidth
                onChange={event => {
                  setPasswordConfirmacion(event.target.value)
                  setPasswordConfirmacionerror('')
                }}
                defaultValue={passwordConfirmacionerror}
                variant="outlined"
                className={classes.textFieldRoot}
                helperText={passwordConfirmacionerror}
              />
          </Box>
          </>
          }
    
          <Box >
            <Button className={classes.button}  onClick={ () => {if(reset_password !== null){checkValidationschangepassowrd()}else{checkValidations()}} } variant="contained" color="primary">
               {reset_password !== null ? 'Cambiar Contraseña' : 'Iniciar Sesion' }
            </Button>
          </Box>
        </form>

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
