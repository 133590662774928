import React, { useState,useEffect,useCallback } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography } from '@material-ui/core';
import useStyles from '../../@jumbo/components/ListadoEjecucion/ListadoEjecucionStyle';
import NoRecordFound from '../../@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '../../@jumbo/utils/tableHelper';
import { useSelector,useDispatch } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '../../@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import ReviewedInvoices from '../Ejecucion/ReviewedInvoices';
import TourOperatorReport from '../Ejecucion/TourOperatorReport';
import Reservations from '../Ejecucion/Reservations';
import InventarioTarifas from '../Ejecucion/InventarioTarifas';
import InvoiceJournal from '../Ejecucion/InvoiceJournal';
import Comparativofacturacclvshotel from '../Ejecucion/Comparativofacturacclvshotel';
import StatusFacturacion from '../Ejecucion/StatusFacturacion';
import AccountStatement from '../Ejecucion/AccountStatement';
import PresupuestoCobrosNeto from '../Ejecucion/PresupuestoCobrosNeto';
import PresupuestoCobrosBrutos from '../Ejecucion/PresupuestoCobrosBrutos';
import PresupuestoCobrosBrutosFinanzas from '../Ejecucion/PresupuestoCobrosBrutosFinanzas';
import AgedBalance from '../Ejecucion/AgedBalance';
import Garantias from '../Ejecucion/Garantias';
import DailyJorunal from '../Ejecucion/DailyJorunal';
import IncomeReconciliation from '../Ejecucion/IncomeReconciliation';
import CountingReport from '../Ejecucion/CountingReport';
import DocumentBalance from '../Ejecucion/DocumentBalance';
import CountingReportsDocuments from '../Ejecucion/CountingReportsDocuments';
import FacturasFechaAplicacion from '../Ejecucion/FacturasFechaAplicacion';
import MonthlyTransactions from '../Ejecucion/MonthlyTransactions';
import AdjusmentReport from '../Ejecucion/AdjusmentReport';
import CollectionsbyHotelbyMonth from '../Ejecucion/CollectionsbyHotelbyMonth';
import GrossBilling from '../Ejecucion/GrossBilling';
import NetBilling from '../Ejecucion/NetBilling';
import Adjustments from '../Ejecucion/Adjustments';
import BalanceVencido from 'routes/Ejecucion/BalanceVencido';
import AccountReceivableBreakdown from 'routes/Ejecucion/AccountReceivableBreakdown';
import RotacionCartera from 'routes/Ejecucion/RotacionCartera';
import Formna from '../Ejecucion/Formna';
import {SET_OPENDIALOG}  from'@jumbo/constants/ActionTypes';
import EjecuciónPresupuestodeCobros from '../Ejecucion/EjecucionPresupuestodeCobros'
import EjecuciónProyeccionSemanal from '../Ejecucion/EjecucionProyeccionSemanal'

const headCells = [
  {
    id: 'descripcion',
    numeric: false,
    disablePadding: false,
    label: 'Descripción',
  }
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

const ListInformes = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {informes,openDialog} =  useSelector(({ ejecucion }) => ejecucion);
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = React.useState('descripcion');
    const [order, setOrder] = React.useState('asc');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [formApp, setformApp] = useState('');
   
    const handleCloseDialog = useCallback(() => {
      setformApp('')
      dispatch({ type: SET_OPENDIALOG, payload: false });
    },[dispatch,setformApp]);

   const handleOpenDialog = (formAppdata) => {
      setformApp(formAppdata)
      dispatch({ type: SET_OPENDIALOG, payload: true });
   };

   useEffect(() => {
    if(!openDialog){
        handleCloseDialog()
      }
    }, [openDialog,handleCloseDialog]);

    useEffect(() => {
      function getFilteredArray(entities, _searchTerm) {
        if (_searchTerm.length === 0) {
          return informes;
        }
        return TableUtils.filterArrayByString(informes, _searchTerm);
      }
      if (informes) {
        setData(getFilteredArray(informes, searchTerm));
      }
    }, [informes, searchTerm]);
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
      };

      const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
            <>
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
               Listado de Informes
            </Typography>
            <React.Fragment>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
            </React.Fragment>
            </>
          </Toolbar>
          
          <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.titulo}</TableCell>
                                 <TableCell  align="right">  
                                    <Button 
                                      size='small' 
                                      className={classes.button} 
                                      variant="contained" 
                                      color="secondary" 
                                      onClick={() => handleOpenDialog(row)}>
                                        Ejecutar
                                        </Button>
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
               
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
        </Paper>

                      <>
        {formApp.formAppID === 2 && <TourOperatorReport open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}      
        {formApp.formAppID === 4 && <Reservations open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 5 && <InventarioTarifas open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}                   
        {formApp.formAppID === 6 && <InvoiceJournal open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}      
        {formApp.formAppID === 7 && <ReviewedInvoices open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 10 && <Comparativofacturacclvshotel open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 14 && <StatusFacturacion open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 15 && <AccountStatement open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 23 && <PresupuestoCobrosBrutos open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}
        {formApp.formAppID === 54 && <PresupuestoCobrosBrutosFinanzas open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 22 && <PresupuestoCobrosNeto open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 24 && <AgedBalance open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 25 && <Garantias open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 26 && <DailyJorunal open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 27 && <IncomeReconciliation open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 28 && <CountingReport open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 29 && <DocumentBalance open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 34 && <CountingReportsDocuments open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 37 && <FacturasFechaAplicacion open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 39 && <MonthlyTransactions open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}  
        {formApp.formAppID === 40 && <AdjusmentReport open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}  
        {formApp.formAppID === 41 && <CollectionsbyHotelbyMonth open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 42 && <GrossBilling open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 43 && <NetBilling open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 44 && <Adjustments open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 45 && <BalanceVencido open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 46 && <AccountReceivableBreakdown open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 48 && <RotacionCartera open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 47 && <Formna open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}      
        {formApp.formAppID === 49 && <Formna open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}      
        {formApp.formAppID === 50 && <Formna open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}      
        {formApp.formAppID === 51 && <Formna open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}      
        {formApp.formAppID === 52 && <Formna open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}      
        {formApp.formAppID === 53 && <Formna open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}      
        {formApp.formAppID === 55 && <EjecuciónPresupuestodeCobros open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        {formApp.formAppID === 56 && <EjecuciónProyeccionSemanal open={openDialog} onCloseDialog={handleCloseDialog} formApp ={formApp}/>}   
        
        </>
    </div>
  );
};

export default ListInformes;
