import {
  SET_PLANTILLAS,
  SET_INFORMES,
  SET_HOTELES,
  SET_TOURSOPERATOR,
  SET_LISTITEMSPRESUPUESTOCOBROS,
  SET_ITEMPRESUPUESTOCOBROBYID,
  SET_LISTITEMPRESUPUESTOS,
  SET_PRESUPUESTOCOBROEXCLUIR,
  SET_TTOODIASHABILES,
  SET_TIPOSDEFACTURACION,
  SET_HOTELPAIS,
  SET_PAISES,
  SET_LISTADOANOSPAIS,
  SET_LISTADODIASFESTIVOS,
  SET_PAISFESTIVOSELECT,
  SET_MENU,
  SET_CARTERAS,
  SET_DIALOGCARTERAS,
  SET_CONCEPTOSDESCUENTOS,
  SET_DIALOGCONCEPTOSDESCUENTOS,
  SET_OPENPRESUPUESTOCOBRODIALOG,
  SET_CLOSEPRESUPUESTOCOBRODIALOG,
  SET_COBROSNOREGULARES,
  SET_OPENCOBROSNOREGULARESDIALOG,
  SET_CLOSECOBROSNOREGULARESDIALOG,
  SET_CARTERAGRUPO,
  SET_OPENCARTERAGRUPODIALOG,
  SET_CLOSECARTERAGRUPODIALOG,
  SET_CARTERAGRUPODETALLE,
  SET_CARTERAGRUPOSELECT,
  SET_OPENPERIODOANODIALOG,
  SET_CLOSEPERIODOANODIALOG,
  SET_PERIODOANOSELECT,
  SET_PERIODOANOS,
  SET_ANOS,
  SET_OPENPERIODOSSEMANALDIALOG,
  SET_CLOSEPERIODOSSEMANALDIALOG,
  SET_PERIODOSSEMANAL,
  SET_CAMPOSHEADER,
  SET_CAMPOSFORMULARIO,
} from '../../@jumbo/constants/ActionTypes';
import moment from 'moment';

const INIT_STATE = {
  plantillas: [],
  informes: [],
  hoteles: [],
  touroperator: false,
  presupuestoCobroDialog: {
    props: {
      open: false,
    },
    data: null,
  },
  listitemspresupuestocobros: [],
  listitempresupuestos: [],
  itempresupuestocobrobyid: {
    presupuestoPartidaID: null,
    presupuestoItemID: '-10000',
    hotelID: '-10000',
    tourOpID: '-10000',
    importe: '',
    fechaDummy: moment().format('YYYYMMDD'),
    comentario: '',
    usuarioID: null,
    tourOpGroupId: '-10000',
  },
  presupuestocobroexcluir: [],
  ttoodiashabiles: [],
  tiposdefacturacion: [],
  hotelpais: [],
  paises: [],
  Listadoanospais: [],
  Listadodiasfestivos: [],
  paisfestivoselect: { value: null, label: null },
  menus: [],
  carteras: [],
  dialogcartera: false,
  ConceptosDescuentos: [],
  dialogConceptosDescuentos: false,
  CobrosnoRegulares: [],
  dialogCobrosnoRegulares: {
    props: {
      open: false,
    },
    data: null,
  },
  CarteraGrupo: [],
  CarteraGrupoDetalle: [],
  dialogCarteraGrupo: {
    props: {
      open: false,
    },
    data: null,
  },
  CarteraGrupoSelect: { carteraGrupoID: null },
  anos: [],
  Periodosano: [],
  dialogPeriodoano: {
    props: {
      open: false,
    },
    data: null,
  },
  PeriodoAnoSelect: { periodoAno: null },
  PeriodosSemanal: [],
  dialogPeriodosSemanal: {
    props: {
      open: false,
    },
    data: null,
  },
  CamposFormularios: [],
  CamposHeader: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CAMPOSHEADER: {
      return {
        ...state,
        CamposHeader: action.payload,
      };
    }
    case SET_CAMPOSFORMULARIO: {
      return {
        ...state,
        CamposFormularios: action.payload,
      };
    }

    case SET_OPENPERIODOSSEMANALDIALOG: {
      return {
        ...state,
        dialogPeriodosSemanal: {
          props: {
            open: true,
          },
          data: action.payload,
        },
      };
    }
    case SET_CLOSEPERIODOSSEMANALDIALOG: {
      return {
        ...state,
        dialogPeriodosSemanal: {
          props: {
            open: false,
          },
          data: null,
        },
      };
    }
    case SET_PERIODOSSEMANAL: {
      return {
        ...state,
        PeriodosSemanal: action.payload,
      };
    }
    case SET_OPENPERIODOANODIALOG: {
      return {
        ...state,
        dialogPeriodoano: {
          props: {
            open: true,
          },
          data: action.payload,
        },
      };
    }
    case SET_CLOSEPERIODOANODIALOG: {
      return {
        ...state,
        dialogPeriodoano: {
          props: {
            open: false,
          },
          data: null,
        },
      };
    }
    case SET_PERIODOANOSELECT: {
      return {
        ...state,
        PeriodoAnoSelect: action.payload,
      };
    }
    case SET_PERIODOANOS: {
      return {
        ...state,
        Periodosano: action.payload,
      };
    }
    case SET_ANOS: {
      return {
        ...state,
        anos: action.payload,
      };
    }
    case SET_CARTERAGRUPOSELECT: {
      return {
        ...state,
        CarteraGrupoSelect: action.payload,
      };
    }
    case SET_CARTERAGRUPO: {
      return {
        ...state,
        CarteraGrupo: action.payload,
      };
    }
    case SET_CARTERAGRUPODETALLE: {
      return {
        ...state,
        CarteraGrupoDetalle: action.payload,
      };
    }
    case SET_OPENCARTERAGRUPODIALOG: {
      return {
        ...state,
        dialogCarteraGrupo: {
          props: {
            open: true,
          },
          data: action.payload,
        },
      };
    }
    case SET_CLOSECARTERAGRUPODIALOG: {
      return {
        ...state,
        dialogCarteraGrupo: {
          props: {
            open: false,
          },
          data: null,
        },
      };
    }
    case SET_COBROSNOREGULARES: {
      return {
        ...state,
        CobrosnoRegulares: action.payload,
      };
    }
    case SET_OPENCOBROSNOREGULARESDIALOG: {
      return {
        ...state,
        dialogCobrosnoRegulares: {
          props: {
            open: true,
          },
          data: action.payload,
        },
      };
    }
    case SET_CLOSECOBROSNOREGULARESDIALOG: {
      return {
        ...state,
        dialogCobrosnoRegulares: {
          props: {
            open: false,
          },
          data: null,
        },
      };
    }
    case SET_DIALOGCARTERAS: {
      return {
        ...state,
        dialogcartera: action.payload,
      };
    }
    case SET_MENU: {
      return {
        ...state,
        menus: action.payload,
      };
    }
    case SET_CARTERAS: {
      return {
        ...state,
        carteras: action.payload,
      };
    }
    case SET_OPENPRESUPUESTOCOBRODIALOG: {
      return {
        ...state,
        presupuestoCobroDialog: {
          props: {
            open: true,
          },
          data: action.payload,
        },
      };
    }
    case SET_CLOSEPRESUPUESTOCOBRODIALOG: {
      return {
        ...state,
        presupuestoCobroDialog: {
          props: {
            open: false,
          },
          data: null,
        },
      };
    }

    case SET_CONCEPTOSDESCUENTOS: {
      return {
        ...state,
        ConceptosDescuentos: action.payload,
      };
    }
    case SET_DIALOGCONCEPTOSDESCUENTOS: {
      return {
        ...state,
        dialogConceptosDescuentos: action.payload,
      };
    }
    case SET_PAISFESTIVOSELECT: {
      return {
        ...state,
        paisfestivoselect: action.payload,
      };
    }
    case SET_PLANTILLAS: {
      return {
        ...state,
        plantillas: action.payload,
      };
    }
    case SET_INFORMES: {
      return {
        ...state,
        informes: action.payload,
      };
    }
    case SET_HOTELES: {
      return {
        ...state,
        hoteles: action.payload,
      };
    }
    case SET_TOURSOPERATOR: {
      return {
        ...state,
        touroperator: action.payload,
      };
    }
    case SET_LISTITEMSPRESUPUESTOCOBROS: {
      return {
        ...state,
        listitemspresupuestocobros: action.payload,
      };
    }
    case SET_ITEMPRESUPUESTOCOBROBYID: {
      return {
        ...state,
        itempresupuestocobrobyid: action.payload,
      };
    }
    case SET_PRESUPUESTOCOBROEXCLUIR: {
      return {
        ...state,
        presupuestocobroexcluir: action.payload,
      };
    }
    case SET_TTOODIASHABILES: {
      return {
        ...state,
        ttoodiashabiles: action.payload,
      };
    }
    case SET_TIPOSDEFACTURACION: {
      return {
        ...state,
        tiposdefacturacion: action.payload,
      };
    }
    case SET_HOTELPAIS: {
      return {
        ...state,
        hotelpais: action.payload,
      };
    }
    case SET_PAISES: {
      return {
        ...state,
        paises: action.payload,
      };
    }
    case SET_LISTADOANOSPAIS: {
      return {
        ...state,
        Listadoanospais: action.payload,
      };
    }
    case SET_LISTADODIASFESTIVOS: {
      return {
        ...state,
        Listadodiasfestivos: action.payload,
      };
    }
    case SET_LISTITEMPRESUPUESTOS: {
      return {
        ...state,
        listitempresupuestos: action.payload,
      };
    }

    default:
      return state;
  }
};
