import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import Ejecucion from './Ejecucion';
import Mantenimiento from './Mantenimiento'
import Herramientas from './Herramientas';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    ejecucion : Ejecucion,
    mantenimiento : Mantenimiento,
    herramientas: Herramientas
  });
