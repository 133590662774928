import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useForm from '@jumbo/utils/useForm';
import { IniciarFacturasFechaAplicacion,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';


const FacturasFechaAplicacion = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
    const { hotel,hotelgroup,touroperator,touroperatorgroup} = useSelector(({ ejecucion }) => ejecucion);
    const { form, setForm } = useForm({
      "hotel": null,
      "hotelgroup": null,
      "touroperator": null,
      "touroperatorgroup": null,
      "checkindatedesde": null,
      "checkindatehasta": null,
      "invoicedatedesde": null,
      "invoicedatehasta": null,
      "applicationdatedesde": null,
      "applicationdatehasta": null,
    });

     useEffect(() => {
       dispatch(IniciarFacturasFechaAplicacion());
     }, [dispatch]);


     const ejecucion = () => {
        let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
        }else{variables += "null"}

        if(form.hotelgroup){variables += `|${form.hotelgroup}`
        }else{variables += "|null"}

        if(form.touroperator){variables += `|${form.touroperator}`
        }else{variables += "|null"}

        if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
        }else{variables += "|null"}
        
        if(form.checkindatedesde){variables += `|${form.checkindatedesde},`
        }else{variables += "|null,"}

        if(form.checkindatehasta){variables += `${form.checkindatehasta}`
        }else{variables += "null"}
        
        if(form.invoicedatedesde){variables += `|${form.invoicedatedesde},`
        }else{variables += "|null,"}

        if(form.invoicedatehasta){variables += `${form.invoicedatehasta}`
        }else{variables += "null"}

        if(form.applicationdatedesde){variables += `|${form.applicationdatedesde},`
        }else{variables += "|null,"}

        if(form.applicationdatehasta){variables += `${form.applicationdatehasta}`
        }else{variables += "null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":null,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }
        
          dispatch(SolicitarEjecucion(Solicitar))
       };

    return (
    <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
        <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
        <DialogContent dividers>
        <div className={classes.root}>  
            <Typography variant='h4'>Criterios de busqueda</Typography>
            <Divider classes={{root: classes.divider}}/>

            <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    options={hotelgroup} 
                    name="Hotel Group"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                    }}
                />

                
            <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    options={touroperatorgroup} 
                    name="Tour Operator Group"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                    }}
                />

              <div className={classes.input} >
                        <Pickersinapp
                          label="Check in From"
                          value={form.checkindatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkindatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkindatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Check in To"
                          value={form.checkindatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkindatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkindatehasta', null))}}
                        />
                  </div>

                  <div className={classes.input} >
                        <Pickersinapp
                          label="Invoice date From"
                          value={form.invoicedatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invoicedatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invoicedatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Invoice date  To"
                          value={form.invoicedatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invoicedatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invoicedatehasta', null))}}
                        />
                  </div>

                  <div className={classes.input} >
                        <Pickersinapp
                          label="Application Date From"
                          value={form.applicationdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'applicationdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'applicationdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Application Date To"
                          value={form.applicationdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'applicationdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'applicationdatehasta', null))}}
                        />
                  </div>
         
        </div>
        <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
        </DialogContent>  
    </Dialog>
    )
};

export default FacturasFechaAplicacion;

FacturasFechaAplicacion.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
