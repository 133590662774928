import React , {useEffect,useState,useCallback} from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography } from '@material-ui/core';
import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles,getEditarRoles,GrabarRoles,EditarRoles,getFormRol,agregarFormRol,getMenuRol,agregarMenuRol,EliminarRoles} from '../../redux/actions/Auth';
import { SET_ADDROLES,SET_CLOSEDILOGROLES } from '@jumbo/constants/ActionTypes';
import _ from 'lodash';
import useForm from '@jumbo/utils/useForm';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import AppBar from '@material-ui/core/AppBar';
import Swal from 'sweetalert2';
const headCells = [
  {
    id: 'nombre',
    numeric: false,
    disablePadding: false,
    label: 'Nombre',
  },
  {
    id: 'descripcion',
    numeric: false,
    disablePadding: false,
    label: 'Descripción',
  },
  {
    id: 'estatus',
    numeric: false,
    disablePadding: false,
    label: 'Estatus',
  }
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useItemStyles = makeStyles(theme => ({
  root: {
    "& > .MuiTreeItem-content > .MuiTreeItem-label": {
      display: "flex",
      alignItems: "center",
      padding: "4px 0",
      background: "transparent !important",
      pointerEvents: "none"
    },
    "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
      content: "''",
      display: "inline-block",
      width: 12,
      height: 12,
      marginRight: 8,
      border: "1px solid #ccc",
      background: "white",
    },
  },
  label: {
    padding: 0
  },
  selected: {
    "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
      background: theme.palette.primary.main,
      border: "1px solid transparent"
    }
  }
}));

const useViewStyles = makeStyles({
  root: {}
});


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box my={3}>
          <Typography >{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Usuariodatadefaul = { 
    "rolID": null,
    "nombre": "",
    "descripcion": "",
    "activo": true,
    "createdBy": null};


    export default function InformesForm() {
  const classes = useStyles();
  const classesView = useViewStyles();
  const classesItem = useItemStyles();
  const dispatch = useDispatch();
  const {roles,formDialogRoles,authUser,formrol,menurol} =  useSelector(({ auth }) => auth);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const { form, setForm } = useForm(Usuariodatadefaul);
  const [value, setValue] = React.useState(0);
  const [nombreError, setnombreError] = useState('');
  const [descripcionError, setdescripcionError] = useState('');
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [expandedmenu, setExpandedmenu] = React.useState([]);
  const [selectedmenu, setSelectedmenu] = React.useState([]);

  useEffect(() => {
      dispatch(getRoles());
  }, [dispatch ]);



  const initDialog = useCallback(() => {
		if (formDialogRoles.type === 'edit' && formDialogRoles.data) {
		setForm({ ...formDialogRoles.data });
         dispatch(getFormRol(formDialogRoles.data.rolID))
         dispatch(getMenuRol(formDialogRoles.data.rolID))   
    	}
	
		if (formDialogRoles.type === 'new') {
			setForm({...Usuariodatadefaul});
		}
    }, [formDialogRoles.data, formDialogRoles.type, setForm,dispatch]);

   useEffect(() => {
    initDialog()
 }, [formDialogRoles.props.open, initDialog,dispatch]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return roles;
      }
      return TableUtils.filterArrayByString(roles, _searchTerm);
    }
    if (roles) {
      setData(getFilteredArray(roles, searchTerm));
    }
  }, [roles, searchTerm]);

  useEffect(() => {
        let arr = []
            formrol.filter(x => x.seleccionar === true).forEach(numero =>{
            arr.push(numero.formAppID)
         });
        setSelected(arr)
	}, [formrol]);

    useEffect(() => {
        let arr = []
            menurol.filter(x => x.seleccionar === true).forEach(numero =>{
            arr.push(numero.menuID)
         });
         setSelectedmenu(arr)
	}, [menurol]);
  

 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrderBy(property);
  setOrder(isAsc ? 'desc' : 'asc');
};

const handlePageChange = (event, newPage) => {
  setPage(newPage);
};

const handleRowsPerPageChange = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

const handleChange = (event, newValue) => {
  setValue(newValue);
};

const handleToggle = (event, nodeIds) => {
  if (event.target.nodeName !== "svg") {
    return;
  }
  setExpanded(nodeIds);
};

const handleTogglemenu = (event, nodeIds) => {
    if (event.target.nodeName !== "svg") {
      return;
    }
    setExpandedmenu(nodeIds);
  };

const handleSelect = (event, nodeIds) => {
  if (event.target.nodeName === "svg") {
    return;
  }
  const first = nodeIds[0];
  if (selected.includes(first)) {
    setSelected(selected.filter(id => id !== first));
  } else {
    setSelected([first, ...selected]);
  }
};

const handleSelectmenu = (event, nodeIds) => {
    if (event.target.nodeName === "svg") {
      return;
    }
    const first = nodeIds[0];
    if (selectedmenu.includes(first)) {
      setSelectedmenu(selectedmenu.filter(id => id !== first));
    } else {
      setSelectedmenu([first, ...selectedmenu]);
    }
  };
  

const renderTree = (nodes) => (
  <TreeItem classes={classesItem}  key={nodes.formAppID} nodeId={nodes.formAppID} label={nodes.titulo}>
    {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
  </TreeItem>
);

const renderTreemenu = (nodes) => (
    <TreeItem classes={classesItem}  key={nodes.menuID} nodeId={nodes.menuID} label={nodes.navTitle}>
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );
  

  return (
    <PageContainer heading="Roles">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
               <Button color="secondary" variant='outlined' onClick={() =>  dispatch({ type: SET_ADDROLES })}>
                Agregar Rol
              </Button>
            </Typography>
            <React.Fragment>
              <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
            </React.Fragment>
          </Toolbar>
          
          <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.nombre}</TableCell>
                                 <TableCell>{row.descripcion}</TableCell>
                                 <TableCell>{row.estatus}</TableCell>
                                 <TableCell  align="right">  
                                    <Button size='small' className={classes.button} variant="contained" color='secondary'  onClick={() => dispatch(getEditarRoles(row.rolID))} >Editar</Button>
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />
         </Paper>
    </div>
      <>{formDialogRoles.props.open &&
        <Dialog open={formDialogRoles.props.open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
            <DialogTitle className={classes.dialogTitleRoot}> {formDialogRoles.type === "new" ? 'Agregar Rol' : "Editar Rol"}</DialogTitle>
            <DialogContent dividers>
            <div className={classes.root}>  

            <Box className={classes.root} mb={{ xs: 4, sm: 6 }}>
            <AppBar position="static" color='inherit'>
              <Tabs value={value} onChange={handleChange} >
                <Tab className={ classes.customLabelColor}   label={`Rol`} {...a11yProps(0)} />
                {form.rolID !== null &&  <Tab className={ classes.customLabelColor}   label={`Informes / Plantillas`} {...a11yProps(1)} /> }
                {form.rolID !== null &&  <Tab className={ classes.customLabelColor}   label={`Menu`} {...a11yProps(1)} /> }
              </Tabs>
            </AppBar>
          <TabPanel value={value} index={0}>
          <Box display="flex" flexDirection={{ xs: 'row', md: 'row' }} alignItems="center" mb={{ xs: 3, md: 3 }}>
                <GridContainer>
                  <Grid item xs={12} sm={6}>
                    <AppTextInput
                      fullWidth
                      variant="outlined"
                      label="Nombre"
                      value={form.nombre}
                       onChange={e => {
                        setForm(_.set({ ...form }, 'nombre', e.target.value)) 
                        setnombreError('');
                      }}
                      helperText={nombreError}
                      style={{marginTop:10}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AppTextInput
                      fullWidth
                      variant="outlined"
                      label="Descripción"
                      value={form.descripcion}
                      onChange={e =>{setForm(_.set({ ...form }, 'descripcion', e.target.value)); setdescripcionError('') }}
                      helperText={descripcionError}
                      style={{marginTop:10}}
                    />
                  </Grid>
                </GridContainer>
              </Box>
               
                <Box display="flex" justifyContent="flex-end" my={4}>
                    {form.rolID !== null &&
                      <Box ml={2}>
                      <Button className={classes.buttonerror} variant="contained" onClick={() => {
                          Swal.fire({
                            title: '¿Estás seguro(a) de Eliminar?',
                            text: '¡No podrás recuperar este cambio!',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: "red",
                            confirmButtonText: 'Si, Eliminar',
                            cancelButtonText: 'No, Mantener',
                            }).then((result) => {
                            if (result.value) {
                              dispatch(EliminarRoles(form.rolID,authUser.usuarioID))
                              setnombreError('')
                              setdescripcionError('')
                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                              Swal.fire(
                              'Cancelado',
                              'No se realizó este cambio :)',
                              'error'
                              )
                            }
                            })
                          }}>Borrar</Button>
                      </Box>
                    }
                  <Box ml={2}>
                    <Button className={classes.button} variant="contained" onClick={() => {
                      dispatch({ type: SET_CLOSEDILOGROLES })
                      setnombreError('')
                      setdescripcionError('')
                      }}>Cancelar</Button>
                  </Box>
                    <Box ml={2}>
                    <Button className={classes.button} variant="contained" color="secondary" onClick={() => {


                            if (!form.nombre) { setnombreError("Nombre Requerido");} 
                            if (!form.descripcion) {setdescripcionError("descripcion Requerido");} 
                            if(form.nombre && form.descripcion ) {
                              if(formDialogRoles.type === "new" ){
                                dispatch(GrabarRoles(form,authUser.usuarioID))
                              }else{
                                dispatch(EditarRoles(form,authUser.usuarioID))
                              }
                            }
                    }}>
                        Guardar
                    </Button>
                    </Box>
                </Box>       
          </TabPanel>
          <TabPanel value={value} index={1}>
            {formrol.length >= 1 &&
                <TreeView
                  classes={classesView}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expanded}
                  selected={selected}
                  onNodeToggle={handleToggle}
                  onNodeSelect={handleSelect}
                  multiSelect
                >
                  {formrol.map(value =>(renderTree(value)))}
                </TreeView>
              }

                <Box display="flex" justifyContent="flex-end" my={4}>
                    <Button className={classes.button} variant="contained" onClick={() => dispatch({ type: SET_CLOSEDILOGROLES })}>Cancelar</Button>
                    <Box ml={2}>
                    <Button className={classes.button} variant="contained" color="secondary" onClick={() => {
                        dispatch(agregarFormRol(form.rolID,selected.toString()))
                      }}>
                        Guardar
                    </Button>
                    </Box>
                </Box> 

          </TabPanel>
          <TabPanel value={value} index={2}>
            {menurol.length >= 1 &&
                <TreeView
                  classes={classesView}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expandedmenu}
                  selected={selectedmenu}
                  onNodeToggle={handleTogglemenu}
                  onNodeSelect={handleSelectmenu}
                  multiSelect
                >
                  {menurol.map(value =>(renderTreemenu(value)))}
                </TreeView>
              }

                <Box display="flex" justifyContent="flex-end" my={4}>
                    <Button className={classes.button} variant="contained" onClick={() => dispatch({ type: SET_CLOSEDILOGROLES })}>Cancelar</Button>
                    <Box ml={2}>
                    <Button className={classes.button} variant="contained" color="secondary" onClick={() => {
                         dispatch(agregarMenuRol(form.rolID,selectedmenu.toString()))
                      }}>
                        Guardar
                    </Button>
                    </Box>
                </Box> 

          </TabPanel>
        </Box>
            </div>
           
            </DialogContent>  
        </Dialog>
        }
      </>
    </PageContainer>
  );
}


