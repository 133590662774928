import React , {useEffect,useState,useCallback} from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button,Typography,TextField } from '@material-ui/core';
import _ from 'lodash';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {makeStyles,alpha, lighten} from '@material-ui/core/styles';
//import useStyles from '@jumbo/components/Mantenimiento/MantenimientoStyle';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import Autocompetesinapp from 'routes/Ejecucion/utils/Autocompetesinapp';
import Pickersinapp from 'routes/Ejecucion/utils/Pickersinapp';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import useForm from '@jumbo/utils/useForm';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { ValorSelect } from 'routes/Ejecucion/utils/Helper';
import TableUtils from '@jumbo/utils/TableUtils';
import ReactExport from "react-export-excel";
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import {SET_CLOSECOBROSNOREGULARESDIALOG,SET_OPENCOBROSNOREGULARESDIALOG} from '@jumbo/constants/ActionTypes';
import { 
    getCobrosnoregulares,
    getHotelesPresupuesto,
    addCobrosnoregulares,
    getCobronoregularesbyid,
    updCobrosnoregulares,
    EliminarCobronoregular,
    getCarterasPresupuesto
} from '../../redux/actions/Mantenimiento';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import {NumberFormatDecimalNegativo} from '@jumbo/utils/NumberFormatCustom'
import Box from '@material-ui/core/Box';
import Swal from 'sweetalert2';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
      },
      paper: {
        width: '100%',
        marginBottom: theme.spacing(4),
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
      },
      container: {
        maxHeight: 350,
        minHeight: 350
      },
      table: {
        minWidth: 750,
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      titleRoot: {
        marginBottom: 2,
        fontSize: 14,
        letterSpacing: 0.25,
        color: theme.palette.common.dark,
      },
      button: {
        textTransform: "none",
      },
      buttonerror: {
        textTransform: "none",
        backgroundColor:theme.palette.error.main,
        color:'white'
      },
      roottoolbar: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
        justifyContent:'space-between'
      },
      highlight:
        theme.palette.type === 'light'
          ? {
              color: theme.palette.secondary.main,
              backgroundColor: alpha(theme.palette.secondary.light, 0.15),
            }
          : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
            },
      title: {
        flex: '1 1 100%',
      },
      chipsRoot: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        '& > *': {
          margin: theme.spacing(0.5),
        },
      },
      dialogRoot: {
        position: 'relative',
      },
      dialogTitleRoot: {
        '& .MuiTypography-h6': {
          fontSize: 16,
          color: theme.palette.common.black,
        },
        background: theme.palette.primary.main,
      },
      textlabel: {
        '& .MuiFormLabel-root': {
          color: '#232322',
        },
       },
       divider: {
        background: theme.palette.primary.main,
    },
    input:{
      '& .MuiTextField-root': {
        width: '50%',
      }
    },
    buscar:{
      '& .CmtSearch-root': {
        justifyContent:'flex-end',
        paddingRight: theme.spacing(2),
      }
    },
    CmtCard:{
      marginBottom:10
    }
  }));

  
const headCells = [
  {
    id: 'hotel',
    numeric: false,
    disablePadding: false,
    label: 'Hotel',
  },
    {
      id: 'cartera',
      numeric: false,
      disablePadding: false,
      label: 'Cartera',
    },
  
    {
      id: 'monto',
      numeric: false,
      disablePadding: false,
      label: 'Monto',
    },
    {
      id: 'fecha',
      numeric: false,
      disablePadding: false,
      label: 'Fecha',
    },
  ];

  function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
    const onSortOrderChange = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={onSortOrderChange(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell align="right">Acción</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const defaultFormState = {
    cobroNoRegularID:null,
    tourOpGroupID: "-10000",
    hotelID: "-10000",
    fecha: moment().format('YYYYMMDD'),
    monto:"",
    usuarioID: null,
};

export default function RegistrarCobrosnoRegulares() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const {hoteles,carteras,CobrosnoRegulares} =  useSelector(({ mantenimiento }) => mantenimiento);
    const { form, setForm } = useForm({tourOpGroupId:"-10000",hotelID:"-10000",fechaInicial:moment().format('YYYYMMDD'),fechaFinal:moment().format('YYYYMMDD')});
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = React.useState('descripcion');
    const [order, setOrder] = React.useState('asc');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        dispatch(getCobrosnoregulares(form));
        dispatch(getCarterasPresupuesto())
        dispatch(getHotelesPresupuesto())
   }, [dispatch]);

    useEffect(() => {
        function getFilteredArray(entities, _searchTerm) {
          if (_searchTerm.length === 0) {
            return CobrosnoRegulares;
          }
          return TableUtils.filterArrayByString(CobrosnoRegulares, _searchTerm);
        }
        if (CobrosnoRegulares) {
          setData(getFilteredArray(CobrosnoRegulares, searchTerm));
        }
      }, [CobrosnoRegulares, searchTerm]);

      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
      };
      
      const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };
      
      const handleRowsPerPageChange = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
      

    return(
        <PageContainer heading="Registrar Cobros no Regulares">
        
            <CmtCard className={classes.CmtCard}>
                <CmtCardContent>
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            {hoteles.length > 0 &&
                                <Autocompetesinapp 
                                    options={hoteles} 
                                    value={hoteles.find(x => x.value === form.hotelID.toString())}
                                    name="Hotel "
                                    onChange={(event, newValue) => {
                                        console.log(newValue)
                                        if(newValue){
                                        setForm(_.set({ ...form }, 'hotelID', ValorSelect(newValue))) 
                                        }else{
                                        setForm(_.set({ ...form }, 'hotelID', "-10000")) 
                                        }
                                    }}
                                />
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {carteras.length > 0 &&
                                <Autocompetesinapp 
                                    options={carteras} 
                                    value={carteras.find(x => x.value === form.tourOpGroupId.toString())}
                                    name="Cartera"
                                    onChange={(event, newValue) => {
                                    if(newValue){
                                        setForm(_.set({ ...form }, 'tourOpGroupId', ValorSelect(newValue))) 
                                    }else{
                                        setForm(_.set({ ...form }, 'tourOpGroupId', "-10000")) 
                                    }
                                    }}
                                />
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Pickersinapp
                            fullWidth
                            label="Fecha"
                            value={form.fechaInicial}
                            onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'fechaInicial', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'fechaInicial', 'na'))}}
                        />
                        </Grid>
                        <Grid item xs={6}>
                            <Pickersinapp
                            fullWidth
                            label="Fecha"
                            value={form.fechaFinal}
                            onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'fechaFinal', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'fechaFinal', 'na'))}}
                        />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" spacing={2} style={{marginTop:10}}>
                    
                     <Button 
                          onClick={() =>  
                            dispatch(getCobrosnoregulares(form)) 
                        } 
                          style={{margin:5}} 
                          size="small" 
                          className={classes.button} 
                          variant="contained"
                          color='secondary'
                          > 
                            Buscar
                        </Button>
                        
                        <Button 
                          onClick={() =>  setForm({tourOpGroupId:"-10000",hotelID:"-10000",fechaInicial:moment().format('YYYYMMDD'),fechaFinal:moment().format('YYYYMMDD')})} 
                          style={{margin:5}} 
                          size="small" 
                          className={classes.button} 
                          variant="contained"> 
                            Limpiar
                        </Button>

                    </Grid>
                </CmtCardContent>
            </CmtCard>
        
        <div className={classes.root}>
            <Paper className={classes.paper}>
          
            <Toolbar className={classes.roottoolbar}>
              
                <div>
                <Typography className={classes.titlecondicion} variant="h4" id="tableTitle" component="div">
                    Cobros no regulares
                </Typography>
                 {/* <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon /> */}
                </div>

                <div>
                    <React.Fragment style={{paddingRight:5}}>
                    <ExcelFile element={<Button  variant="contained" color='secondary'>Export Excel</Button>}>
                    <ExcelSheet data={CobrosnoRegulares} name="Cobros no regulares">
                        <ExcelColumn label="Hotel" value="hotel"/>
                        <ExcelColumn label="Tour Operator" value="tourOpGroup"/>
                        <ExcelColumn label="Fecha" value="fecha"/>
                        <ExcelColumn label="Importe" value="monto"/>
                    
                    </ExcelSheet>
                    </ExcelFile>
                    </React.Fragment>
                

                    <React.Fragment>
                        <Button style={{marginLeft:10}} variant="contained" color='secondary' onClick={ () => dispatch({ type:SET_OPENCOBROSNOREGULARESDIALOG, payload: defaultFormState })}>Agregar</Button>
                    </React.Fragment>
                </div>
            </Toolbar>

            <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              
                <TableBody>
                   {!!data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <TableRow
                                 hover
                                 tabIndex={-1}
                                 key={row.formAppID}
                               >
                                 <TableCell>{row.hotel}</TableCell>
                                 <TableCell>{row.tourOpGroup}</TableCell>
                                 <TableCell>{new Intl.NumberFormat().format(row.monto)}</TableCell>
                                 <TableCell>{row.fecha}</TableCell>
                                 <TableCell  align="right">  
                                    <Button  size="small" className={classes.button} variant="contained" color="secondary" 
                                    onClick={() => dispatch(getCobronoregularesbyid(row.cobroNoRegularID))}
                                     >Editar</Button>
                                 </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ height: 53 * 6 }}>
                          <TableCell colSpan={7} rowSpan={10}>
                             <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                          </TableCell>
                        </TableRow>
                    )}
                </TableBody>
               </Table>
            </TableContainer>    
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
             />

            </Paper>
        </div>
        
        <FormDialog/>
        </PageContainer>
    )

}


function FormDialog(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const formDialog=  useSelector(({ mantenimiento }) => mantenimiento.dialogCobrosnoRegulares);
    const {hoteles,carteras} =  useSelector(({ mantenimiento }) => mantenimiento);
    const { form, setForm } = useForm(defaultFormState);
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  
    const initDialog = useCallback(() => {
      if (formDialog.data) {
        setForm({ ...formDialog.data });
        }
    }, [formDialog.data, setForm]);
  
    useEffect(() => {
          if (formDialog.props.open) {
              initDialog();
          }
      }, [formDialog.props.open, initDialog]);
  
      function closeComposeDialog(){
          dispatch({ type:SET_CLOSECOBROSNOREGULARESDIALOG, payload: false });
      }
  
      return(
        <>
          <Dialog open={formDialog.props.open}  className={classes.dialogRoot} fullWidth maxWidth="sm" >
              <DialogTitle className={classes.dialogTitleRoot}>  Cobros no Regulares </DialogTitle>
              <DialogContent dividers>
              <div className={classes.root}>  

                            <div style={{marginBottom:10}}>
                                <Autocompetesinapp 
                                    options={hoteles} 
                                    value={hoteles.find(x => x.value === form.hotelID.toString())}
                                    name="Hotel "
                                    onChange={(event, newValue) => {
                                        console.log(newValue)
                                        if(newValue){
                                        setForm(_.set({ ...form }, 'hotelID', ValorSelect(newValue))) 
                                        }else{
                                        setForm(_.set({ ...form }, 'hotelID', "-10000")) 
                                        }
                                    }}
                                />
                            </div>
                            <div style={{marginBottom:10}}>
                                <Autocompetesinapp 
                                    options={carteras} 
                                    value={carteras.find(x => x.value === form.tourOpGroupID.toString())}
                                    name="Cartera"
                                    onChange={(event, newValue) => {
                                    if(newValue){
                                        setForm(_.set({ ...form }, 'tourOpGroupID', ValorSelect(newValue))) 
                                    }else{
                                        setForm(_.set({ ...form }, 'tourOpGroupID', "-10000")) 
                                    }
                                    }}
                                />
                            </div>
                    
                   <TextField 
                       className={classes.textlabel}
                       fullWidth
                       label="Monto" 
                       variant="outlined"
                       value={form.monto}
                       onChange={(value) =>  setForm(_.set({ ...form }, 'monto',value.target.value))} 
                       size="small"
                       style={{marginBottom:10}}
                       InputProps={{
                        inputComponent: NumberFormatDecimalNegativo,
                      }}
                       /> 
                    
                      <Pickersinapp
                         style={{marginBottom:10}}
                        fullWidth
                        label="Fecha"
                        value={form.fecha}
                        onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'fecha', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'fecha', 'na'))}}
                       />
  
              </div>
  
                 <Box display="flex" justifyContent="flex-end" my={4}>
                    <Button variant="contained" onClick={() =>closeComposeDialog()}>Cancelar</Button>
                      {form.cobroNoRegularID !== null &&
                        <Box ml={2}>
                        <Button variant="contained" className={classes.buttonerror} onClick={() => {
                        form.usuarioID = usuarioID;
                        Swal.fire({
                          title: '¿Estás seguro(a) de Eliminar?',
                          text: '¡No podrás recuperar este cambio!',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: "red",
                          confirmButtonText: 'Si, Eliminar',
                          cancelButtonText: 'No, Mantener',
                          }).then((result) => {
                          if (result.value) {
                            dispatch(EliminarCobronoregular(form.cobroNoRegularID))
                          } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                            'Cancelado',
                            'No se realizó este cambio :)',
                            'error'
                            )
                          }
                          })
                            }}>
                            Eliminar
                        </Button>
                          </Box>
                        }
                      <Box ml={2}>
  
                          <Button variant="contained" color="secondary" onClick={() =>{
                                form.usuarioID = usuarioID;

                                if(form.cobroNoRegularID === null){
                                //if((form.presupuestoItemID !== "-10000") && (form.hotelID !== "-10000") && (form.tourOpID !== "-10000") && form.importe && form.fechaDummy ){
                                    dispatch(addCobrosnoregulares(form))
                                    // }
                                }
                               else{
                                dispatch(updCobrosnoregulares(form))
                               }

                              }}>
                              Guardar
                          </Button>
  
                      </Box>
                  </Box>
              </DialogContent>  
          </Dialog>
        </>
      )
  }
  
  